//@flow
import WarningIcon from '@material-ui/icons/Warning';
import GavelIcon from '@material-ui/icons/Gavel';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { ToolkitIcon } from '@dt/material-components';
import ProtectIcon from '@material-ui/icons/Security';
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
import APISecureIcon from '@dt/brand/product-icon-api-secure';
import DTIcon from '@dt/brand/dt-logo-icon-only';
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
import type { LeftMenuConfiguration } from '@dt/material-components/navigation/ConfigurableLHSMenuPage';

export const MenuDashboard = ({
  product: {
    to: '/dashboard',
    name: 'Data Theorem',
    icon: DTIcon,
  },
  entries: [],
}: LeftMenuConfiguration);

export const MenuMobile = ({
  product: {
    to: '/issues/priority',
    name: 'Mobile Secure',
    icon: MobileSecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Issues',
      to: '/issues/search',
      subEntries: [],
      redirect: false,
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/policy-configuration',
      exact: false,
      subEntries: [],
      redirect: false,
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/apps',
          name: 'Inventory',
          redirect: false,
        },
        {
          to: '/api/discover/shadow-assets',
          name: 'Shadow Assets',
          redirect: true,
        },
        {
          to: '/management/configurations',
          name: 'ASM Setup',
          redirect: true,
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Attack',
      to: null, // No link
      subEntries: [
        {
          to: '/issues/security',
          name: 'Remote P1 Exploits',
          redirect: false,
        },
        {
          to: '/issues/apple',
          name: 'Gatekeeper Gambling',
          redirect: false,
        },
        {
          to: '/kttk/scan',
          name: 'Keys to the Kingdom',
          redirect: false,
        },
        {
          to: '/brandprotect/discovered_apps',
          name: 'Mobile Phishing',
          redirect: true,
        },
        {
          to: '/ios/privacy',
          name: 'App Store Answers',
          redirect: false,
        },
        {
          to: '/csc/scans',
          name: 'Coffee Shop Compromise',
          redirect: false,
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Protect',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/sdk-issues/all',
          name: 'Software Composition Analysis',
        },
      ],
    },
  ],
}: LeftMenuConfiguration);

export const MenuWeb = ({
  product: {
    to: '/web',
    name: 'Web Secure',
    icon: WebSecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      // Not on cloud path
      to: '/api/inspect/policy-violations',
      subEntries: [],
      redirect: true,
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/web/policies',
      exact: false,
      subEntries: [],
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/web/inventory',
          name: 'Inventory',
        },
        {
          // Not on /web path
          to: '/api/discover/shadow-assets',
          name: 'Shadow Assets',
          redirect: true,
        },
        {
          // Not on /web path (Doesn't need redirect but applying redirect for consistency
          to: '/management/configurations',
          name: 'ASM Setup',
          redirect: true,
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Attack',
      to: null,
      subEntries: [
        {
          to: '/web/security-toolkits',
          name: 'Hack Toolkits',
          exact: true,
        },
        {
          to: '/web/security-toolkits/sql-injection',
          name: 'SQL Injection',
        },
        {
          to: '/web/toxic-tokens',
          name: 'Toxic Tokens',
        },
        {
          to: '/web/security-toolkits/xss-attack/overview',
          name: 'XSS Attack',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Protect',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/web/security-toolkits/xss-protection',
          name: 'XSS Protect',
        },
      ],
    },
  ],
}: LeftMenuConfiguration);

export const MenuManagement = ({
  product: {
    to: '/management/products',
    name: 'Your Products',
    icon: DTIcon,
  },
  entries: [],
}: LeftMenuConfiguration);

export const MenuApi = ({
  product: {
    to: '/api/overview',
    name: 'API Secure',
    icon: APISecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      // Not on cloud path
      to: '/api/inspect/policy-violations',
      subEntries: [],
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/api/policies',
      exact: false,
      subEntries: [],
      redirect: true,
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/api/discover/inventory',
          name: 'Inventory',
        },
        {
          to: '/api/discover/shadow-assets',
          name: 'Shadow Assets',
        },
        {
          // Not on /web path AND requires a redirect
          to: '/management/configurations',
          name: 'ASM Setup',
          redirect: true,
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Attack',
      to: null, // No link
      subEntries: [
        {
          to: '/api/inspect/hack-and-extract',
          name: 'Hack and Extract',
        },
        {
          to: '/api/inspect/detect-and-inject',
          name: 'Detect and Inject',
        },
        {
          to: '/api/ssrf-sploits-toolkit',
          name: 'SSRF Sploits',
        },
        {
          to: '/api/inspect/leaky-apis',
          name: 'Leaky APIs',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Protect',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/api/protect',
          name: 'API Protect',
        },
        {
          to: '/api/inspect/correct-and-protect',
          name: 'Correct and Protect',
        },
      ],
    },
  ],
}: LeftMenuConfiguration);

export const MenuCloud = ({
  product: {
    to: '/cloud',
    name: 'Cloud Secure',
    icon: CloudSecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      // Not on cloud path
      to: '/api/inspect/policy-violations',
      subEntries: [],
      redirect: true,
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/cloud/policies',
      exact: false,
      subEntries: [],
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/cloud/inventory',
          name: 'Inventory',
        },
        {
          // Not on /cloud path
          to: '/api/discover/shadow-assets',
          name: 'Shadow Assets',
          redirect: true,
        },
        {
          to: '/cloud/asm-setup',
          name: 'ASM Setup',
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Attack',
      subEntries: [
        {
          to: '/cloud/security-toolkits',
          name: 'Hacker Toolkits',
          exact: true,
        },
        {
          to: '/cloud/security-toolkits/cloud-leaks',
          name: 'Cloud Leaks',
        },
        {
          to: '/cloud/security-toolkits/code-red',
          name: 'Code Red',
        },
        {
          to: '/cloud/security-toolkits/cloud-gotchas',
          name: 'Cloud Gotchas',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Protect',
      to: null,
      exact: true,
      subEntries: [
        {
          // Not on /cloud path
          to: '/cloud/policies/default',
          name: 'CSPM',
        },
      ],
    },
  ],
}: LeftMenuConfiguration);

export const MenuSupplyChainSecurity = ({
  product: {
    to: '/openscan',
    name: 'Supply Chain Security',
    icon: BrandIconSupplyChainSecurity,
  },
  entries: [],
}: LeftMenuConfiguration);
