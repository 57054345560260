// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  JSON: any,
};






export type Headers = {
  contentType?: ?$ElementType<Scalars, 'String'>,
  authorization?: ?$ElementType<Scalars, 'String'>,
};

export const AuthMessageValues = Object.freeze({
  RequestCookie: 'REQUEST_COOKIE', 
  UpdateSession: 'UPDATE_SESSION', 
  RequestSignOut: 'REQUEST_SIGN_OUT', 
  Session: 'SESSION'
});


export type AuthMessage = $Values<typeof AuthMessageValues>;

export const AppScanBlockedReasonValues = Object.freeze({
  CorruptedAppPackage: 'CORRUPTED_APP_PACKAGE', 
  NoCredentials: 'NO_CREDENTIALS', 
  NotBlocked: 'NOT_BLOCKED', 
  Other: 'OTHER'
});


export type AppScanBlockedReason = $Values<typeof AppScanBlockedReasonValues>;

export const AuthStrategyValues = Object.freeze({
  Otp: 'OTP', 
  GoogleAccount: 'GOOGLE_ACCOUNT', 
  Saml: 'SAML'
});


export type AuthStrategy = $Values<typeof AuthStrategyValues>;

export const CompliancePolicyValues = Object.freeze({
  Gdpr: 'GDPR', 
  Pci: 'PCI', 
  Hipaa: 'HIPAA', 
  Ftc: 'FTC', 
  Owasp: 'OWASP', 
  Caloppa: 'CALOPPA', 
  Coppa: 'COPPA', 
  Vppa: 'VPPA', 
  Ccpa: 'CCPA', 
  Mitre: 'MITRE'
});


export type CompliancePolicy = $Values<typeof CompliancePolicyValues>;

export const FindingPriorityValues = Object.freeze({
  P0: 'P0', 
  P1: 'P1', 
  P2: 'P2', 
  P3: 'P3', 
  P4: 'P4'
});


export type FindingPriority = $Values<typeof FindingPriorityValues>;

export const FindingSeverityValues = Object.freeze({
  Low: 'LOW', 
  Medium: 'MEDIUM', 
  High: 'HIGH'
});


export type FindingSeverity = $Values<typeof FindingSeverityValues>;

export const MobileAppListFilterValues = Object.freeze({
  ScanAndSecureApps: 'SCAN_AND_SECURE_APPS', 
  OpenscanApps: 'OPENSCAN_APPS'
});


export type MobileAppListFilter = $Values<typeof MobileAppListFilterValues>;

export const MobileAppPlatformValues = Object.freeze({
  Ios: 'IOS', 
  Android: 'ANDROID', 
  WindowsPhone: 'WINDOWS_PHONE', 
  AndroidAmazon: 'ANDROID_AMAZON', 
  AndroidOculus: 'ANDROID_OCULUS', 
  AndroidFacebookPortal: 'ANDROID_FACEBOOK_PORTAL'
});


export type MobileAppPlatform = $Values<typeof MobileAppPlatformValues>;

export const MobileAppReleaseTypeValues = Object.freeze({
  AppStore: 'APP_STORE', 
  PreProd: 'PRE_PROD', 
  AppStoreThirdParty: 'APP_STORE_THIRD_PARTY'
});


export type MobileAppReleaseType = $Values<typeof MobileAppReleaseTypeValues>;

export const MobileAppSubscriptionValues = Object.freeze({
  Static: 'STATIC', 
  Dynamic: 'DYNAMIC', 
  AppLogic: 'APP_LOGIC', 
  P1Alerts: 'P1_ALERTS', 
  NoSubscription: 'NO_SUBSCRIPTION'
});


export type MobileAppSubscription = $Values<typeof MobileAppSubscriptionValues>;

export const PiiTypeValues = Object.freeze({
  AdvertisingId: 'ADVERTISING_ID', 
  Age: 'AGE', 
  CreditCardNumber: 'CREDIT_CARD_NUMBER', 
  CreditCardTrackNumber: 'CREDIT_CARD_TRACK_NUMBER', 
  DateOfBirth: 'DATE_OF_BIRTH', 
  EmailAddress: 'EMAIL_ADDRESS', 
  FemaleName: 'FEMALE_NAME', 
  FirstName: 'FIRST_NAME', 
  Gender: 'GENDER', 
  IbanCode: 'IBAN_CODE', 
  HttpCookie: 'HTTP_COOKIE', 
  Icd9Code: 'ICD9_CODE', 
  Icd10Code: 'ICD10_CODE', 
  ImeiHardwareId: 'IMEI_HARDWARE_ID', 
  LastName: 'LAST_NAME', 
  Location: 'LOCATION', 
  MacAddress: 'MAC_ADDRESS', 
  MacAddressLocal: 'MAC_ADDRESS_LOCAL', 
  MaleName: 'MALE_NAME', 
  MedicalTerm: 'MEDICAL_TERM', 
  Passport: 'PASSPORT', 
  PersonName: 'PERSON_NAME', 
  PhoneNumber: 'PHONE_NUMBER', 
  StreetAddress: 'STREET_ADDRESS', 
  SwiftCode: 'SWIFT_CODE', 
  AuthToken: 'AUTH_TOKEN', 
  Password: 'PASSWORD', 
  JapanBankAccount: 'JAPAN_BANK_ACCOUNT', 
  JapanDriversLicenseNumber: 'JAPAN_DRIVERS_LICENSE_NUMBER', 
  JapanIndividualNumber: 'JAPAN_INDIVIDUAL_NUMBER', 
  AmericanBankersCusipId: 'AMERICAN_BANKERS_CUSIP_ID', 
  UsBankRoutingMicr: 'US_BANK_ROUTING_MICR', 
  UsDriversLicenseNumber: 'US_DRIVERS_LICENSE_NUMBER', 
  UsEmployerIdentificationNumber: 'US_EMPLOYER_IDENTIFICATION_NUMBER', 
  UsHealthcareNpi: 'US_HEALTHCARE_NPI', 
  UsPassport: 'US_PASSPORT', 
  UsPreparerTaxpayerIdentificationNumber: 'US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER', 
  UsSocialSecurityNumber: 'US_SOCIAL_SECURITY_NUMBER'
});


export type PiiType = $Values<typeof PiiTypeValues>;

export const RoleValues = Object.freeze({
  Developer: 'DEVELOPER', 
  Manager: 'MANAGER', 
  Security: 'SECURITY', 
  DeveloperAllApps: 'DEVELOPER_ALL_APPS', 
  SecurityAllApps: 'SECURITY_ALL_APPS'
});


export type Role = $Values<typeof RoleValues>;

export const SecurityFindingSearchImportanceTagValues = Object.freeze({
  SecurityP1: 'SECURITY_P1', 
  Security: 'SECURITY', 
  Apple: 'APPLE', 
  AppleP1: 'APPLE_P1', 
  Google: 'GOOGLE', 
  GoogleP1: 'GOOGLE_P1', 
  DefenseInDepth: 'DEFENSE_IN_DEPTH', 
  ServerSide: 'SERVER_SIDE', 
  CustomerPolicy: 'CUSTOMER_POLICY', 
  KeysToTheKingdom: 'KEYS_TO_THE_KINGDOM', 
  DataLeaks: 'DATA_LEAKS', 
  CoffeeShopCompromise: 'COFFEE_SHOP_COMPROMISE', 
  CategorySslCertificateValidation: 'CATEGORY_SSL_CERTIFICATE_VALIDATION', 
  CategorySslHostnameValidation: 'CATEGORY_SSL_HOSTNAME_VALIDATION', 
  CategorySslVulnerableSdk: 'CATEGORY_SSL_VULNERABLE_SDK', 
  CategorySslSecureNetworkRequests: 'CATEGORY_SSL_SECURE_NETWORK_REQUESTS', 
  CategorySslOpenSockets: 'CATEGORY_SSL_OPEN_SOCKETS', 
  CategorySslCleartextCommunication: 'CATEGORY_SSL_CLEARTEXT_COMMUNICATION', 
  AppStorePrivacy: 'APP_STORE_PRIVACY', 
  DataTypeContactInfo: 'DATA_TYPE_CONTACT_INFO', 
  DataTypeContactList: 'DATA_TYPE_CONTACT_LIST', 
  DataTypePhotos: 'DATA_TYPE_PHOTOS', 
  DataTypeLocation: 'DATA_TYPE_LOCATION', 
  DataTypeIdfa: 'DATA_TYPE_IDFA', 
  DataTypeHealthAndFitness: 'DATA_TYPE_HEALTH_AND_FITNESS', 
  DataTypePasteboard: 'DATA_TYPE_PASTEBOARD', 
  DataTypeCellularCarrier: 'DATA_TYPE_CELLULAR_CARRIER', 
  DataTypeWifiNetwork: 'DATA_TYPE_WIFI_NETWORK', 
  DataTypeMacAddress: 'DATA_TYPE_MAC_ADDRESS', 
  DataTypeDeviceIdentifier: 'DATA_TYPE_DEVICE_IDENTIFIER', 
  DataTypeUnknown: 'DATA_TYPE_UNKNOWN'
});


export type SecurityFindingSearchImportanceTag = $Values<typeof SecurityFindingSearchImportanceTagValues>;

export const FilterByImportanceTagEnumValues = Object.freeze({
  DataLeaks: 'DATA_LEAKS'
});


export type FilterByImportanceTagEnum = $Values<typeof FilterByImportanceTagEnumValues>;

export const FilterByProductEnumValues = Object.freeze({
  CloudSecure: 'CLOUD_SECURE', 
  ApiSecure: 'API_SECURE', 
  WebSecure: 'WEB_SECURE'
});


export type FilterByProductEnum = $Values<typeof FilterByProductEnumValues>;

export const OpenScanFavoriteAppGroupValues = Object.freeze({
  CustomerApps: 'CUSTOMER_APPS', 
  CompetitorApps: 'COMPETITOR_APPS', 
  UsedByExecs: 'USED_BY_EXECS', 
  UsedByFinance: 'USED_BY_FINANCE', 
  UsedByHr: 'USED_BY_HR', 
  UsedByIt: 'USED_BY_IT', 
  UsedBySales: 'USED_BY_SALES', 
  UsedByMarketing: 'USED_BY_MARKETING', 
  UsedByEngineering: 'USED_BY_ENGINEERING'
});


export type OpenScanFavoriteAppGroup = $Values<typeof OpenScanFavoriteAppGroupValues>;

export const MobileAppAnalysisStatusValues = Object.freeze({
  Ongoing: 'ONGOING', 
  Completed: 'COMPLETED'
});


export type MobileAppAnalysisStatus = $Values<typeof MobileAppAnalysisStatusValues>;

export const MobileAppAnalysisFeatureEnumValues = Object.freeze({
  KeysToTheKingdom: 'KEYS_TO_THE_KINGDOM', 
  CoffeeShopCompromise: 'COFFEE_SHOP_COMPROMISE', 
  SdkSpyware: 'SDK_SPYWARE'
});


export type MobileAppAnalysisFeatureEnum = $Values<typeof MobileAppAnalysisFeatureEnumValues>;

export const MobileAppScanStatusValues = Object.freeze({
  NoScansInitiated: 'NO_SCANS_INITIATED', 
  ScanOngoing: 'SCAN_ONGOING', 
  ScanCompleted: 'SCAN_COMPLETED'
});


export type MobileAppScanStatus = $Values<typeof MobileAppScanStatusValues>;

export const SecurityFindingSearchPriorityValues = Object.freeze({
  P0: 'P0', 
  P1: 'P1', 
  P2: 'P2', 
  P3: 'P3', 
  P4: 'P4', 
  NoPriority: 'NO_PRIORITY'
});


/**
 * Used by the Security Finding Search endpoint as a filter, it's identical to the
 * FindingPriority enum except it also has NO_PRIORITY
 */
export type SecurityFindingSearchPriority = $Values<typeof SecurityFindingSearchPriorityValues>;

export const SecurityFindingSearchReleaseTypeValues = Object.freeze({
  AppStore: 'APP_STORE', 
  PreProd: 'PRE_PROD', 
  AppStoreThirdParty: 'APP_STORE_THIRD_PARTY'
});


export type SecurityFindingSearchReleaseType = $Values<typeof SecurityFindingSearchReleaseTypeValues>;

export const TargetStatusValues = Object.freeze({
  Open: 'OPEN', 
  New: 'NEW', 
  ClosedFixed: 'CLOSED_FIXED', 
  ClosedRiskAccepted: 'CLOSED_RISK_ACCEPTED', 
  ClosedCompensatingControl: 'CLOSED_COMPENSATING_CONTROL', 
  ClosedItemNotFound: 'CLOSED_ITEM_NOT_FOUND', 
  OpenReadyToRescan: 'OPEN_READY_TO_RESCAN'
});


export type TargetStatus = $Values<typeof TargetStatusValues>;

export const TargetAdditionalContextTypeValues = Object.freeze({
  CustomFormat: 'CUSTOM_FORMAT', 
  StackTrace: 'STACK_TRACE', 
  CodeList: 'CODE_LIST', 
  AssociatedSdks: 'ASSOCIATED_SDKS'
});


export type TargetAdditionalContextType = $Values<typeof TargetAdditionalContextTypeValues>;

export const ComplianceReportTypeValues = Object.freeze({
  DataTrustReports: 'data_trust_reports', 
  AppStoreAnswersReports: 'app_store_answers_reports', 
  ComingSoon: 'coming_soon', 
  GdprApiReports: 'gdpr_api_reports', 
  Soc2ApiReports: 'soc2_api_reports', 
  HipaaApiReports: 'hipaa_api_reports', 
  Iso27001ApiReports: 'iso27001_api_reports', 
  PciApiReports: 'pci_api_reports', 
  PentestApiReports: 'pentest_api_reports', 
  GdprCloudReports: 'gdpr_cloud_reports', 
  Soc2CloudReports: 'soc2_cloud_reports', 
  HipaaCloudReports: 'hipaa_cloud_reports', 
  Iso27001CloudReports: 'iso27001_cloud_reports', 
  PciCloudReports: 'pci_cloud_reports', 
  PentestCloudReports: 'pentest_cloud_reports', 
  GdprMobileReports: 'gdpr_mobile_reports', 
  Soc2MobileReports: 'soc2_mobile_reports', 
  HipaaMobileReports: 'hipaa_mobile_reports', 
  Iso27001MobileReports: 'iso27001_mobile_reports', 
  PciMobileReports: 'pci_mobile_reports', 
  PentestMobileReports: 'pentest_mobile_reports', 
  GdprWebReports: 'gdpr_web_reports', 
  Soc2WebReports: 'soc2_web_reports', 
  HipaaWebReports: 'hipaa_web_reports', 
  Iso27001WebReports: 'iso27001_web_reports', 
  PciWebReports: 'pci_web_reports', 
  PentestWebReports: 'pentest_web_reports'
});


export type ComplianceReportType = $Values<typeof ComplianceReportTypeValues>;

export const AppleCategoryValues = Object.freeze({
  ContactInfo: 'CONTACT_INFO', 
  HealthAndFitness: 'HEALTH_AND_FITNESS', 
  FinancialInfo: 'FINANCIAL_INFO', 
  Location: 'LOCATION', 
  SensitiveInfo: 'SENSITIVE_INFO', 
  Contacts: 'CONTACTS', 
  UserContent: 'USER_CONTENT', 
  BrowsingHistory: 'BROWSING_HISTORY', 
  SearchHistory: 'SEARCH_HISTORY', 
  Identifiers: 'IDENTIFIERS', 
  Purchases: 'PURCHASES', 
  UsageData: 'USAGE_DATA', 
  Diagnostics: 'DIAGNOSTICS', 
  OtherData: 'OTHER_DATA'
});


export type AppleCategory = $Values<typeof AppleCategoryValues>;

export const DataTypeValues = Object.freeze({
  DataTypeContactInfo: 'DATA_TYPE_CONTACT_INFO', 
  DataTypeContactList: 'DATA_TYPE_CONTACT_LIST', 
  DataTypePhotos: 'DATA_TYPE_PHOTOS', 
  DataTypeLocation: 'DATA_TYPE_LOCATION', 
  DataTypeIdfa: 'DATA_TYPE_IDFA', 
  DataTypeHealthAndFitness: 'DATA_TYPE_HEALTH_AND_FITNESS', 
  DataTypePasteboard: 'DATA_TYPE_PASTEBOARD', 
  DataTypeCellularCarrier: 'DATA_TYPE_CELLULAR_CARRIER', 
  DataTypeWifiNetwork: 'DATA_TYPE_WIFI_NETWORK', 
  DataTypeMacAddress: 'DATA_TYPE_MAC_ADDRESS', 
  DataTypeDeviceIdentifier: 'DATA_TYPE_DEVICE_IDENTIFIER', 
  DataTypeUnknown: 'DATA_TYPE_UNKNOWN'
});


export type DataType = $Values<typeof DataTypeValues>;

/** Misc Types */
export type ActivityLogEvent = {
  __typename: 'ActivityLogEvent',
  id: $ElementType<Scalars, 'String'>,
  event_type: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type ApplicationMetadata = {
  __typename: 'ApplicationMetadata',
  closed_security_finding_apple_app_store_blocker_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_apple_app_store_blocker_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_google_play_blocker_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_google_play_blocker_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_high_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_high_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_low_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_low_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_medium_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_medium_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_priority_alerts_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_priority_alerts_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_security_p1_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_security_p1_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_regulatory_compliance_count: $ElementType<Scalars, 'String'>,
  closed_security_finding_regulatory_compliance_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_insight_finding_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_with_open_issues_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_sdk_finding_with_open_issues_count: $ElementType<Scalars, 'String'>,
  open_security_finding_apple_app_store_blocker_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_apple_app_store_blocker_count: $ElementType<Scalars, 'String'>,
  open_security_finding_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_count: $ElementType<Scalars, 'String'>,
  open_security_finding_google_play_blocker_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_google_play_blocker_count: $ElementType<Scalars, 'String'>,
  open_security_finding_high_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_high_count: $ElementType<Scalars, 'String'>,
  open_security_finding_low_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_low_count: $ElementType<Scalars, 'String'>,
  open_security_finding_medium_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_medium_count: $ElementType<Scalars, 'String'>,
  open_security_finding_priority_alerts_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_priority_alerts_count: $ElementType<Scalars, 'String'>,
  open_security_finding_security_p1_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
  open_security_finding_security_p1_count: $ElementType<Scalars, 'String'>,
  open_security_finding_regulatory_compliance_count: $ElementType<Scalars, 'String'>,
  open_security_finding_regulatory_compliance_beyond_subscription_level_count: $ElementType<Scalars, 'String'>,
};

export type ApplicationScan = {
  __typename: 'ApplicationScan',
  end_date?: ?$ElementType<Scalars, 'String'>,
  date_updated?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?$ElementType<Scalars, 'String'>,
  start_date?: ?$ElementType<Scalars, 'String'>,
  date_created?: ?$ElementType<Scalars, 'String'>,
  app_version?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};

export type CompliancePolicyReference = {
  __typename: 'CompliancePolicyReference',
  compliance_policy: CompliancePolicy,
  link: $ElementType<Scalars, 'String'>,
  markdown: $ElementType<Scalars, 'String'>,
};

export type Integrations = {
  __typename: 'Integrations',
  ci_cd?: ?$ElementType<Scalars, 'Boolean'>,
  issue_tracker?: ?$ElementType<Scalars, 'Boolean'>,
  trust_kit?: ?$ElementType<Scalars, 'Boolean'>,
};

export type Note = {
  __typename: 'Note',
  author_email: $ElementType<Scalars, 'String'>,
  date: $ElementType<Scalars, 'Date'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  security_finding_id: $ElementType<Scalars, 'ID'>,
  text: $ElementType<Scalars, 'String'>,
};

export type TargetStatusWithDate = {
  __typename: 'TargetStatusWithDate',
  date: $ElementType<Scalars, 'Date'>,
  status: TargetStatus,
};

export type TargetStatusWithFinding = {
  __typename: 'TargetStatusWithFinding',
  date: $ElementType<Scalars, 'Date'>,
  status: TargetStatus,
  security_finding?: ?SecurityFinding,
};

export type ToolkitsFindingAndTargetsCount = {
  __typename: 'ToolkitsFindingAndTargetsCount',
  open_security_finding_count: $ElementType<Scalars, 'String'>,
  open_security_finding_target_count: $ElementType<Scalars, 'String'>,
};

export type AppStorePrivacy = {
  __typename: 'AppStorePrivacy',
  data_record_types_collected_count: $ElementType<Scalars, 'Int'>,
  data_records_collected_count: $ElementType<Scalars, 'Int'>,
  data_records_shared_with_sdks_count: $ElementType<Scalars, 'Int'>,
  embedded_sdk_count: $ElementType<Scalars, 'Int'>,
  downloaded_in_countries?: ?Array<?$ElementType<Scalars, 'String'>>,
  downloaded_in_countries_names?: ?Array<?$ElementType<Scalars, 'String'>>,
};

/** CORE TYPES */
export type MobileApp = {
  __typename: 'MobileApp',
  app_protection_score?: ?$ElementType<Scalars, 'String'>,
  app_protection_score_ratio?: ?$ElementType<Scalars, 'Float'>,
  blocked_scan_reason?: ?AppScanBlockedReason,
  bundle_id?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'String'>,
  date_updated?: ?$ElementType<Scalars, 'String'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  integrations?: ?Integrations,
  max_app_protection_score: $ElementType<Scalars, 'String'>,
  metadata: ApplicationMetadata,
  most_recent_scan?: ?ApplicationScan,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  release_type?: ?MobileAppReleaseType,
  store_url?: ?$ElementType<Scalars, 'String'>,
  subscription: MobileAppSubscription,
  is_enterprise_internal?: ?$ElementType<Scalars, 'Boolean'>,
  app_store_customer_mobile_app_id?: ?$ElementType<Scalars, 'ID'>,
  keys_to_the_kingdom: ToolkitsFindingAndTargetsCount,
  coffee_shop_compromise: ToolkitsFindingAndTargetsCount,
  trustkit_url?: ?$ElementType<Scalars, 'String'>,
  app_store_privacy?: ?AppStorePrivacy,
  scan_status?: ?MobileAppScanStatus,
  all_communication_encrypted: $ElementType<Scalars, 'Boolean'>,
  does_not_offload_data_to_third_parties: $ElementType<Scalars, 'Boolean'>,
  enhances_password_security: $ElementType<Scalars, 'Boolean'>,
  full_protection_on_untrusted_networks: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_browser_settings: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_libraries: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_sdk: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_settings: $ElementType<Scalars, 'Boolean'>,
  protects_data_on_screens: $ElementType<Scalars, 'Boolean'>,
  protects_the_keyboard: $ElementType<Scalars, 'Boolean'>,
  removes_data_from_shared_device_locations: $ElementType<Scalars, 'Boolean'>,
  requires_android_device_security: $ElementType<Scalars, 'Boolean'>,
  requires_android_malware_protection: $ElementType<Scalars, 'Boolean'>,
  server_enforces_https: $ElementType<Scalars, 'Boolean'>,
  supports_two_factor_authentication: $ElementType<Scalars, 'Boolean'>,
  app_secure_id?: ?$ElementType<Scalars, 'ID'>,
};

export type OpenScanMobileAppInsights = {
  __typename: 'OpenScanMobileAppInsights',
  sdk_count: $ElementType<Scalars, 'Int'>,
  communicates_with_hostnames?: ?$ElementType<Scalars, 'String'>,
  communicates_with_ip_addresses?: ?$ElementType<Scalars, 'String'>,
  communicates_with_countries?: ?$ElementType<Scalars, 'String'>,
  communicates_with_blacklisted_ip_addresses?: ?$ElementType<Scalars, 'String'>,
  ios_base_sdk?: ?$ElementType<Scalars, 'String'>,
  ios_minimum_sdk?: ?$ElementType<Scalars, 'String'>,
  ios_permissions?: ?Array<?$ElementType<Scalars, 'String'>>,
  ios_app_extensions?: ?Array<?$ElementType<Scalars, 'String'>>,
  ios_supports_apple_watch: $ElementType<Scalars, 'Boolean'>,
  ios_supports_face_id: $ElementType<Scalars, 'Boolean'>,
  ios_supports_imessage: $ElementType<Scalars, 'Boolean'>,
  android_permissions?: ?Array<?$ElementType<Scalars, 'String'>>,
  android_minimum_sdk_version?: ?$ElementType<Scalars, 'String'>,
  android_target_sdk_version?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanMobileAppSecurityMetrics = {
  __typename: 'OpenScanMobileAppSecurityMetrics',
  open_security_finding_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_security_p1_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_regulatory_compliance_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_apple_app_store_blocker_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_google_play_blocker_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_high_severity_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_medium_severity_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_low_severity_count: $ElementType<Scalars, 'Int'>,
  open_security_finding_caused_by_sdk_count: $ElementType<Scalars, 'Int'>,
  sdk_with_issue_count: $ElementType<Scalars, 'Int'>,
  closed_security_issues_count: $ElementType<Scalars, 'Int'>,
};

export type OpenScanMobileAppAppProtectionMetrics = {
  __typename: 'OpenScanMobileAppAppProtectionMetrics',
  app_protection_score: $ElementType<Scalars, 'Int'>,
  max_app_protection_score: $ElementType<Scalars, 'Int'>,
  app_protection_score_ratio: $ElementType<Scalars, 'Float'>,
  completed_app_protection_template_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  not_completed_app_protection_template_ids?: ?Array<?$ElementType<Scalars, 'String'>>,
  full_protection_on_untrusted_networks: $ElementType<Scalars, 'Boolean'>,
  removes_data_from_shared_device_locations: $ElementType<Scalars, 'Boolean'>,
  protects_the_keyboard: $ElementType<Scalars, 'Boolean'>,
  all_communication_encrypted: $ElementType<Scalars, 'Boolean'>,
  supports_two_factor_authentication: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_sdk: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_browser_settings: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_libraries: $ElementType<Scalars, 'Boolean'>,
  leverages_secure_system_settings: $ElementType<Scalars, 'Boolean'>,
  enhances_password_security: $ElementType<Scalars, 'Boolean'>,
  does_not_offload_data_to_third_parties: $ElementType<Scalars, 'Boolean'>,
  protects_data_on_screens: $ElementType<Scalars, 'Boolean'>,
  requires_android_malware_protection: $ElementType<Scalars, 'Boolean'>,
  requires_android_device_security: $ElementType<Scalars, 'Boolean'>,
  server_enforces_https: $ElementType<Scalars, 'Boolean'>,
};

export type OpenScanApp = {
  __typename: 'OpenScanApp',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  bundle_id: $ElementType<Scalars, 'String'>,
  store_url?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  last_scanned_version?: ?$ElementType<Scalars, 'Date'>,
  last_scanned_date?: ?$ElementType<Scalars, 'Date'>,
  scan_count: $ElementType<Scalars, 'Int'>,
  has_results: $ElementType<Scalars, 'Boolean'>,
  watchlist_groups?: ?Array<?OpenScanFavoriteAppGroup>,
  security_metrics: OpenScanMobileAppSecurityMetrics,
  insights: OpenScanMobileAppInsights,
  app_protection_score: $ElementType<Scalars, 'Int'>,
  max_app_protection_score: $ElementType<Scalars, 'Int'>,
  app_protection_score_ratio: $ElementType<Scalars, 'Int'>,
  app_protection_percentile?: ?$ElementType<Scalars, 'Float'>,
  app_protection_metrics: OpenScanMobileAppAppProtectionMetrics,
  app_description?: ?$ElementType<Scalars, 'String'>,
  app_price_in_cents?: ?$ElementType<Scalars, 'Int'>,
  has_in_app_purchases?: ?$ElementType<Scalars, 'Boolean'>,
  publisher_name?: ?$ElementType<Scalars, 'String'>,
  publisher_website?: ?$ElementType<Scalars, 'String'>,
  initial_release_date?: ?$ElementType<Scalars, 'Date'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  screenshot_urls?: ?Array<?$ElementType<Scalars, 'String'>>,
  ranking?: ?$ElementType<Scalars, 'Int'>,
  publisher_can_be_contacted_automatically: $ElementType<Scalars, 'Boolean'>,
  inappropriate_for_the_enterprise: $ElementType<Scalars, 'Boolean'>,
  favorite: $ElementType<Scalars, 'Boolean'>,
};

export type SecurityFinding = {
  __typename: 'SecurityFinding',
  affecting_mediawatch_article_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  aggregated_status: TargetStatus,
  aggregated_status_date: $ElementType<Scalars, 'Date'>,
  app_protection_score?: ?$ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  compliance_policy_references?: ?Array<CompliancePolicyReference>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  description: $ElementType<Scalars, 'String'>,
  description_intro?: ?$ElementType<Scalars, 'String'>,
  exploitability?: ?$ElementType<Scalars, 'String'>,
  finding_type?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  importance_tags?: ?Array<$ElementType<Scalars, 'String'>>,
  is_permanently_closed?: ?$ElementType<Scalars, 'Boolean'>,
  issue_type_id: $ElementType<Scalars, 'String'>,
  mobile_app_id: $ElementType<Scalars, 'ID'>,
  notes?: ?Array<Note>,
  priority?: ?FindingPriority,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  severity: FindingSeverity,
  targets: Array<Target>,
  title: $ElementType<Scalars, 'String'>,
  secure_code?: ?$ElementType<Scalars, 'String'>,
  screenshot_url?: ?$ElementType<Scalars, 'String'>,
  reproduction_steps?: ?$ElementType<Scalars, 'String'>,
  template_id?: ?$ElementType<Scalars, 'String'>,
};

export type Session = {
  __typename: 'Session',
  session_id: $ElementType<Scalars, 'ID'>,
  session_expiration: $ElementType<Scalars, 'Date'>,
};

export type TargetAdditionalContext = {
  __typename: 'TargetAdditionalContext',
  body: $ElementType<Scalars, 'String'>,
  type: TargetAdditionalContextType,
};

export type Target = {
  __typename: 'Target',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  external_bug_tracker_url?: ?$ElementType<Scalars, 'String'>,
  external_id?: ?$ElementType<Scalars, 'String'>,
  formatted_text: $ElementType<Scalars, 'String'>,
  additional_contexts?: ?Array<TargetAdditionalContext>,
  id: $ElementType<Scalars, 'ID'>,
  security_finding_id: $ElementType<Scalars, 'ID'>,
  statuses: Array<TargetStatusWithDate>,
  raw_text?: ?$ElementType<Scalars, 'String'>,
};

export type SecretString = {
  __typename: 'SecretString',
  id: $ElementType<Scalars, 'ID'>,
  added_by: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  value: $ElementType<Scalars, 'String'>,
};

export type SupportRequest = {
  __typename: 'SupportRequest',
  subject: $ElementType<Scalars, 'String'>,
  body: $ElementType<Scalars, 'String'>,
};

export type AnalyzedApp = {
  __typename: 'AnalyzedApp',
  mobile_app_id: $ElementType<Scalars, 'ID'>,
  scan_status: MobileAppAnalysisStatus,
};

export type AnalysisRequest = {
  __typename: 'AnalysisRequest',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  feature?: ?MobileAppAnalysisFeatureEnum,
  progress: $ElementType<Scalars, 'Float'>,
  requested_by: $ElementType<Scalars, 'String'>,
  status?: ?MobileAppAnalysisStatus,
  analyzed_apps?: ?Array<AnalyzedApp>,
};

export type AdministrativeAccount = {
  __typename: 'AdministrativeAccount',
  id: $ElementType<Scalars, 'ID'>,
  logged_in: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
};

export type Vendor = {
  __typename: 'Vendor',
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  deletion_requested?: ?$ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  last_login_date?: ?$ElementType<Scalars, 'Date'>,
  logged_in: $ElementType<Scalars, 'Boolean'>,
  name: $ElementType<Scalars, 'String'>,
  setup_completion_date?: ?$ElementType<Scalars, 'Date'>,
  setup_needed: $ElementType<Scalars, 'Boolean'>,
};

/** ENDPOINTS */
export type MobileAppsListResponse = {
  __typename: 'MobileAppsListResponse',
  pagination_information: PaginationInformation,
  mobile_apps?: ?Array<MobileApp>,
};

export type UsersListResponse = {
  __typename: 'UsersListResponse',
  pagination_information: PaginationInformation,
  users: Array<User>,
};

export type SecurityFindingsSearchResponse = {
  __typename: 'SecurityFindingsSearchResponse',
  pagination_information: PaginationInformation,
  security_findings?: ?Array<SecurityFinding>,
};

export type ActivityLogEventListResponse = {
  __typename: 'ActivityLogEventListResponse',
  pagination_information: PaginationInformation,
  activity_log_events: Array<ActivityLogEvent>,
};

export type OpenScanSearchResponse = {
  __typename: 'OpenScanSearchResponse',
  id: $ElementType<Scalars, 'ID'>,
  app_protection_score: $ElementType<Scalars, 'Int'>,
  app_protection_score_ratio: $ElementType<Scalars, 'Int'>,
  has_results: $ElementType<Scalars, 'Boolean'>,
  icon_url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  platform: MobileAppPlatform,
  store_url?: ?$ElementType<Scalars, 'String'>,
};

export type OpenScanMobileAppSearchResponse = {
  __typename: 'OpenScanMobileAppSearchResponse',
  openscan_mobile_app_search_results: Array<OpenScanSearchResponse>,
};

export type OpenScanMobileAppsResponse = {
  __typename: 'OpenScanMobileAppsResponse',
  pagination_information: PaginationInformation,
  openscan_mobile_apps?: ?Array<OpenScanApp>,
};

export type SecretStringsListResponse = {
  __typename: 'SecretStringsListResponse',
  pagination_information: PaginationInformation,
  secret_strings?: ?Array<SecretString>,
};

export type MobileAppAnalysisScheduleResponse = {
  __typename: 'MobileAppAnalysisScheduleResponse',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_updated: $ElementType<Scalars, 'Date'>,
  time_when_to_scan: $ElementType<Scalars, 'String'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
};

export type MobileAppAnalysisRequestListResponse = {
  __typename: 'MobileAppAnalysisRequestListResponse',
  pagination_information: PaginationInformation,
  mobile_app_analysis_requests?: ?Array<AnalysisRequest>,
};

export type VendorManagementVendorsListResponse = {
  __typename: 'VendorManagementVendorsListResponse',
  pagination_information: PaginationInformation,
  administrative_account: AdministrativeAccount,
  vendors?: ?Array<Vendor>,
};

export type VendorManagementVendorByIdResponse = {
  __typename: 'VendorManagementVendorByIdResponse',
  vendor: Vendor,
};

export const ApplicationLayerProtocolValues = Object.freeze({
  Unknown: 'UNKNOWN', 
  Http: 'HTTP'
});


export type ApplicationLayerProtocol = $Values<typeof ApplicationLayerProtocolValues>;

export const AssetGroupMembershipKindValues = Object.freeze({
  AssetSearchMatch: 'ASSET_SEARCH_MATCH', 
  Manual: 'MANUAL', 
  ShadowAssetMatch: 'SHADOW_ASSET_MATCH'
});


export type AssetGroupMembershipKind = $Values<typeof AssetGroupMembershipKindValues>;

export const CloudServiceTypeValues = Object.freeze({
  Unknown: 'UNKNOWN', 
  AwsS3: 'AWS_S3', 
  AwsApiGateway: 'AWS_API_GATEWAY', 
  AwsLambda: 'AWS_LAMBDA', 
  AwsRoute53: 'AWS_ROUTE53', 
  AwsElasticsearch: 'AWS_ELASTICSEARCH', 
  AwsEbs: 'AWS_EBS', 
  AwsRds: 'AWS_RDS', 
  AwsApiGatewayMock: 'AWS_API_GATEWAY_MOCK', 
  AwsApiGatewayHttp: 'AWS_API_GATEWAY_HTTP', 
  AwsApiGatewayAwsIntegration: 'AWS_API_GATEWAY_AWS_INTEGRATION', 
  AwsApiGatewayHttpUpstream: 'AWS_API_GATEWAY_HTTP_UPSTREAM', 
  ApigeeApiGateway: 'APIGEE_API_GATEWAY', 
  ApigeeApiGatewayHostedTarget: 'APIGEE_API_GATEWAY_HOSTED_TARGET', 
  ApigeeApiGatewayHttp: 'APIGEE_API_GATEWAY_HTTP', 
  ApigeeApiGatewayHttpUpstream: 'APIGEE_API_GATEWAY_HTTP_UPSTREAM', 
  AzureApiManagementService: 'AZURE_API_MANAGEMENT_SERVICE', 
  AzureApiManagementServiceApi: 'AZURE_API_MANAGEMENT_SERVICE_API', 
  AzureApiManagementHttpUpstream: 'AZURE_API_MANAGEMENT_HTTP_UPSTREAM', 
  AzureWebSite: 'AZURE_WEB_SITE', 
  AzureDns: 'AZURE_DNS', 
  AzureFunctionApp: 'AZURE_FUNCTION_APP', 
  GcpCloudDns: 'GCP_CLOUD_DNS', 
  GcpAppEngine: 'GCP_APP_ENGINE', 
  GcpCloudStorage: 'GCP_CLOUD_STORAGE', 
  GcpFirebaseDatabase: 'GCP_FIREBASE_DATABASE', 
  GcpFirebaseStorage: 'GCP_FIREBASE_STORAGE', 
  GcpFirebaseFunctions: 'GCP_FIREBASE_FUNCTIONS', 
  GcpCloudFunctions: 'GCP_CLOUD_FUNCTIONS'
});


export type CloudServiceType = $Values<typeof CloudServiceTypeValues>;

export const DiscoveredViaValues = Object.freeze({
  AmazonWebServices: 'AMAZON_WEB_SERVICES', 
  Apigee: 'APIGEE', 
  ApigeeOnPrem: 'APIGEE_ON_PREM', 
  ApplicationFingerprinting: 'APPLICATION_FINGERPRINTING', 
  Axway: 'AXWAY', 
  Azure: 'AZURE', 
  CertificateTransparency: 'CERTIFICATE_TRANSPARENCY', 
  GoogleCloudPlatform: 'GOOGLE_CLOUD_PLATFORM', 
  Kong: 'KONG', 
  ManualImport: 'MANUAL_IMPORT', 
  MobileAppScans: 'MOBILE_APP_SCANS', 
  Mulesoft: 'MULESOFT', 
  RestfulApiDiscoverer: 'RESTFUL_API_DISCOVERER', 
  ReverseIpLookup: 'REVERSE_IP_LOOKUP', 
  SanExtraction: 'SAN_EXTRACTION', 
  WebApplicationScans: 'WEB_APPLICATION_SCANS', 
  WebsiteCrawler: 'WEBSITE_CRAWLER'
});


export type DiscoveredVia = $Values<typeof DiscoveredViaValues>;

export const HostedOnValues = Object.freeze({
  AmazonWebServices: 'AMAZON_WEB_SERVICES', 
  Apigee: 'APIGEE', 
  Axway: 'AXWAY', 
  Azure: 'AZURE', 
  DetectionFailed: 'DETECTION_FAILED', 
  GoogleCloudPlatform: 'GOOGLE_CLOUD_PLATFORM', 
  Kong: 'KONG', 
  Mulesoft: 'MULESOFT', 
  Unknown: 'UNKNOWN'
});


export type HostedOn = $Values<typeof HostedOnValues>;

export const HttpMethodValues = Object.freeze({
  Get: 'GET', 
  Put: 'PUT', 
  Post: 'POST', 
  Delete: 'DELETE', 
  Options: 'OPTIONS', 
  Head: 'HEAD', 
  Patch: 'PATCH', 
  Trace: 'TRACE', 
  XHorizonUndefinedHttpMethod: 'X_HORIZON_UNDEFINED_HTTP_METHOD', 
  Unknown: 'UNKNOWN'
});


export type HttpMethod = $Values<typeof HttpMethodValues>;

export const PolicyComplianceStandardValues = Object.freeze({
  Owasp: 'OWASP', 
  PciDss: 'PCI_DSS'
});


export type PolicyComplianceStandard = $Values<typeof PolicyComplianceStandardValues>;

export const PolicyComplianceStandardCriteriaValues = Object.freeze({
  OwaspA1_2019: 'OWASP_A1_2019', 
  OwaspA2_2019: 'OWASP_A2_2019', 
  OwaspA3_2019: 'OWASP_A3_2019', 
  OwaspA4_2019: 'OWASP_A4_2019', 
  OwaspA5_2019: 'OWASP_A5_2019', 
  OwaspA6_2019: 'OWASP_A6_2019', 
  OwaspA7_2019: 'OWASP_A7_2019', 
  OwaspA8_2019: 'OWASP_A8_2019', 
  OwaspA9_2019: 'OWASP_A9_2019', 
  OwaspA10_2019: 'OWASP_A10_2019', 
  OwaspW1_2017: 'OWASP_W1_2017', 
  OwaspW2_2017: 'OWASP_W2_2017', 
  OwaspW3_2017: 'OWASP_W3_2017', 
  OwaspW4_2017: 'OWASP_W4_2017', 
  OwaspW5_2017: 'OWASP_W5_2017', 
  OwaspW6_2017: 'OWASP_W6_2017', 
  OwaspW7_2017: 'OWASP_W7_2017', 
  OwaspW8_2017: 'OWASP_W8_2017', 
  OwaspW9_2017: 'OWASP_W9_2017', 
  OwaspW10_2017: 'OWASP_W10_2017', 
  PciDssV3_2_1A1_3: 'PCI_DSS_v3_2_1_a1_3', 
  PciDssV3_2_1A1_2_1: 'PCI_DSS_v3_2_1_a1_2_1', 
  PciDssV3_2_1A2_3: 'PCI_DSS_v3_2_1_a2_3', 
  PciDssV3_2_1A2_2_3: 'PCI_DSS_v3_2_1_a2_2_3', 
  PciDssV3_2_1A3_4: 'PCI_DSS_v3_2_1_a3_4', 
  PciDssV3_2_1A4_1: 'PCI_DSS_v3_2_1_a4_1', 
  PciDssV3_2_1A6_1: 'PCI_DSS_v3_2_1_a6_1', 
  PciDssV3_2_1A6_5_10: 'PCI_DSS_v3_2_1_a6_5_10', 
  PciDssV3_2_1A6_6: 'PCI_DSS_v3_2_1_a6_6', 
  PciDssV3_2_1A8_7: 'PCI_DSS_v3_2_1_a8_7', 
  PciDssV3_2_1A10_1: 'PCI_DSS_v3_2_1_a10_1', 
  PciDssV3_2_1AA_2_1: 'PCI_DSS_v3_2_1_aA_2_1'
});


export type PolicyComplianceStandardCriteria = $Values<typeof PolicyComplianceStandardCriteriaValues>;

export const PolicyRuleGroupValues = Object.freeze({
  RestApiAndServerless: 'REST_API_AND_SERVERLESS', 
  CloudSecurity: 'CLOUD_SECURITY', 
  WebApps: 'WEB_APPS', 
  Encryption: 'ENCRYPTION'
});


export type PolicyRuleGroup = $Values<typeof PolicyRuleGroupValues>;

export const PolicyRuleTypeRelevanceValues = Object.freeze({
  Proactive: 'PROACTIVE', 
  Important: 'IMPORTANT', 
  Urgent: 'URGENT'
});


export type PolicyRuleTypeRelevance = $Values<typeof PolicyRuleTypeRelevanceValues>;

export const PolicyViolationExceptionValues = Object.freeze({
  WontFix: 'WONT_FIX', 
  PolicyRuleDeleted: 'POLICY_RULE_DELETED'
});


export type PolicyViolationException = $Values<typeof PolicyViolationExceptionValues>;

export const WebRequestTypeValues = Object.freeze({
  Xhr: 'XHR', 
  Fetch: 'FETCH', 
  Font: 'FONT', 
  Script: 'SCRIPT', 
  Stylesheet: 'STYLESHEET', 
  Image: 'IMAGE', 
  Media: 'MEDIA', 
  Document: 'DOCUMENT', 
  Texttrack: 'TEXTTRACK', 
  Eventsource: 'EVENTSOURCE', 
  Websocket: 'WEBSOCKET', 
  Manifest: 'MANIFEST', 
  Other: 'OTHER'
});


export type WebRequestType = $Values<typeof WebRequestTypeValues>;

export const WebApplicationTypeValues = Object.freeze({
  SinglePage: 'SINGLE_PAGE', 
  Traditional: 'TRADITIONAL', 
  Hybrid: 'HYBRID'
});


export type WebApplicationType = $Values<typeof WebApplicationTypeValues>;

export const WebApplicationStatusEnumValues = Object.freeze({
  Online: 'ONLINE', 
  NoLongerAccessible: 'NO_LONGER_ACCESSIBLE'
});


export type WebApplicationStatusEnum = $Values<typeof WebApplicationStatusEnumValues>;

export const RestfulApiStatusEnumValues = Object.freeze({
  Online: 'ONLINE', 
  NoLongerAccessible: 'NO_LONGER_ACCESSIBLE'
});


export type RestfulApiStatusEnum = $Values<typeof RestfulApiStatusEnumValues>;

export const NetworkServiceStatusEnumValues = Object.freeze({
  Online: 'ONLINE', 
  NoLongerAccessible: 'NO_LONGER_ACCESSIBLE'
});


export type NetworkServiceStatusEnum = $Values<typeof NetworkServiceStatusEnumValues>;

export const WeekdayValues = Object.freeze({
  Monday: 'MONDAY', 
  Tuesday: 'TUESDAY', 
  Wednesday: 'WEDNESDAY', 
  Thursday: 'THURSDAY', 
  Friday: 'FRIDAY', 
  Saturday: 'SATURDAY', 
  Sunday: 'SUNDAY'
});


export type Weekday = $Values<typeof WeekdayValues>;

export const SpecialScanStatusValues = Object.freeze({
  NotStarted: 'NOT_STARTED', 
  Ongoing: 'ONGOING', 
  Finished: 'FINISHED', 
  Completed: 'COMPLETED'
});


export type SpecialScanStatus = $Values<typeof SpecialScanStatusValues>;

export const SpecialScanTypeValues = Object.freeze({
  HackAndExtract: 'HACK_AND_EXTRACT', 
  LeakyApis: 'LEAKY_APIS', 
  DetectAndInject: 'DETECT_AND_INJECT', 
  XssProtection: 'XSS_PROTECTION', 
  SpaDetectAndInject: 'SPA_DETECT_AND_INJECT'
});


export type SpecialScanType = $Values<typeof SpecialScanTypeValues>;

export const PolicyViolationStatusEnumValues = Object.freeze({
  Open: 'OPEN', 
  WontFix: 'WONT_FIX', 
  Resolved: 'RESOLVED', 
  Deleted: 'DELETED'
});


export type PolicyViolationStatusEnum = $Values<typeof PolicyViolationStatusEnumValues>;

export const TlsScanTypeValues = Object.freeze({
  SupportsSsl_2_0: 'SUPPORTS_SSL_2_0', 
  SupportsSsl_3_0: 'SUPPORTS_SSL_3_0', 
  SupportsTls_1_0: 'SUPPORTS_TLS_1_0', 
  SupportsTls_1_1: 'SUPPORTS_TLS_1_1', 
  SupportsTls_1_2: 'SUPPORTS_TLS_1_2', 
  SupportsTls_1_3: 'SUPPORTS_TLS_1_3', 
  SupportsOcspStapling: 'SUPPORTS_OCSP_STAPLING', 
  SupportsSessionResumption: 'SUPPORTS_SESSION_RESUMPTION', 
  SupportsCompression: 'SUPPORTS_COMPRESSION', 
  SupportsSecureRenegotiation: 'SUPPORTS_SECURE_RENEGOTIATION', 
  SupportsTls_1_3EarlyData: 'SUPPORTS_TLS_1_3_EARLY_DATA', 
  VulnerableToHeartbleed: 'VULNERABLE_TO_HEARTBLEED', 
  VulnerableToCcsInjection: 'VULNERABLE_TO_CCS_INJECTION', 
  VulnerableToRobot: 'VULNERABLE_TO_ROBOT'
});


export type TlsScanType = $Values<typeof TlsScanTypeValues>;

export const SecurityPropertiesEnumValues = Object.freeze({
  MatchesHostname: 'MATCHES_HOSTNAME', 
  IsExpiring: 'IS_EXPIRING', 
  SupportsMustStaple: 'SUPPORTS_MUST_STAPLE', 
  EmbedsCertificateTransparencySct: 'EMBEDS_CERTIFICATE_TRANSPARENCY_SCT', 
  VulnerableToSha1Collision: 'VULNERABLE_TO_SHA1_COLLISION', 
  RsaKeySmallerThan_2048Bits: 'RSA_KEY_SMALLER_THAN_2048_BITS', 
  LegacySymantecAnchor: 'LEGACY_SYMANTEC_ANCHOR'
});


export type SecurityPropertiesEnum = $Values<typeof SecurityPropertiesEnumValues>;

export const NetworkServiceConnectivityScanStatusEnumValues = Object.freeze({
  Reachable: 'REACHABLE', 
  NotReachable: 'NOT_REACHABLE', 
  NotReachableConnectionRejected: 'NOT_REACHABLE_CONNECTION_REJECTED', 
  NotReachableConnectionTimedOut: 'NOT_REACHABLE_CONNECTION_TIMED_OUT', 
  NotReachableDnsLookupFailed: 'NOT_REACHABLE_DNS_LOOKUP_FAILED'
});


export type NetworkServiceConnectivityScanStatusEnum = $Values<typeof NetworkServiceConnectivityScanStatusEnumValues>;

export const CredentialTriagingDecisionEnumValues = Object.freeze({
  None: 'NONE', 
  Monitored: 'MONITORED', 
  Ignored: 'IGNORED'
});


export type CredentialTriagingDecisionEnum = $Values<typeof CredentialTriagingDecisionEnumValues>;

export const ShouldAddAssetsRecentlyDiscoveredViaSourceValues = Object.freeze({
  AllSources: 'ALL_SOURCES', 
  PublicInternetAnalyzer: 'PUBLIC_INTERNET_ANALYZER'
});


export type ShouldAddAssetsRecentlyDiscoveredViaSource = $Values<typeof ShouldAddAssetsRecentlyDiscoveredViaSourceValues>;

export const CloudAuthenticatorCategoryValues = Object.freeze({
  CloudAccount: 'CLOUD_ACCOUNT', 
  ApiGatewayAccount: 'API_GATEWAY_ACCOUNT'
});


export type CloudAuthenticatorCategory = $Values<typeof CloudAuthenticatorCategoryValues>;

export const CloudAuthenticatorStatusValues = Object.freeze({
  Online: 'ONLINE', 
  Offline: 'OFFLINE'
});


export type CloudAuthenticatorStatus = $Values<typeof CloudAuthenticatorStatusValues>;

export const CloudAuthenticatorTypeValues = Object.freeze({
  Apigee: 'APIGEE', 
  ApigeeOnPrem: 'APIGEE_ON_PREM', 
  AmazonWebServices: 'AMAZON_WEB_SERVICES', 
  Azure: 'AZURE', 
  GoogleCloudPlatform: 'GOOGLE_CLOUD_PLATFORM', 
  Mulesoft: 'MULESOFT', 
  Kong: 'KONG', 
  Axway: 'AXWAY'
});


export type CloudAuthenticatorType = $Values<typeof CloudAuthenticatorTypeValues>;

export const AutoRemediationAttemptStatusValues = Object.freeze({
  Created: 'CREATED', 
  Started: 'STARTED', 
  Succeeded: 'SUCCEEDED', 
  Failed: 'FAILED'
});


export type AutoRemediationAttemptStatus = $Values<typeof AutoRemediationAttemptStatusValues>;

export const AlertsIntegrationsServiceValues = Object.freeze({
  Slack: 'SLACK', 
  MicrosoftTeams: 'MICROSOFT_TEAMS'
});


export type AlertsIntegrationsService = $Values<typeof AlertsIntegrationsServiceValues>;

export const CloudResourceStatusValues = Object.freeze({
  Online: 'ONLINE', 
  NoLongerAccessible: 'NO_LONGER_ACCESSIBLE'
});


export type CloudResourceStatus = $Values<typeof CloudResourceStatusValues>;

export const MetricTypesValues = Object.freeze({
  FullStackMetrics: 'FULL_STACK_METRICS', 
  WebApplicationXssToolkitMetrics: 'WEB_APPLICATION_XSS_TOOLKIT_METRICS', 
  CloudResourceCategories: 'CLOUD_RESOURCE_CATEGORIES'
});


export type MetricTypes = $Values<typeof MetricTypesValues>;

export const AuthenticatoTypeValues = Object.freeze({
  Apigee: 'APIGEE', 
  ApigeeOnPrem: 'APIGEE_ON_PREM', 
  AmazonWebServices: 'AMAZON_WEB_SERVICES', 
  Azure: 'AZURE', 
  GoogleCloudPlatform: 'GOOGLE_CLOUD_PLATFORM', 
  Mulesoft: 'MULESOFT', 
  Kong: 'KONG', 
  Axway: 'AXWAY'
});


export type AuthenticatoType = $Values<typeof AuthenticatoTypeValues>;

export const AssetStatusValues = Object.freeze({
  Online: 'ONLINE', 
  NoLongerAccessible: 'NO_LONGER_ACCESSIBLE'
});


export type AssetStatus = $Values<typeof AssetStatusValues>;

export const CloudProviderValues = Object.freeze({
  Unknown: 'UNKNOWN', 
  Apigee: 'APIGEE', 
  AmazonWebServices: 'AMAZON_WEB_SERVICES', 
  Azure: 'AZURE', 
  GoogleCloudPlatform: 'GOOGLE_CLOUD_PLATFORM', 
  Mulesoft: 'MULESOFT', 
  Kong: 'KONG', 
  Axway: 'AXWAY'
});


export type CloudProvider = $Values<typeof CloudProviderValues>;

export const AssetOrderByValues = Object.freeze({
  PolicyViolationsCountAsc: 'policy_violations_count_asc', 
  PolicyViolationsCountDesc: 'policy_violations_count_desc'
});


export type AssetOrderBy = $Values<typeof AssetOrderByValues>;

export const ToolkitScanTypeEnumValues = Object.freeze({
  WebApplicationXss: 'WEB_APPLICATION_XSS', 
  DetectAndInject: 'DETECT_AND_INJECT', 
  CloudLeaks: 'CLOUD_LEAKS', 
  CodeRed: 'CODE_RED', 
  ComplianceReports: 'COMPLIANCE_REPORTS', 
  AppStorePrivacy: 'APP_STORE_PRIVACY'
});


export type ToolkitScanTypeEnum = $Values<typeof ToolkitScanTypeEnumValues>;

export const WebApplicationXssScanIntensityEnumValues = Object.freeze({
  Low: 'LOW', 
  Medium: 'MEDIUM', 
  High: 'HIGH'
});


export type WebApplicationXssScanIntensityEnum = $Values<typeof WebApplicationXssScanIntensityEnumValues>;

export const XssToolkitResultEnumValues = Object.freeze({
  Vulnerable: 'VULNERABLE', 
  NotVulnerable: 'NOT_VULNERABLE', 
  Watchlist: 'WATCHLIST'
});


export type XssToolkitResultEnum = $Values<typeof XssToolkitResultEnumValues>;

export const AssetTypeEnumValues = Object.freeze({
  DomainName: 'DOMAIN_NAME', 
  NetworkService: 'NETWORK_SERVICE', 
  RestfulApi: 'RESTFUL_API', 
  CloudResource: 'CLOUD_RESOURCE', 
  WebApplication: 'WEB_APPLICATION', 
  GraphqlApi: 'GRAPHQL_API', 
  ApiOperation: 'API_OPERATION', 
  MobileApplication: 'MOBILE_APPLICATION'
});


export type AssetTypeEnum = $Values<typeof AssetTypeEnumValues>;

export const SharedLinksResourceTypeEnumValues = Object.freeze({
  CloudAuthenticatorsAdd: 'CLOUD_AUTHENTICATORS_ADD', 
  SinglePolicyViolationRead: 'SINGLE_POLICY_VIOLATION_READ', 
  SingleNetworkServiceRead: 'SINGLE_NETWORK_SERVICE_READ', 
  SingleRestfulApiRead: 'SINGLE_RESTFUL_API_READ', 
  SingleCloudResourceRead: 'SINGLE_CLOUD_RESOURCE_READ', 
  SingleWebApplicationRead: 'SINGLE_WEB_APPLICATION_READ', 
  CrossProductOnboarding: 'CROSS_PRODUCT_ONBOARDING'
});


export type SharedLinksResourceTypeEnum = $Values<typeof SharedLinksResourceTypeEnumValues>;

export const ApplicationLayerProtocolEnumValues = Object.freeze({
  Unknown: 'UNKNOWN', 
  Http: 'HTTP', 
  Ssh: 'SSH', 
  Redis: 'REDIS', 
  Mongodb: 'MONGODB'
});


export type ApplicationLayerProtocolEnum = $Values<typeof ApplicationLayerProtocolEnumValues>;

export const BulkActionTypeValues = Object.freeze({
  AssetsMoveToAssetGroup: 'ASSETS_MOVE_TO_ASSET_GROUP'
});


export type BulkActionType = $Values<typeof BulkActionTypeValues>;

export const BulkActionStatusValues = Object.freeze({
  Completed: 'COMPLETED', 
  Ongoing: 'ONGOING'
});


export type BulkActionStatus = $Values<typeof BulkActionStatusValues>;

/** Misc Types */
export type PolicyComplianceReference = {
  __typename: 'PolicyComplianceReference',
  compliance_standard: PolicyComplianceStandard,
  compliance_standard_criteria: PolicyComplianceStandardCriteria,
  description: $ElementType<Scalars, 'String'>,
  link: $ElementType<Scalars, 'String'>,
};

export type PolicyRuleConfiguration = {
  __typename: 'PolicyRuleConfiguration',
  nb_of_days_before_cert_expiration?: ?$ElementType<Scalars, 'Int'>,
  http_methods_to_scan?: ?Array<HttpMethod>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  enabled_google_pii_types?: ?Array<PiiType>,
};

export type Remediation = {
  __typename: 'Remediation',
  remediation_instructions: $ElementType<Scalars, 'String'>,
  remediation_url: $ElementType<Scalars, 'String'>,
};

export type WebActivity = {
  __typename: 'WebActivity',
  base_url: $ElementType<Scalars, 'String'>,
  request_types: Array<WebRequestType>,
};

export type WebAnalysisResult = {
  __typename: 'WebAnalysisResult',
  title: $ElementType<Scalars, 'String'>,
  screenshot_url: $ElementType<Scalars, 'String'>,
  fullscreen_screenshot_url: $ElementType<Scalars, 'String'>,
  web_application_type: WebApplicationType,
  date_created: $ElementType<Scalars, 'String'>,
  date_last_received: $ElementType<Scalars, 'String'>,
  detected_libraries?: ?Array<WebLibrary>,
  detected_network_activity?: ?Array<WebActivity>,
  detected_restful_api_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  exercised_api_operation_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type WebLibrary = {
  __typename: 'WebLibrary',
  name: $ElementType<Scalars, 'String'>,
  enum: $ElementType<Scalars, 'String'>,
  project_url: $ElementType<Scalars, 'String'>,
  icon_url: $ElementType<Scalars, 'String'>,
};

export type TlsScanAttribute = {
  __typename: 'TlsScanAttribute',
  enum: TlsScanType,
  value: $ElementType<Scalars, 'Boolean'>,
  opened_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  opened_policy_violation?: ?PolicyViolation,
  resolved_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  resolved_policy_violation?: ?PolicyViolation,
};

export type TlsScanResult = {
  __typename: 'TlsScanResult',
  network_service_id: $ElementType<Scalars, 'ID'>,
  tls_scan_attributes: Array<TlsScanAttribute>,
};

export type Certificate = {
  __typename: 'Certificate',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  domain_names_in_cn: Array<$ElementType<Scalars, 'String'>>,
  domain_names_in_san: Array<$ElementType<Scalars, 'String'>>,
  is_end_entity_certificate?: ?$ElementType<Scalars, 'Boolean'>,
  issuer_as_rfc4514_string: $ElementType<Scalars, 'String'>,
  not_valid_after: $ElementType<Scalars, 'Date'>,
  not_valid_before: $ElementType<Scalars, 'Date'>,
  public_key_algorithm: $ElementType<Scalars, 'String'>,
  public_key_pin: $ElementType<Scalars, 'String'>,
  public_key_size: $ElementType<Scalars, 'Int'>,
  serial_number: $ElementType<Scalars, 'String'>,
  sha1_fingerprint: $ElementType<Scalars, 'String'>,
  sha256_fingerprint: $ElementType<Scalars, 'String'>,
  signature_hash_algorithm: $ElementType<Scalars, 'String'>,
  subject_as_rfc4514_string: $ElementType<Scalars, 'String'>,
};

export type CertificateChainSecurityProperty = {
  __typename: 'CertificateChainSecurityProperty',
  enum: SecurityPropertiesEnum,
  value: $ElementType<Scalars, 'Boolean'>,
  relevance?: ?PolicyRuleTypeRelevance,
};

export type CertificateChain = {
  __typename: 'CertificateChain',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_removed?: ?$ElementType<Scalars, 'Date'>,
  deployed_on_network_service_id?: ?$ElementType<Scalars, 'ID'>,
  network_service: NetworkService,
  security_properties: Array<CertificateChainSecurityProperty>,
  certificate_ids: Array<$ElementType<Scalars, 'ID'>>,
  certificates: Array<Certificate>,
};

export type Comment = {
  __typename: 'Comment',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  content?: ?$ElementType<Scalars, 'String'>,
  related_policy_violation_id: $ElementType<Scalars, 'ID'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
};

/** CORE TYPES */
export type Event = {
  __typename: 'Event',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  event_type: $ElementType<Scalars, 'String'>,
  event_importance: $ElementType<Scalars, 'String'>,
  user_id?: ?$ElementType<Scalars, 'ID'>,
  user?: ?User,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  network_service?: ?NetworkService,
  certificate_chain_id?: ?$ElementType<Scalars, 'ID'>,
  certificate_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api?: ?RestfulApi,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource?: ?CloudResource,
  apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  apigee_on_prem_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  kong_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  openapi_definition_id?: ?$ElementType<Scalars, 'ID'>,
  api_operation_id?: ?$ElementType<Scalars, 'ID'>,
  api_operation?: ?ApiOperation,
  comment?: ?Comment,
  comment_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation?: ?PolicyViolation,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  policy_id?: ?$ElementType<Scalars, 'ID'>,
  ip_range_id?: ?$ElementType<Scalars, 'ID'>,
  web_application_id?: ?$ElementType<Scalars, 'ID'>,
  web_application?: ?WebApplication,
  alerts_integration_id?: ?$ElementType<Scalars, 'ID'>,
  asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  scoped_access_token_id?: ?$ElementType<Scalars, 'ID'>,
  auto_remediation_attempt_id?: ?$ElementType<Scalars, 'ID'>,
};

export type SevenhellNotificationEvent = {
  __typename: 'SevenhellNotificationEvent',
  id: $ElementType<Scalars, 'ID'>,
  title?: ?$ElementType<Scalars, 'String'>,
  event_type: SevenhellEventTypeEnum,
  icon_url: $ElementType<Scalars, 'String'>,
  detail: $ElementType<Scalars, 'String'>,
  detail_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  portal_url: $ElementType<Scalars, 'String'>,
  subtitle?: ?$ElementType<Scalars, 'String'>,
};

export type HorizonNotificationEvent = {
  __typename: 'HorizonNotificationEvent',
  id: $ElementType<Scalars, 'ID'>,
  title?: ?$ElementType<Scalars, 'String'>,
  event_type: HorizonEventTypeEnum,
  icon_url: $ElementType<Scalars, 'String'>,
  detail: $ElementType<Scalars, 'String'>,
  detail_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  portal_url: $ElementType<Scalars, 'String'>,
  subtitle?: ?$ElementType<Scalars, 'String'>,
};

export type ApiOperationScanTrace = {
  __typename: 'APIOperationScanTrace',
  id: $ElementType<Scalars, 'ID'>,
  api_operation_id: $ElementType<Scalars, 'ID'>,
  api_operation?: ?ApiOperation,
  url: $ElementType<Scalars, 'String'>,
  http_request: $ElementType<Scalars, 'String'>,
  http_response: $ElementType<Scalars, 'String'>,
};

export type ApiOperation = {
  __typename: 'APIOperation',
  id: $ElementType<Scalars, 'ID'>,
  path: $ElementType<Scalars, 'String'>,
  http_method: HttpMethod,
  is_authenticated?: ?$ElementType<Scalars, 'Boolean'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  date_created: $ElementType<Scalars, 'Date'>,
  restful_api_id: $ElementType<Scalars, 'ID'>,
  restful_api: RestfulApi,
  restful_api_title?: ?$ElementType<Scalars, 'String'>,
  hosted_on?: ?HostedOn,
};

export type AssetGroupMembership = {
  __typename: 'AssetGroupMembership',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  membership_kind: AssetGroupMembershipKind,
  asset_group_id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api?: ?RestfulApi,
  web_application_id?: ?$ElementType<Scalars, 'ID'>,
  web_application?: ?WebApplication,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource?: ?CloudResource,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  network_service?: ?NetworkService,
};

export type AzureCredential = {
  __typename: 'AzureCredential',
  id: $ElementType<Scalars, 'ID'>,
  tenant_id: $ElementType<Scalars, 'ID'>,
  client_id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type ApigeeCredential = {
  __typename: 'ApigeeCredential',
  id: $ElementType<Scalars, 'ID'>,
  organization: $ElementType<Scalars, 'String'>,
  added_by_user_id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_external_user_email?: ?$ElementType<Scalars, 'String'>,
};

export type ApigeeOnPremCredential = {
  __typename: 'ApigeeOnPremCredential',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  organization: $ElementType<Scalars, 'String'>,
  management_api_url?: ?$ElementType<Scalars, 'String'>,
  management_api_username?: ?$ElementType<Scalars, 'String'>,
};

export type AwsCredential = {
  __typename: 'AwsCredential',
  id: $ElementType<Scalars, 'ID'>,
  role_arn: $ElementType<Scalars, 'String'>,
  external_id: $ElementType<Scalars, 'ID'>,
  added_by_user_id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_external_user_email?: ?$ElementType<Scalars, 'String'>,
};

export type GcpCredential = {
  __typename: 'GcpCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  client_email?: ?$ElementType<Scalars, 'String'>,
  organization_id?: ?$ElementType<Scalars, 'String'>,
  private_key_id?: ?$ElementType<Scalars, 'String'>,
};

export type KongCredential = {
  __typename: 'KongCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  kong_admin_subdomain?: ?$ElementType<Scalars, 'String'>,
};

export type MulesoftCredential = {
  __typename: 'MulesoftCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  organization_id?: ?$ElementType<Scalars, 'String'>,
  account_username?: ?$ElementType<Scalars, 'String'>,
};

export type AxwayCredential = {
  __typename: 'AxwayCredential',
  id: $ElementType<Scalars, 'ID'>,
  added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  organization_id?: ?$ElementType<Scalars, 'String'>,
  client_id?: ?$ElementType<Scalars, 'String'>,
};

export type CloudAuthenticator_Deprecated = {
  __typename: 'CloudAuthenticator_DEPRECATED',
  status: CloudAuthenticatorStatus,
  apigee_credential?: ?ApigeeCredential,
  apigee_on_prem_credential?: ?ApigeeOnPremCredential,
  aws_credential?: ?AwsCredential,
  azure_credential?: ?AzureCredential,
  axway_credential?: ?AxwayCredential,
  gcp_credential?: ?GcpCredential,
  kong_credential?: ?KongCredential,
  mulesoft_credential?: ?MulesoftCredential,
  authenticator_type?: ?CloudAuthenticatorType,
};

export type CloudAuthenticator = {
  __typename: 'CloudAuthenticator',
  authenticator_category: CloudAuthenticatorCategory,
  authenticator_type: CloudAuthenticatorType,
  cloud_account_id: $ElementType<Scalars, 'String'>,
  customer_supplied_name?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  id: $ElementType<Scalars, 'ID'>,
  status: CloudAuthenticatorStatus,
};

export type AlertsIntegration = {
  __typename: 'AlertsIntegration',
  id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  service_enum: AlertsIntegrationsService,
  webhook_url: $ElementType<Scalars, 'String'>,
};

export type CloudResourceIncludedCodeRedStats = {
  __typename: 'CloudResourceIncludedCodeRedStats',
  unauthenticated_policy_violation_id?: ?$ElementType<Scalars, 'String'>,
};

export type CloudResource = {
  __typename: 'CloudResource',
  id: $ElementType<Scalars, 'ID'>,
  url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status: CloudResourceStatus,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  hosted_on?: ?HostedOn,
  runtime?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  resource_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  date_created: $ElementType<Scalars, 'Date'>,
  cloud_console_url?: ?$ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  gcp_project_id?: ?$ElementType<Scalars, 'ID'>,
  mulesoft_domain?: ?$ElementType<Scalars, 'String'>,
  azure_tenant_id?: ?$ElementType<Scalars, 'ID'>,
  axway_org_id?: ?$ElementType<Scalars, 'ID'>,
  kong_workspace_name?: ?$ElementType<Scalars, 'String'>,
  constituting_cloud_services: Array<CloudService>,
  asset_group_memberships: Array<AssetGroupMembership>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  included_code_red_stats?: ?CloudResourceIncludedCodeRedStats,
  resource_type_icon_url?: ?$ElementType<Scalars, 'String'>,
};

export type WebApplicationConnectedAsset = {
  __typename: 'WebApplicationConnectedAsset',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
  asset_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
};

export type CloudResourceV2 = {
  __typename: 'CloudResourceV2',
  id: $ElementType<Scalars, 'ID'>,
  url?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  status: CloudResourceStatus,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  hosted_on?: ?HostedOn,
  runtime?: ?$ElementType<Scalars, 'String'>,
  region?: ?$ElementType<Scalars, 'String'>,
  resource_type: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  cloud_console_url?: ?$ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  belongs_to_cloud_account?: ?PolicyViolationAffectedAssetCloudAccount,
  connected_assets: Array<?WebApplicationConnectedAsset>,
};

export type CloudService = {
  __typename: 'CloudService',
  cloud_service_type?: ?CloudServiceType,
  resource_name: $ElementType<Scalars, 'String'>,
  resource_id: $ElementType<Scalars, 'ID'>,
  runtime: $ElementType<Scalars, 'String'>,
  region: $ElementType<Scalars, 'String'>,
};

export type ComplianceStandardCriteria = {
  __typename: 'ComplianceStandardCriteria',
  compliance_standard: $ElementType<Scalars, 'String'>,
  criterion: PolicyComplianceStandardCriteria,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  link?: ?$ElementType<Scalars, 'String'>,
  referenced_by_policy_rule_type_ids: Array<$ElementType<Scalars, 'String'>>,
};

export type IncludedCodeRedStats = {
  __typename: 'IncludedCodeRedStats',
  fingerprinted_resources_count?: ?$ElementType<Scalars, 'Int'>,
  unauthenticated_fingerprinted_resources_count?: ?$ElementType<Scalars, 'Int'>,
};

export type DomainName = {
  __typename: 'DomainName',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  asset_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  asset_type_name?: ?$ElementType<Scalars, 'String'>,
  included_code_red_stats?: ?IncludedCodeRedStats,
};

export type GraphqlApi = {
  __typename: 'GraphqlApi',
  asset_group_memberships: Array<AssetGroupMembership>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  azure_tenant_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  discovered_via: DiscoveredVia,
  discovered_via_actor_id?: ?$ElementType<Scalars, 'String'>,
  discovered_via_apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_apigee_on_prem_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_cloud_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_kong_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  gcp_project_id?: ?$ElementType<Scalars, 'ID'>,
  hosted_on: HostedOn,
  id: $ElementType<Scalars, 'ID'>,
  mulesoft_asset_id?: ?$ElementType<Scalars, 'ID'>,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  status: CloudResourceStatus,
  url: $ElementType<Scalars, 'String'>,
};

export type NetworkServiceConnectivityScanResult = {
  __typename: 'NetworkServiceConnectivityScanResult',
  status: NetworkServiceConnectivityScanStatusEnum,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type NetworkService = {
  __typename: 'NetworkService',
  id: $ElementType<Scalars, 'ID'>,
  domain_name_id: $ElementType<Scalars, 'ID'>,
  domain_name: DomainName,
  port: $ElementType<Scalars, 'Int'>,
  url: $ElementType<Scalars, 'String'>,
  status: NetworkServiceStatusEnum,
  application_layer_protocol: ApplicationLayerProtocol,
  is_tls_encrypted: $ElementType<Scalars, 'Boolean'>,
  hosted_on?: ?HostedOn,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  connectivity_scan_results: Array<NetworkServiceConnectivityScanResult>,
  discovered_via: DiscoveredVia,
  discovered_via_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user?: ?User,
  date_created: $ElementType<Scalars, 'Date'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  constituting_cloud_services: Array<CloudService>,
  asset_group_memberships: Array<AssetGroupMembership>,
};

export type Policy = {
  __typename: 'Policy',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  created_by_actor_id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  created_by_user: HorizonUserField,
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  is_special_dt_policy: $ElementType<Scalars, 'Boolean'>,
  date_created: $ElementType<Scalars, 'Date'>,
  protected_asset_groups_count: $ElementType<Scalars, 'Int'>,
  enabled_policy_rules_count: $ElementType<Scalars, 'Int'>,
  policy_rule_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type PolicyRule = {
  __typename: 'PolicyRule',
  id: $ElementType<Scalars, 'ID'>,
  belongs_to_policy_id: $ElementType<Scalars, 'ID'>,
  policy: Policy,
  belongs_to_policy_name: $ElementType<Scalars, 'String'>,
  created_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  created_by_actor_id: $ElementType<Scalars, 'ID'>,
  created_by_actor_name: $ElementType<Scalars, 'String'>,
  deleted_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  deleted_by_actor_id?: ?$ElementType<Scalars, 'ID'>,
  deleted_by_actor_name?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  policy_rule_type_id: $ElementType<Scalars, 'ID'>,
  policy_rule_type: PolicyRuleType,
  deletion_explanation?: ?$ElementType<Scalars, 'String'>,
  relevance: PolicyRuleTypeRelevance,
  configuration?: ?PolicyRuleConfiguration,
  has_all_alerts_muted: $ElementType<Scalars, 'Boolean'>,
  has_comment_created_alerts_enabled: $ElementType<Scalars, 'Boolean'>,
  has_violation_created_alerts_enabled: $ElementType<Scalars, 'Boolean'>,
  has_violation_exception_added_alerts_enabled: $ElementType<Scalars, 'Boolean'>,
  open_policy_violations_count: $ElementType<Scalars, 'Int'>,
  resolved_policy_violations_count: $ElementType<Scalars, 'Int'>,
};

export type PolicyRuleV2 = {
  __typename: 'PolicyRuleV2',
  id: $ElementType<Scalars, 'ID'>,
  policy_rule_type: PolicyRuleType,
  relevance: PolicyRuleTypeRelevance,
};

export type PolicyRuleType = {
  __typename: 'PolicyRuleType',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  default_relevance?: ?PolicyRuleTypeRelevance,
  configuration_default_value?: ?PolicyRuleConfiguration,
  requires_configuration?: ?$ElementType<Scalars, 'Boolean'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  group?: ?PolicyRuleGroup,
  related_rule_types?: ?Array<PolicyRuleTypeRelatedRuleType>,
};

export type PolicyRuleTypeV2 = {
  __typename: 'PolicyRuleTypeV2',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  default_relevance?: ?PolicyRuleTypeRelevance,
  configuration_default_value?: ?PolicyRuleConfiguration,
  requires_configuration?: ?$ElementType<Scalars, 'Boolean'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  group?: ?PolicyRuleGroup,
  related_rule_types?: ?Array<PolicyRuleTypeRelatedRuleType>,
  policy_rule?: ?PolicyRuleTypeV2PolicyRule,
  headliner_hack_titles?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type PolicyRuleTypeV2PolicyRule = {
  __typename: 'PolicyRuleTypeV2PolicyRule',
  id: $ElementType<Scalars, 'ID'>,
  configuration?: ?PolicyRuleConfiguration,
  date_created: $ElementType<Scalars, 'Date'>,
  open_policy_violations_count: $ElementType<Scalars, 'Int'>,
  relevance: PolicyRuleTypeRelevance,
  resolved_policy_violations_count: $ElementType<Scalars, 'Int'>,
};

export type PolicyRuleTypeRelatedRuleType = {
  __typename: 'PolicyRuleTypeRelatedRuleType',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
  recommendation?: ?$ElementType<Scalars, 'String'>,
  default_relevance?: ?PolicyRuleTypeRelevance,
  configuration_default_value?: ?PolicyRuleConfiguration,
  requires_configuration?: ?$ElementType<Scalars, 'Boolean'>,
  compliance_policy_references: Array<PolicyComplianceReference>,
  group?: ?PolicyRuleGroup,
};

export type PolicyViolation = {
  __typename: 'PolicyViolation',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  violated_policy_rule_id: $ElementType<Scalars, 'ID'>,
  violated_policy_rule: PolicyRule,
  date_resolved?: ?$ElementType<Scalars, 'Date'>,
  status: PolicyViolationStatusEnum,
  additional_info?: ?$ElementType<Scalars, 'String'>,
  opened_by_certificate_chain_id?: ?$ElementType<Scalars, 'ID'>,
  exception_type?: ?PolicyViolationException,
  exception_explanation?: ?$ElementType<Scalars, 'String'>,
  exception_date_created?: ?$ElementType<Scalars, 'Date'>,
  exception_added_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  exception_added_by_user?: ?User,
  affected_network_service_id?: ?$ElementType<Scalars, 'ID'>,
  affected_network_service?: ?NetworkService,
  affected_api_operation_id?: ?$ElementType<Scalars, 'ID'>,
  affected_api_operation?: ?ApiOperation,
  affected_cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  affected_cloud_resource?: ?CloudResource,
  affected_graphql_api_id?: ?$ElementType<Scalars, 'ID'>,
  affected_graphql_api?: ?GraphqlApi,
  affected_web_application_id?: ?$ElementType<Scalars, 'ID'>,
  affected_web_application?: ?WebApplication,
  remediation?: ?Remediation,
  affected_restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  affected_restful_api?: ?RestfulApi,
};

export type PolicyViolationAffectedAssetCloudAccount = {
  __typename: 'PolicyViolationAffectedAssetCloudAccount',
  id: $ElementType<Scalars, 'ID'>,
  cloud_account_icon_url: $ElementType<Scalars, 'String'>,
  cloud_account_type_name: $ElementType<Scalars, 'String'>,
  discovered_via_cloud_authenticator_id?: ?$ElementType<Scalars, 'String'>,
  user_supplied_name?: ?$ElementType<Scalars, 'String'>,
};

export type PolicyViolationAffectedAsset = {
  __typename: 'PolicyViolationAffectedAsset',
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  hosted_on?: ?HostedOn,
  aws_arn?: ?$ElementType<Scalars, 'String'>,
  belongs_to_cloud_account?: ?PolicyViolationAffectedAssetCloudAccount,
};

export type PolicyViolationV2 = {
  __typename: 'PolicyViolationV2',
  id: $ElementType<Scalars, 'ID'>,
  affected_asset: PolicyViolationAffectedAsset,
  affected_asset_id?: ?$ElementType<Scalars, 'String'>,
  affected_asset_name: $ElementType<Scalars, 'String'>,
  affected_asset_type: AssetTypeEnum,
  affected_asset_type_name: $ElementType<Scalars, 'String'>,
  affected_asset_type_icon_url?: ?$ElementType<Scalars, 'String'>,
  affected_asset_hosted_on?: ?HostedOn,
  date_created: $ElementType<Scalars, 'Date'>,
  date_resolved?: ?$ElementType<Scalars, 'Date'>,
  status: PolicyViolationStatusEnum,
  additional_info?: ?$ElementType<Scalars, 'String'>,
  opened_by_certificate_chain_id?: ?$ElementType<Scalars, 'ID'>,
  exception_type?: ?PolicyViolationException,
  exception_explanation?: ?$ElementType<Scalars, 'String'>,
  exception_date_created?: ?$ElementType<Scalars, 'Date'>,
  violated_policy_rule: PolicyRuleV2,
};

export type RestfulApi = {
  __typename: 'RestfulAPI',
  id: $ElementType<Scalars, 'ID'>,
  network_service_id: $ElementType<Scalars, 'ID'>,
  network_service: NetworkService,
  openapi_definition_id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  base_url: $ElementType<Scalars, 'String'>,
  status: RestfulApiStatusEnum,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  base_path: $ElementType<Scalars, 'String'>,
  api_operations?: ?Array<ApiOperation>,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  integrated_cloud_resource_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  integrated_cloud_resources?: ?Array<CloudResource>,
  constituting_cloud_services: Array<CloudService>,
  asset_group_memberships: Array<AssetGroupMembership>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
};

export type User = {
  __typename: 'User',
  allowed_app_ids?: ?Array<?$ElementType<Scalars, 'ID'>>,
  auth_strategy: AuthStrategy,
  can_access_app_search: $ElementType<Scalars, 'Boolean'>,
  can_access_app_secure: $ElementType<Scalars, 'Boolean'>,
  can_access_api_inspect: $ElementType<Scalars, 'Boolean'>,
  can_access_brand_protect: $ElementType<Scalars, 'Boolean'>,
  can_invite_users: $ElementType<Scalars, 'Boolean'>,
  can_download_reports?: ?$ElementType<Scalars, 'Boolean'>,
  date_created?: ?$ElementType<Scalars, 'String'>,
  date_updated?: ?$ElementType<Scalars, 'String'>,
  first_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  last_login_date?: ?$ElementType<Scalars, 'String'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
  renewal_reminder_last_dismissed?: ?$ElementType<Scalars, 'String'>,
  role: Role,
};

export type HorizonUserField = {
  __typename: 'HorizonUserField',
  date_created?: ?$ElementType<Scalars, 'String'>,
  first_name: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  last_name: $ElementType<Scalars, 'String'>,
  login_email: $ElementType<Scalars, 'String'>,
  notification_email?: ?$ElementType<Scalars, 'String'>,
};

export type WebApplication = {
  __typename: 'WebApplication',
  id: $ElementType<Scalars, 'ID'>,
  base_url: $ElementType<Scalars, 'String'>,
  base_path: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'Date'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  network_service_id: $ElementType<Scalars, 'ID'>,
  status: WebApplicationStatusEnum,
  network_service: NetworkService,
  most_recent_analysis_result: WebAnalysisResult,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_gcp_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_azure_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_axway_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_mulesoft_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_apigee_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_ids: Array<$ElementType<Scalars, 'ID'>>,
  policy_violations: Array<PolicyViolation>,
  asset_group_memberships: Array<AssetGroupMembership>,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
};

export type WebApplicationVersion2 = {
  __typename: 'WebApplicationVersion2',
  id: $ElementType<Scalars, 'ID'>,
  base_url: $ElementType<Scalars, 'String'>,
  base_path: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  network_service_id: $ElementType<Scalars, 'ID'>,
  status: WebApplicationStatusEnum,
  title: $ElementType<Scalars, 'String'>,
  screenshot_url: $ElementType<Scalars, 'String'>,
  hosted_on?: ?HostedOn,
  discovered_via: DiscoveredVia,
  policy_violation_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  included_detected_spa_credentials_stats?: ?WebAppCredentialsStat,
  included_connected_assets_stats?: ?WebAppConnectedAssetsStats,
  included_xss_toolkit_stats?: ?WebAppXssToolkitStats,
  open_important_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
  open_urgent_policy_violations_count?: ?$ElementType<Scalars, 'Int'>,
};

export type AutoRemediationAttempt = {
  __typename: 'AutoRemediationAttempt',
  id: $ElementType<Scalars, 'ID'>,
  status: AutoRemediationAttemptStatus,
  policy_violation_id: $ElementType<Scalars, 'ID'>,
  affected_cloud_resource_arn?: ?$ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
};

export type AutoRemediationAttemptBatch = {
  __typename: 'AutoRemediationAttemptBatch',
  id: $ElementType<Scalars, 'ID'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  auto_remediation_attempts: Array<AutoRemediationAttempt>,
  url: $ElementType<Scalars, 'String'>,
};

export type IpRange = {
  __typename: 'IpRange',
  id: $ElementType<Scalars, 'ID'>,
  has_continuous_discovery_enabled: $ElementType<Scalars, 'Boolean'>,
  ip_range: $ElementType<Scalars, 'String'>,
};

export type IpRangeScanResult = {
  __typename: 'IpRangeScanResult',
  ip_address: $ElementType<Scalars, 'String'>,
  online_network_service_urls: Array<$ElementType<Scalars, 'String'>>,
};

export type IpRangeScan = {
  __typename: 'IpRangeScan',
  ip_address_scan_results: Array<IpRangeScanResult>,
  ip_range: $ElementType<Scalars, 'String'>,
  ip_range_id?: ?$ElementType<Scalars, 'ID'>,
  title?: ?Array<?$ElementType<Scalars, 'String'>>,
};

export type OpenApiDefinition = {
  __typename: 'OpenApiDefinition',
  id: $ElementType<Scalars, 'ID'>,
  title: $ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  discovered_via_aws_authenticator_id?: ?$ElementType<Scalars, 'ID'>,
  discovered_via_user_id?: ?$ElementType<Scalars, 'ID'>,
  original_document: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
};

/** ENDPOINTS */
export type ApiOperationDetailsResponse = {
  __typename: 'APIOperationDetailsResponse',
  api_operations: Array<ApiOperation>,
  api_operation_scan_traces: Array<ApiOperationScanTrace>,
};

export type ApiOperationV2RestfulApi = {
  __typename: 'APIOperationV2RestfulApi',
  id: $ElementType<Scalars, 'ID'>,
};

export type ApiOperationDetailsResponseVersion2 = {
  __typename: 'APIOperationDetailsResponseVersion2',
  id: $ElementType<Scalars, 'ID'>,
  restful_api: ApiOperationV2RestfulApi,
};

export type ComplianceStandardCriteriaListResponse = {
  __typename: 'ComplianceStandardCriteriaListResponse',
  compliance_standard_criteria: Array<ComplianceStandardCriteria>,
};

export type EventsListResponse = {
  __typename: 'EventsListResponse',
  pagination_information: PaginationInformation,
  events?: ?Array<HorizonNotificationEvent>,
};

export type PortalNotificationsListResponse = {
  __typename: 'PortalNotificationsListResponse',
  pagination_information: PaginationInformation,
  portal_notifications?: ?Array<SevenhellNotificationEvent>,
};

export type PolicyDetailResponse = {
  __typename: 'PolicyDetailResponse',
  policies: Array<Policy>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  asset_groups: Array<AssetGroups>,
  events: Array<Event>,
  users: Array<HorizonUserField>,
  compliance_standard_criteria: Array<ComplianceStandardCriteria>,
};

export type PolicyRuleListResponse = {
  __typename: 'PolicyRuleListResponse',
  pagination_information: PaginationInformation,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  users: Array<HorizonUserField>,
};

export type PolicyRulesByPolicyIdListResponse = {
  __typename: 'PolicyRulesByPolicyIdListResponse',
  pagination_information: PaginationInformation,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  users: Array<HorizonUserField>,
};

export type PolicyRuleTypeListResponse = {
  __typename: 'PolicyRuleTypeListResponse',
  pagination_information: PaginationInformation,
  policy_rule_types: Array<PolicyRuleType>,
};

export type PoliciesPolicyRuleTypeListResponse = {
  __typename: 'PoliciesPolicyRuleTypeListResponse',
  pagination_information: PaginationInformation,
  policy_rule_types: Array<PolicyRuleTypeV2>,
};

export type PolicyViolationListResponse = {
  __typename: 'PolicyViolationListResponse',
  pagination_information: PaginationInformation,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  users: Array<HorizonUserField>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  restful_apis: Array<RestfulApi>,
  web_applications: Array<WebApplication>,
  api_operations?: ?Array<ApiOperation>,
};

export type PolicyViolationListResponseVersion2 = {
  __typename: 'PolicyViolationListResponseVersion2',
  pagination_information: PaginationInformation,
  policy_violations: Array<PolicyViolationV2>,
};

export type PolicyViolationResponseVersion2 = {
  __typename: 'PolicyViolationResponseVersion2',
  policy_violation: PolicyViolationV2,
};

export type RestfulApIsListResponse = {
  __typename: 'RestfulAPIsListResponse',
  pagination_information: PaginationInformation,
  restful_apis: Array<RestfulApi>,
};

export type RestfulApIsListResponse_V1 = {
  __typename: 'RestfulAPIsListResponse_v1',
  pagination_information: PaginationInformation,
  restful_apis: Array<RestfulApi>,
  network_services: Array<NetworkService>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
};

export type RestfulApiDetailsResponse = {
  __typename: 'RestfulAPIDetailsResponse',
  restful_apis: Array<RestfulApi>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  web_applications: Array<WebApplication>,
  mobile_applications: Array<MobileApp>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
};

export type MobileApplicationDetailsResponse = {
  __typename: 'MobileApplicationDetailsResponse',
  cloud_resources: Array<CloudResource>,
  mobile_applications: Array<MobileApp>,
  restful_apis: Array<RestfulApi>,
};

export type PoliciesListResponse = {
  __typename: 'PoliciesListResponse',
  pagination_information: PaginationInformation,
  policies: Array<Policy>,
  users: Array<HorizonUserField>,
};

export type WebApplicationListResponse = {
  __typename: 'WebApplicationListResponse',
  pagination_information: PaginationInformation,
  web_applications: Array<WebApplication>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
};

export type WebApplicationListResponseVersion2 = {
  __typename: 'WebApplicationListResponseVersion2',
  pagination_information: PaginationInformation,
  web_applications: Array<WebApplicationVersion2>,
};

export type XssScanRunResultUrlGetParameter = {
  __typename: 'XssScanRunResultUrlGetParameter',
  name: $ElementType<Scalars, 'String'>,
  sample_value?: ?$ElementType<Scalars, 'String'>,
};

export type XssScanRunResultUrlGetParameterReflected = {
  __typename: 'XssScanRunResultUrlGetParameterReflected',
  name: $ElementType<Scalars, 'String'>,
  payload?: ?$ElementType<Scalars, 'String'>,
  page_src_position_where_reflected?: ?$ElementType<Scalars, 'Int'>,
  page_src_position_snippet?: ?$ElementType<Scalars, 'String'>,
};

export type XssScanRunResultUrlGetParameterVulnerableToXss = {
  __typename: 'XssScanRunResultUrlGetParameterVulnerableToXss',
  name: $ElementType<Scalars, 'String'>,
  payload?: ?$ElementType<Scalars, 'String'>,
  page_src_position_where_reflected?: ?$ElementType<Scalars, 'Int'>,
  page_src_position_snippet?: ?$ElementType<Scalars, 'String'>,
};

export type XssScanRunResultUrl = {
  __typename: 'XssScanRunResultUrl',
  scanned_get_parameters: Array<XssScanRunResultUrlGetParameter>,
  reflected_get_parameters: Array<XssScanRunResultUrlGetParameterReflected>,
  vulnerable_get_parameters: Array<XssScanRunResultUrlGetParameterVulnerableToXss>,
};

export type WebAppSummaryDiscoveredUrl = {
  __typename: 'WebAppSummaryDiscoveredUrl',
  url: $ElementType<Scalars, 'String'>,
  xss_scan_result?: ?XssScanRunResultUrl,
};

export type WebApplicationUrlsResponse = {
  __typename: 'WebApplicationUrlsResponse',
  urls: Array<WebAppSummaryDiscoveredUrl>,
};

export type NetworkServiceDetailsResponse = {
  __typename: 'NetworkServiceDetailsResponse',
  restful_apis: Array<RestfulApi>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  web_applications: Array<WebApplication>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  tls_scan_results: Array<TlsScanResult>,
  certificate_chains: Array<CertificateChain>,
  certificates: Array<Certificate>,
  asset_group_memberships: Array<AssetGroupMembership>,
  events: Array<Event>,
};

export type WebAppCredentialsStat = {
  __typename: 'WebAppCredentialsStat',
  detected_spa_credentials_count: $ElementType<Scalars, 'Int'>,
};

export type WebAppAssetsInventoryStats = {
  __typename: 'WebAppAssetsInventoryStats',
  important_assets_count: $ElementType<Scalars, 'Int'>,
  proactive_assets_count: $ElementType<Scalars, 'Int'>,
  total_assets_count: $ElementType<Scalars, 'Int'>,
  urgent_assets_count: $ElementType<Scalars, 'Int'>,
};

export type WebAppConnectedAssetsStats = {
  __typename: 'WebAppConnectedAssetsStats',
  api_management_cloud_resources_stats: WebAppAssetsInventoryStats,
  api_operations_stats: WebAppAssetsInventoryStats,
  database_cloud_resources_stats: WebAppAssetsInventoryStats,
  graphql_apis_stats: WebAppAssetsInventoryStats,
  network_services_stats: WebAppAssetsInventoryStats,
  other_cloud_resources_stats: WebAppAssetsInventoryStats,
  secret_management_cloud_resources_stats: WebAppAssetsInventoryStats,
  serverless_cloud_resources_stats: WebAppAssetsInventoryStats,
  storage_cloud_resources_stats: WebAppAssetsInventoryStats,
  virtual_machine_cloud_resources_stats: WebAppAssetsInventoryStats,
};

export type LatestScanRun = {
  __typename: 'LatestScanRun',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  scan_type: ToolkitScanTypeEnum,
  status: ScanRunsStatusEnum,
};

export type WebAppXssToolkitStats = {
  __typename: 'WebAppXssToolkitStats',
  next_xss_scan_run_date?: ?$ElementType<Scalars, 'Date'>,
  latest_xss_scan_run?: ?LatestScanRun,
  urls_count: $ElementType<Scalars, 'Int'>,
  open_xss_policy_violations_count: $ElementType<Scalars, 'Int'>,
  xss_result: XssToolkitResultEnum,
};

export type WebApplicationDetailsResponse = {
  __typename: 'WebApplicationDetailsResponse',
  web_applications: Array<WebApplication>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  graphql_apis: Array<GraphqlApi>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  restful_apis: Array<RestfulApi>,
  cloud_resources: Array<CloudResource>,
};

export type DetectedSpaCredentialUsedIn = {
  __typename: 'DetectedSpaCredentialUsedIn',
  header_name: $ElementType<Scalars, 'String'>,
  header_value: $ElementType<Scalars, 'String'>,
  method: $ElementType<Scalars, 'String'>,
  url: $ElementType<Scalars, 'String'>,
};

export type DetectedSpaCredential = {
  __typename: 'DetectedSpaCredential',
  id: $ElementType<Scalars, 'ID'>,
  value: $ElementType<Scalars, 'String'>,
  web_application_id: $ElementType<Scalars, 'ID'>,
  found_at_url: $ElementType<Scalars, 'String'>,
  credential_as_markdown: $ElementType<Scalars, 'String'>,
  exported_to_policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  triaging_decision: CredentialTriagingDecisionEnum,
  used_in_request: DetectedSpaCredentialUsedIn,
};

export type WebApplicationCredentialsResponse = {
  __typename: 'WebApplicationCredentialsResponse',
  detected_spa_credentials: Array<DetectedSpaCredential>,
};

export type DetectedSpaCredentialsResponse = {
  __typename: 'DetectedSpaCredentialsResponse',
  detected_spa_credentials: Array<DetectedSpaCredential>,
  pagination_information: PaginationInformation,
};

export type CredentialTriageResponse = {
  __typename: 'CredentialTriageResponse',
  detected_spa_credential: DetectedSpaCredential,
};

export type CloudAuthenticatorsResponse_Deprecated = {
  __typename: 'CloudAuthenticatorsResponse_DEPRECATED',
  pagination_information: PaginationInformation,
  cloud_authenticators: Array<CloudAuthenticator_Deprecated>,
};

export type CloudAuthenticatorsResponse = {
  __typename: 'CloudAuthenticatorsResponse',
  pagination_information: PaginationInformation,
  cloud_authenticators: Array<CloudAuthenticator>,
};

export type AlertsIntegrationsResponse = {
  __typename: 'AlertsIntegrationsResponse',
  pagination_information: PaginationInformation,
  alerts_integrations: Array<AlertsIntegration>,
};

export type ApigeeAuthenticatorsCreateResponse = {
  __typename: 'ApigeeAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  organization: $ElementType<Scalars, 'String'>,
};

export type AwsAuthenticatorsCreateResponse = {
  __typename: 'AwsAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  role_arn: $ElementType<Scalars, 'String'>,
  external_id: $ElementType<Scalars, 'String'>,
};

export type AxwayAuthenticatorsCreateResponse = {
  __typename: 'AxwayAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  client_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
  added_by_external_user_email?: ?$ElementType<Scalars, 'String'>,
};

export type AzureAuthenticatorsCreateResponse = {
  __typename: 'AzureAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  tenant_id: $ElementType<Scalars, 'String'>,
  client_id: $ElementType<Scalars, 'String'>,
};

export type GcpAuthenticatorsCreateResponse = {
  __typename: 'GcpAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
  client_email: $ElementType<Scalars, 'String'>,
};

export type KongAuthenticatorsCreateResponse = {
  __typename: 'KongAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  kong_admin_subdomain: $ElementType<Scalars, 'String'>,
};

export type MulesoftAuthenticatorsCreateResponse = {
  __typename: 'MulesoftAuthenticatorsCreateResponse',
  id: $ElementType<Scalars, 'String'>,
  date_created: $ElementType<Scalars, 'Date'>,
  added_by_user_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
};

export type DetectAndInjectScanRunConfigurationType = {
  __typename: 'DetectAndInjectScanRunConfigurationType',
  http_methods_to_scan?: ?Array<HttpMethod>,
};

export type DetectAndInjectConfigurationType = {
  __typename: 'DetectAndInjectConfigurationType',
  api_operation_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
  should_scan_all_api_operations?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?DetectAndInjectScanRunConfigurationType,
};

export type DetectAndInjectScansSchedulesResponse = {
  __typename: 'DetectAndInjectScansSchedulesResponse',
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationType,
};

export type DetectAndInjectScansScheduleSetResponse = {
  __typename: 'DetectAndInjectScansScheduleSetResponse',
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationType,
};

export type WebApplicationXssScanRunConfigurationType = {
  __typename: 'WebApplicationXssScanRunConfigurationType',
  should_inject_in_get_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_post_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_url_path?: ?$ElementType<Scalars, 'Boolean'>,
  intensity?: ?WebApplicationXssScanIntensityEnum,
};

export type WebApplicationXssConfigurationType = {
  __typename: 'WebApplicationXssConfigurationType',
  web_applications_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  should_scan_all_web_applications?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?WebApplicationXssScanRunConfigurationType,
};

export type WebApplicationXssScansSchedulesResponse = {
  __typename: 'WebApplicationXssScansSchedulesResponse',
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationType,
};

export type ComplianceReportConfigurationType = {
  __typename: 'ComplianceReportConfigurationType',
  recipient_emails: Array<$ElementType<Scalars, 'String'>>,
  compliance_report_type: ToolkitScanTypeEnum,
  should_report_all_mobile_apps: $ElementType<Scalars, 'Boolean'>,
  customer_mobile_app_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ComplianceReportScheduleResponse = {
  __typename: 'ComplianceReportScheduleResponse',
  should_only_scan_monthly: $ElementType<Scalars, 'Boolean'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  toolkit_scan_type: ToolkitScanTypeEnum,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  compliance_reports_configuration: ComplianceReportConfigurationType,
};

export type TriggeredScanRuns = {
  __typename: 'TriggeredScanRuns',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  scanned_asset_id: $ElementType<Scalars, 'ID'>,
  scanned_asset_type: AssetTypeEnum,
};

export type ToolkitScanStartRequestResponse = {
  __typename: 'ToolkitScanStartRequestResponse',
  toolkit_scan_type: ToolkitScanTypeEnum,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationType,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationType,
  triggered_scan_runs?: ?Array<TriggeredScanRuns>,
};

export type AssetsInventoryStats = {
  __typename: 'AssetsInventoryStats',
  assets_total_count: $ElementType<Scalars, 'Int'>,
  shadow_assets_count: $ElementType<Scalars, 'Int'>,
  urgent_assets_count: $ElementType<Scalars, 'Int'>,
  important_assets_count: $ElementType<Scalars, 'Int'>,
  proactive_assets_count: $ElementType<Scalars, 'Int'>,
};

export type CloudResourcesInventoryStats = {
  __typename: 'CloudResourcesInventoryStats',
  assets_total_count: $ElementType<Scalars, 'Int'>,
  shadow_assets_count: $ElementType<Scalars, 'Int'>,
  urgent_assets_count: $ElementType<Scalars, 'Int'>,
  important_assets_count: $ElementType<Scalars, 'Int'>,
  proactive_assets_count: $ElementType<Scalars, 'Int'>,
  serverless_assets_count: $ElementType<Scalars, 'Int'>,
  shadow_serverless_assets_count: $ElementType<Scalars, 'Int'>,
};

export type AllAssetsStats = {
  __typename: 'AllAssetsStats',
  network_services_stats: AssetsInventoryStats,
  restful_apis_stats: AssetsInventoryStats,
  web_applications_stats: AssetsInventoryStats,
  cloud_resources_stats: CloudResourcesInventoryStats,
};

export type InventoryStats = {
  __typename: 'InventoryStats',
  reference_date: $ElementType<Scalars, 'Date'>,
  all_assets_stats: AllAssetsStats,
  aws_assets_stats: AllAssetsStats,
  gcp_assets_stats: AllAssetsStats,
  apigee_assets_stats: AllAssetsStats,
  mulesoft_assets_stats: AllAssetsStats,
  kong_assets_stats: AllAssetsStats,
  axway_assets_stats: AllAssetsStats,
  azure_assets_stats: AllAssetsStats,
  unresolved_policy_violations_total_count: $ElementType<Scalars, 'Int'>,
  resolved_policy_violations_total_count: $ElementType<Scalars, 'Int'>,
};

export type InventoryStatsResponse = {
  __typename: 'InventoryStatsResponse',
  inventory_stats: Array<InventoryStats>,
};

export type SpecialScanRequestConfiguration = {
  __typename: 'SpecialScanRequestConfiguration',
  selected_all_web_applications?: ?$ElementType<Scalars, 'Boolean'>,
  selected_web_application_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type SpecialScanRequest = {
  __typename: 'SpecialScanRequest',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  date_completed?: ?$ElementType<Scalars, 'Date'>,
  created_by_user_id?: ?$ElementType<Scalars, 'ID'>,
  current_status: SpecialScanStatus,
  scan_type: SpecialScanType,
  scan_configuration?: ?SpecialScanRequestConfiguration,
};

export type SpecialScanRequestResponse = {
  __typename: 'SpecialScanRequestResponse',
  special_scan_requests: Array<SpecialScanRequest>,
};

export type AssetGroupMembershipsOverview = {
  __typename: 'AssetGroupMembershipsOverview',
  restful_apis_count: $ElementType<Scalars, 'Int'>,
  api_operations_count: $ElementType<Scalars, 'Int'>,
  web_applications_count: $ElementType<Scalars, 'Int'>,
  network_services_count: $ElementType<Scalars, 'Int'>,
  cloud_resources_count: $ElementType<Scalars, 'Int'>,
};

export type AssetGroups = {
  __typename: 'AssetGroups',
  id: $ElementType<Scalars, 'ID'>,
  date_created: $ElementType<Scalars, 'Date'>,
  name: $ElementType<Scalars, 'String'>,
  is_read_only: $ElementType<Scalars, 'Boolean'>,
  description: $ElementType<Scalars, 'String'>,
  attached_policy_id: $ElementType<Scalars, 'String'>,
  created_by_user_id: $ElementType<Scalars, 'ID'>,
  created_by_user: HorizonUserField,
  asset_group_memberships_overview: AssetGroupMembershipsOverview,
};

export type AssetGroupListResponse = {
  __typename: 'AssetGroupListResponse',
  pagination_information: PaginationInformation,
  asset_groups: Array<AssetGroups>,
  users: Array<HorizonUserField>,
};

export type SingleAssetGroupResponse = {
  __typename: 'SingleAssetGroupResponse',
  asset_groups: Array<AssetGroups>,
  events: Array<Event>,
  policies: Array<Policy>,
  policy_rule_types: Array<PolicyRuleType>,
  policy_rules: Array<PolicyRule>,
  users: Array<HorizonUserField>,
};

export type AssetSummary = {
  __typename: 'AssetSummary',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_important_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Int'>,
  discovered_via: DiscoveredVia,
  hosted_on: HostedOn,
  date_created: $ElementType<Scalars, 'Date'>,
};

export type AssetGroupAssetsSummaryListResponse = {
  __typename: 'AssetGroupAssetsSummaryListResponse',
  pagination_information: PaginationInformation,
  assets: Array<AssetSummary>,
};

export type ShadowAssetsMembersResponse = {
  __typename: 'ShadowAssetsMembersResponse',
  pagination_information: PaginationInformation,
  asset_group_memberships: Array<AssetGroupMembership>,
  users: Array<HorizonUserField>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  cloud_resources: Array<CloudResource>,
  restful_apis: Array<RestfulApi>,
  web_applications: Array<WebApplication>,
};

export type ShadowAssetsConfiguration = {
  __typename: 'ShadowAssetsConfiguration',
  days_count_when_move_assets_out_of_group?: ?$ElementType<Scalars, 'Int'>,
  should_add_assets_hosted_in_cloud_providers?: ?Array<$ElementType<Scalars, 'String'>>,
  should_add_assets_hosted_in_unknown_cloud_environment: $ElementType<Scalars, 'Boolean'>,
  should_add_assets_not_used_by_mobile_or_web_applications: $ElementType<Scalars, 'Boolean'>,
  should_add_assets_recently_discovered_via_source?: ?ShouldAddAssetsRecentlyDiscoveredViaSource,
  should_add_cloud_resource_of_types?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type ShadowAssetsConfigurationResponse = {
  __typename: 'ShadowAssetsConfigurationResponse',
  configuration: ShadowAssetsConfiguration,
};

export type WebApplicationsStatsResponse = {
  __typename: 'WebApplicationsStatsResponse',
  web_applications_stats: Array<WebAppAssetsInventoryStats>,
};

export type CloudResourceListResponse = {
  __typename: 'CloudResourceListResponse',
  pagination_information: PaginationInformation,
  cloud_resources: Array<CloudResource>,
};

export type CloudResourceDetailsResponse = {
  __typename: 'CloudResourceDetailsResponse',
  asset_groups: Array<AssetGroups>,
  cloud_resources: Array<CloudResource>,
  mobile_applications: Array<MobileApp>,
  domain_names: Array<DomainName>,
  events: Array<Event>,
  network_services: Array<NetworkService>,
  policy_rule_types: Array<PolicyRuleType>,
  policy_rules: Array<PolicyRule>,
  policy_violations: Array<PolicyViolation>,
  restful_apis: Array<RestfulApi>,
  web_applications: Array<WebApplication>,
};

export type GraphqlApiDetailsResponse = {
  __typename: 'GraphqlApiDetailsResponse',
  asset_groups: Array<AssetGroups>,
  domain_names: Array<DomainName>,
  events: Array<Event>,
  graphql_apis: Array<GraphqlApi>,
  mobile_applications: Array<MobileApp>,
  network_services: Array<NetworkService>,
  policy_rule_types: Array<PolicyRuleType>,
  policy_rules: Array<PolicyRule>,
  policy_violations: Array<PolicyViolation>,
  web_applications: Array<WebApplication>,
};

export type FullStackMetrics = {
  __typename: 'FullStackMetrics',
  api_operations_total_count: $ElementType<Scalars, 'Int'>,
  api_operations_exercised_by_mobile_applications_count: $ElementType<Scalars, 'Int'>,
  api_operations_exercised_by_web_applications_count: $ElementType<Scalars, 'Int'>,
  api_operations_hosted_on_cloud_platforms_count: $ElementType<Scalars, 'Int'>,
};

export type GraphqlApiListResponse = {
  __typename: 'GraphqlApiListResponse',
  pagination_information: PaginationInformation,
  graphql_apis: Array<GraphqlApi>,
};

export type WebApplicationXssToolkitMetrics = {
  __typename: 'WebApplicationXssToolkitMetrics',
  urls_count: $ElementType<Scalars, 'Int'>,
  web_applications_count: $ElementType<Scalars, 'Int'>,
  vulnerable_web_applications_count: $ElementType<Scalars, 'Int'>,
  watchlisted_web_applications_count: $ElementType<Scalars, 'Int'>,
  xss_scan_runs_count: $ElementType<Scalars, 'Int'>,
  ongoing_xss_scan_runs_count: $ElementType<Scalars, 'Int'>,
};

export type PerEnvironmentCloudResourceCategoriesMetrics = {
  __typename: 'PerEnvironmentCloudResourceCategoriesMetrics',
  assets_total_count: $ElementType<Scalars, 'Int'>,
  serverless_assets_count: $ElementType<Scalars, 'Int'>,
  database_assets_count: $ElementType<Scalars, 'Int'>,
  virtual_machine_assets_count: $ElementType<Scalars, 'Int'>,
  storage_assets_count: $ElementType<Scalars, 'Int'>,
  api_management_assets_count: $ElementType<Scalars, 'Int'>,
  secret_management_assets_count: $ElementType<Scalars, 'Int'>,
  other_assets_count: $ElementType<Scalars, 'Int'>,
  kubernetes_assets_count: $ElementType<Scalars, 'Int'>,
};

export type CloudResourceCategoriesMetrics = {
  __typename: 'CloudResourceCategoriesMetrics',
  reference_date: $ElementType<Scalars, 'Date'>,
  all_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
  aws_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
  gcp_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
  azure_assets_stats: PerEnvironmentCloudResourceCategoriesMetrics,
};

export type Metrics = {
  __typename: 'Metrics',
  metric_type: MetricTypes,
  full_stack_metrics?: ?FullStackMetrics,
  xss_toolkit_metrics?: ?WebApplicationXssToolkitMetrics,
  cloud_resource_categories_metrics?: ?CloudResourceCategoriesMetrics,
};

export type MetricsResponse = {
  __typename: 'MetricsResponse',
  metrics: Array<Metrics>,
};

export type IpRangeCreateResponse = {
  __typename: 'IpRangeCreateResponse',
  ip_ranges: Array<IpRange>,
};

export type IpRangeListResponse = {
  __typename: 'IpRangeListResponse',
  pagination_information: PaginationInformation,
  ip_ranges: Array<IpRange>,
};

export type IpRangeScanCreateResponse = {
  __typename: 'IpRangeScanCreateResponse',
  ip_range_scans: Array<IpRangeScan>,
  ip_ranges: Array<IpRange>,
};

export type IpRangePatchResponse = {
  __typename: 'IpRangePatchResponse',
  ip_ranges: Array<IpRange>,
};

export type PolicyViolationsPatchResponse = {
  __typename: 'PolicyViolationsPatchResponse',
  policy_violations: Array<PolicyViolation>,
};

export type OpenApiDefinitionsListResponse = {
  __typename: 'OpenAPIDefinitionsListResponse',
  pagination_information: PaginationInformation,
  openapi_definitions: Array<OpenApiDefinition>,
};

export type OpenApiDefinitionsCreateResponse = {
  __typename: 'OpenApiDefinitionsCreateResponse',
  id: $ElementType<Scalars, 'ID'>,
  domain_names: Array<DomainName>,
  network_services: Array<NetworkService>,
  restful_apis: Array<RestfulApi>,
};

export type SharedLinksCreateForPolicyViolationResponse = {
  __typename: 'SharedLinksCreateForPolicyViolationResponse',
  shared_resource_id?: ?$ElementType<Scalars, 'String'>,
  shared_resource_type: SharedLinksResourceTypeEnum,
  access_token: $ElementType<Scalars, 'String'>,
};

export type BulkActionResponse = {
  __typename: 'BulkActionResponse',
  action_type?: ?BulkActionType,
  status?: ?BulkActionStatus,
};

export type PaginationInformation = {
  __typename: 'PaginationInformation',
  next_cursor?: ?$ElementType<Scalars, 'ID'>,
  total_count?: ?$ElementType<Scalars, 'Int'>,
};

export type PolicyViolationResponse = {
  __typename: 'PolicyViolationResponse',
  web_applications: Array<WebApplication>,
  network_services: Array<NetworkService>,
  domain_names: Array<DomainName>,
  policy_violations: Array<PolicyViolation>,
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  restful_apis: Array<RestfulApi>,
  graphql_apis: Array<GraphqlApi>,
  api_operations: Array<ApiOperation>,
  cloud_resources: Array<CloudResource>,
};

export type ApiOperationListResponse = {
  __typename: 'ApiOperationListResponse',
  pagination_information: PaginationInformation,
  api_operations: Array<ApiOperation>,
};

export type PolicyRuleTypesResponse = {
  __typename: 'PolicyRuleTypesResponse',
  policy_rule_types: Array<PolicyRuleType>,
};

export type PolicyRulesUpdateResponse = {
  __typename: 'PolicyRulesUpdateResponse',
  policy_rules: Array<PolicyRule>,
  policy_rule_types: Array<PolicyRuleType>,
  events: Array<Event>,
};

export type OnboardingCompletionResponse = {
  __typename: 'OnboardingCompletionResponse',
  onboarding_completed: $ElementType<Scalars, 'Boolean'>,
};

export type EmptyResponse = {
  __typename: 'EmptyResponse',
  nothing?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ScanLogResponse = {
  __typename: 'ScanLogResponse',
  text: $ElementType<Scalars, 'String'>,
};

export const HorizonEventTypeEnumValues = Object.freeze({
  UserLoggedIn: 'USER_LOGGED_IN', 
  NetworkServiceDiscovered: 'NETWORK_SERVICE_DISCOVERED', 
  RestfulApiDiscovered: 'RESTFUL_API_DISCOVERED', 
  CloudResourceDiscovered: 'CLOUD_RESOURCE_DISCOVERED', 
  AwsAuthenticatorCreated: 'AWS_AUTHENTICATOR_CREATED', 
  PolicyViolationCreated: 'POLICY_VIOLATION_CREATED', 
  PolicyViolationResolved: 'POLICY_VIOLATION_RESOLVED', 
  PolicyViolationExceptionAdded: 'POLICY_VIOLATION_EXCEPTION_ADDED', 
  NetworkServiceWentOffline: 'NETWORK_SERVICE_WENT_OFFLINE', 
  NetworkServiceWentOnline: 'NETWORK_SERVICE_WENT_ONLINE', 
  CommentCreated: 'COMMENT_CREATED', 
  CommentDeleted: 'COMMENT_DELETED', 
  OpenapiDefinitionCreated: 'OPENAPI_DEFINITION_CREATED', 
  RestfulApiUpdated: 'RESTFUL_API_UPDATED', 
  ApiOperationUpdated: 'API_OPERATION_UPDATED', 
  ApiOperationNoLongerAccessible: 'API_OPERATION_NO_LONGER_ACCESSIBLE', 
  ApiOperationCreated: 'API_OPERATION_CREATED', 
  CertificateDiscovered: 'CERTIFICATE_DISCOVERED', 
  CertificateChainDiscovered: 'CERTIFICATE_CHAIN_DISCOVERED', 
  CertificateChainRemoved: 'CERTIFICATE_CHAIN_REMOVED', 
  AzureAuthenticatorCreated: 'AZURE_AUTHENTICATOR_CREATED', 
  AzureAuthenticatorUpdated: 'AZURE_AUTHENTICATOR_UPDATED', 
  GcpAuthenticatorCreated: 'GCP_AUTHENTICATOR_CREATED', 
  GcpAuthenticatorKeyUpdated: 'GCP_AUTHENTICATOR_KEY_UPDATED', 
  ApigeeAuthenticatorCreated: 'APIGEE_AUTHENTICATOR_CREATED', 
  PolicyRuleCreated: 'POLICY_RULE_CREATED', 
  PolicyRuleDeleted: 'POLICY_RULE_DELETED', 
  IpRangeCreated: 'IP_RANGE_CREATED', 
  IpRangeUpdated: 'IP_RANGE_UPDATED', 
  WebApplicationDiscovered: 'WEB_APPLICATION_DISCOVERED', 
  MulesoftAuthenticatorCreated: 'MULESOFT_AUTHENTICATOR_CREATED', 
  PolicyRuleUpdated: 'POLICY_RULE_UPDATED', 
  ApigeeOnPremAuthenticatorCreated: 'APIGEE_ON_PREM_AUTHENTICATOR_CREATED', 
  AlertsIntegrationCreated: 'ALERTS_INTEGRATION_CREATED', 
  AlertsIntegrationUpdated: 'ALERTS_INTEGRATION_UPDATED', 
  AlertsIntegrationDeleted: 'ALERTS_INTEGRATION_DELETED', 
  AssetGroupCreated: 'ASSET_GROUP_CREATED', 
  AssetGroupDeleted: 'ASSET_GROUP_DELETED', 
  AssetGroupUpdated: 'ASSET_GROUP_UPDATED', 
  AssetGroupMembershipCreated: 'ASSET_GROUP_MEMBERSHIP_CREATED', 
  AssetGroupMembershipDeleted: 'ASSET_GROUP_MEMBERSHIP_DELETED', 
  PolicyCreated: 'POLICY_CREATED', 
  PolicyDeleted: 'POLICY_DELETED', 
  PolicyUpdated: 'POLICY_UPDATED', 
  KongAuthenticatorCreated: 'KONG_AUTHENTICATOR_CREATED', 
  KongAuthenticatorUpdated: 'KONG_AUTHENTICATOR_UPDATED', 
  ScopedAccessTokenCreated: 'SCOPED_ACCESS_TOKEN_CREATED', 
  ScopedAccessTokenDeleted: 'SCOPED_ACCESS_TOKEN_DELETED', 
  DomainNameOwnershipUpdated: 'DOMAIN_NAME_OWNERSHIP_UPDATED', 
  RestfulApiOwnershipUpdated: 'RESTFUL_API_OWNERSHIP_UPDATED', 
  CloudAuthenticatorCreated: 'CLOUD_AUTHENTICATOR_CREATED', 
  CloudAuthenticatorUpdated: 'CLOUD_AUTHENTICATOR_UPDATED', 
  AutoRemediationAttemptCreated: 'AUTO_REMEDIATION_ATTEMPT_CREATED', 
  AutoRemediationAttemptStarted: 'AUTO_REMEDIATION_ATTEMPT_STARTED', 
  AutoRemediationAttemptSucceeded: 'AUTO_REMEDIATION_ATTEMPT_SUCCEEDED', 
  AutoRemediationAttemptFailed: 'AUTO_REMEDIATION_ATTEMPT_FAILED', 
  AutoRemediationAttemptBatchCreated: 'AUTO_REMEDIATION_ATTEMPT_BATCH_CREATED', 
  AxwayAuthenticatorCreated: 'AXWAY_AUTHENTICATOR_CREATED', 
  AxwayAuthenticatorUpdated: 'AXWAY_AUTHENTICATOR_UPDATED', 
  RestfulApiNoLongerAccessible: 'RESTFUL_API_NO_LONGER_ACCESSIBLE', 
  WebApplicationNoLongerAccessible: 'WEB_APPLICATION_NO_LONGER_ACCESSIBLE', 
  CloudResourceDeletedFromCloud: 'CLOUD_RESOURCE_DELETED_FROM_CLOUD', 
  PolicyViolationTriagingEffortCreated: 'POLICY_VIOLATION_TRIAGING_EFFORT_CREATED', 
  PolicyViolationTriagingEffortCompleted: 'POLICY_VIOLATION_TRIAGING_EFFORT_COMPLETED', 
  PolicyViolationTriagingEffortDeleted: 'POLICY_VIOLATION_TRIAGING_EFFORT_DELETED', 
  ToolkitScansScheduleEnabled: 'TOOLKIT_SCANS_SCHEDULE_ENABLED', 
  ToolkitScansScheduleUpdated: 'TOOLKIT_SCANS_SCHEDULE_UPDATED', 
  ToolkitScansScheduleDisabled: 'TOOLKIT_SCANS_SCHEDULE_DISABLED', 
  ApiCredentialCreated: 'API_CREDENTIAL_CREATED', 
  ApiCredentialDeleted: 'API_CREDENTIAL_DELETED', 
  GraphqlApiCreated: 'GRAPHQL_API_CREATED', 
  GraphqlApiNoLongerAccessible: 'GRAPHQL_API_NO_LONGER_ACCESSIBLE', 
  WebApplicationWentOnline: 'WEB_APPLICATION_WENT_ONLINE', 
  CloudResourceWentOnline: 'CLOUD_RESOURCE_WENT_ONLINE', 
  RestfulApiWentOnline: 'RESTFUL_API_WENT_ONLINE', 
  GraphqlApiWentOnline: 'GRAPHQL_API_WENT_ONLINE'
});


export type HorizonEventTypeEnum = $Values<typeof HorizonEventTypeEnumValues>;

export const SevenhellEventTypeEnumValues = Object.freeze({
  Comment: 'COMMENT', 
  PermanentlyCloseIssue: 'PERMANENTLY_CLOSE_ISSUE', 
  CloseTarget: 'CLOSE_TARGET', 
  ChangePriority: 'CHANGE_PRIORITY', 
  ReOpenIssue: 'RE_OPEN_ISSUE', 
  ReOpenTarget: 'RE_OPEN_TARGET', 
  AddNewApp: 'ADD_NEW_APP'
});


export type SevenhellEventTypeEnum = $Values<typeof SevenhellEventTypeEnumValues>;

export const ScanRunsTypeEnumValues = Object.freeze({
  WebApplicationXss: 'WEB_APPLICATION_XSS'
});


export type ScanRunsTypeEnum = $Values<typeof ScanRunsTypeEnumValues>;

export const ScanRunsStatusEnumValues = Object.freeze({
  Completed: 'COMPLETED', 
  Ongoing: 'ONGOING', 
  Started: 'STARTED', 
  Queued: 'QUEUED', 
  Failed: 'FAILED'
});


export type ScanRunsStatusEnum = $Values<typeof ScanRunsStatusEnumValues>;

export type ScanRun = {
  __typename: 'ScanRun',
  uuid: $ElementType<Scalars, 'ID'>,
  scan_type: ScanRunsTypeEnum,
  status: ScanRunsStatusEnum,
  date_created?: ?$ElementType<Scalars, 'Date'>,
  date_started?: ?$ElementType<Scalars, 'Date'>,
  date_finished?: ?$ElementType<Scalars, 'Date'>,
  logs_file_url?: ?$ElementType<Scalars, 'String'>,
  result_issues_found_count?: ?$ElementType<Scalars, 'Int'>,
  result_markdown?: ?$ElementType<Scalars, 'String'>,
  scanned_asset_display_name?: ?$ElementType<Scalars, 'String'>,
  triggered_by_actor_display_name?: ?$ElementType<Scalars, 'String'>,
};

export type ScanRunsResponse = {
  __typename: 'ScanRunsResponse',
  pagination_information: PaginationInformation,
  scan_runs: Array<ScanRun>,
};

export type ComplianceReportResponse = {
  __typename: 'ComplianceReportResponse',
  download_url: $ElementType<Scalars, 'String'>,
};

export type ToolkitScans = {
  __typename: 'ToolkitScans',
  date_created?: ?$ElementType<Scalars, 'Date'>,
  date_finished?: ?$ElementType<Scalars, 'Date'>,
  status: SpecialScanStatus,
  toolkit_scan_type: ToolkitScanTypeEnum,
};

export type ToolkitScansResponse = {
  __typename: 'ToolkitScansResponse',
  pagination_information: PaginationInformation,
  toolkit_scans: Array<?ToolkitScans>,
};

export const FingerprintedResourceCategoryEnumValues = Object.freeze({
  FingerprintedAwsS3Bucket: 'FINGERPRINTED_AWS_S3_BUCKET', 
  FingerprintedGcpCloudStorageBucket: 'FINGERPRINTED_GCP_CLOUD_STORAGE_BUCKET', 
  FingerprintedDockerRegistry: 'FINGERPRINTED_DOCKER_REGISTRY', 
  FingerprintedRedisDatabase: 'FINGERPRINTED_REDIS_DATABASE', 
  FingerprintedMongodbInstance: 'FINGERPRINTED_MONGODB_INSTANCE', 
  FingerprintedAzureStorageAccountBlobContainer: 'FINGERPRINTED_AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER', 
  FingerprintedCassandraDbDatabase: 'FINGERPRINTED_CASSANDRA_DB_DATABASE', 
  FingerprintedCouchdbDatabase: 'FINGERPRINTED_COUCHDB_DATABASE', 
  FingerprintedElasticsearchDatabase: 'FINGERPRINTED_ELASTICSEARCH_DATABASE', 
  FingerprintedSolrDatabase: 'FINGERPRINTED_SOLR_DATABASE'
});


export type FingerprintedResourceCategoryEnum = $Values<typeof FingerprintedResourceCategoryEnumValues>;

export type DomanNamesFingerprintedResourceCategoriesStats = {
  __typename: 'DomanNamesFingerprintedResourceCategoriesStats',
  fingerprinted_resource_category?: ?FingerprintedResourceCategoryEnum,
  fingerprinted_resources_count?: ?$ElementType<Scalars, 'Int'>,
  unauthenticated_fingerprinted_resources_count?: ?$ElementType<Scalars, 'Int'>,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
};

export type DomainNamesLastScanStats = {
  __typename: 'DomainNamesLastScanStats',
  scanned_domain_names_count?: ?$ElementType<Scalars, 'Int'>,
  fingerprinted_resources_count?: ?$ElementType<Scalars, 'Int'>,
  unauthenticated_fingerprinted_resources_count?: ?$ElementType<Scalars, 'Int'>,
  date_since_last_breached?: ?$ElementType<Scalars, 'Int'>,
};

export type FingerprintedResourceStatsField = {
  __typename: 'FingerprintedResourceStatsField',
  fingerprinted_resources_categories_stats: Array<DomanNamesFingerprintedResourceCategoriesStats>,
  last_scan_stats: DomainNamesLastScanStats,
};

export type DomainNamesStatsResponse = {
  __typename: 'DomainNamesStatsResponse',
  fingerprinted_resource_stats_field: FingerprintedResourceStatsField,
};

export type DomainNameListResponse = {
  __typename: 'DomainNameListResponse',
  pagination_information: PaginationInformation,
  domain_names: Array<DomainName>,
};

export type AssetGroupField = {
  __typename: 'AssetGroupField',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
};

export type AssetSummaryField = {
  __typename: 'AssetSummaryField',
  id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  url?: ?$ElementType<Scalars, 'String'>,
  status: AssetStatus,
  asset_type: AssetTypeEnum,
  asset_type_icon_url: $ElementType<Scalars, 'String'>,
  asset_type_name: $ElementType<Scalars, 'String'>,
  belongs_to_asset_group?: ?AssetGroupField,
  date_created: $ElementType<Scalars, 'Date'>,
  date_no_longer_accessible?: ?$ElementType<Scalars, 'String'>,
  discovered_via: DiscoveredVia,
  hosted_on?: ?HostedOn,
  open_important_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_proactive_policy_violations_count: $ElementType<Scalars, 'Int'>,
  open_urgent_policy_violations_count: $ElementType<Scalars, 'Int'>,
};

export type AssetListResponse = {
  __typename: 'AssetListResponse',
  pagination_information: PaginationInformation,
  assets: Array<AssetSummaryField>,
};

export type Query = {
  __typename: 'Query',
  compliance_standard_criteria_list: ComplianceStandardCriteriaListResponse,
  notification_events_list: EventsListResponse,
  policy_rules_by_policy_id_list: PolicyRulesByPolicyIdListResponse,
  policy_rules_list: PolicyRuleListResponse,
  policy_rule_types_list: PolicyRuleTypeListResponse,
  policies_policy_rule_types_list: PoliciesPolicyRuleTypeListResponse,
  policy_violations_list: PolicyViolationListResponse,
  policy_violations_list_v2: PolicyViolationListResponseVersion2,
  policy_violation_details_v2: PolicyViolationV2,
  policy_violation_by_id: PolicyViolationResponse,
  restful_api_list: RestfulApIsListResponse,
  restful_api_list_v1: RestfulApIsListResponse_V1,
  restful_api_details: RestfulApiDetailsResponse,
  api_operation_details: ApiOperationDetailsResponse,
  api_operation_details_v2: ApiOperationDetailsResponseVersion2,
  network_service_details: NetworkServiceDetailsResponse,
  policies_list: PoliciesListResponse,
  policy_by_id: PolicyDetailResponse,
  web_applications_list: WebApplicationListResponse,
  web_applications_list_v2: WebApplicationListResponseVersion2,
  web_application_details: WebApplicationDetailsResponse,
  web_application_credentials: WebApplicationCredentialsResponse,
  web_application_urls: WebApplicationUrlsResponse,
  mobile_application_details: MobileApplicationDetailsResponse,
  inventory_stats_get: InventoryStatsResponse,
  inventory_stats_reference_date_get: InventoryStatsResponse,
  special_scan_requests_list: SpecialScanRequestResponse,
  asset_group_list: AssetGroupListResponse,
  asset_group_details: SingleAssetGroupResponse,
  asset_group_assets_summary_list: AssetGroupAssetsSummaryListResponse,
  shadow_assets_all_members_list: ShadowAssetsMembersResponse,
  shadow_assets_configuration_get: ShadowAssetsConfigurationResponse,
  detected_spa_credentials_list: DetectedSpaCredentialsResponse,
  cloud_authenticators_list_DEPRECATED: CloudAuthenticatorsResponse_Deprecated,
  cloud_authenticators_list: CloudAuthenticatorsResponse,
  alerts_integrations_list: AlertsIntegrationsResponse,
  policy_rule_list: PolicyRuleListResponse,
  web_applications_stats_get: WebApplicationsStatsResponse,
  api_operation_list: ApiOperationListResponse,
  cloud_resource_list: CloudResourceListResponse,
  cloud_resource_details_v2: CloudResourceV2,
  cloud_resource_details_policy_violations_list: PolicyViolationListResponseVersion2,
  cloud_resource_details: CloudResourceDetailsResponse,
  cloud_resource_categories_metrics: MetricsResponse,
  graphql_api_list: GraphqlApiListResponse,
  graphql_api_details: GraphqlApiDetailsResponse,
  policy_rule_type_list: PolicyRuleTypesResponse,
  full_stack_security_metrics: MetricsResponse,
  web_application_xss_toolkit_metrics: MetricsResponse,
  ip_ranges_list: IpRangeListResponse,
  openapi_definitions_list: OpenApiDefinitionsListResponse,
  detect_and_inject_scans_schedules: DetectAndInjectScansSchedulesResponse,
  web_application_xss_scans_schedules: WebApplicationXssScansSchedulesResponse,
  compliance_report_schedules: ComplianceReportScheduleResponse,
  toolkit_scans: ToolkitScansResponse,
  domain_name_list: DomainNameListResponse,
  domain_names_stats: DomainNamesStatsResponse,
  asset_list: AssetListResponse,
  users_list: UsersListResponse,
  user_by_id?: ?User,
  portal_notifications_list: PortalNotificationsListResponse,
  mobile_apps_list: MobileAppsListResponse,
  mobile_app_analysis_schedules_get: MobileAppAnalysisScheduleResponse,
  mobile_app_analysis_requests_list: MobileAppAnalysisRequestListResponse,
  mobile_app_get: MobileApp,
  security_findings_search: SecurityFindingsSearchResponse,
  auto_remediation_attempt_batch_details: AutoRemediationAttemptBatch,
  activity_log_events_list: ActivityLogEventListResponse,
  openscan_mobile_apps_search: OpenScanMobileAppSearchResponse,
  openscan_favorite_apps: OpenScanMobileAppsResponse,
  secret_strings_list: SecretStringsListResponse,
  vendor_management_vendors_list: VendorManagementVendorsListResponse,
  vendor_management_vendor_by_id: VendorManagementVendorByIdResponse,
  scan_runs_list: ScanRunsResponse,
  scan_run: ScanRun,
  scan_log: ScanLogResponse,
};


export type QueryCompliance_Standard_Criteria_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryNotification_Events_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  openapi_definition_id?: ?$ElementType<Scalars, 'ID'>,
  importance?: ?$ElementType<Scalars, 'String'>,
  start_date?: ?$ElementType<Scalars, 'Date'>,
  end_date?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryPolicy_Rules_By_Policy_Id_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policyId: $ElementType<Scalars, 'ID'>,
};


export type QueryPolicy_Rules_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicy_Rule_Types_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicies_Policy_Rule_Types_ListArgs = {
  policy_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_group?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_compliance_standard_criteria?: ?Array<$ElementType<Scalars, 'String'>>,
  include_headliner_hacks_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryPolicy_Violations_ListArgs = {
  violated_policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_relevance?: ?Array<?PolicyRuleTypeRelevance>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
};


export type QueryPolicy_Violations_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
  filter_by_relevance?: ?Array<?PolicyRuleTypeRelevance>,
  order_by?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicy_Violation_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryPolicy_Violation_By_IdArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryRestful_Api_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryRestful_Api_List_V1Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryRestful_Api_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryApi_Operation_DetailsArgs = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryApi_Operation_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryNetwork_Service_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryPolicies_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicy_By_IdArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryWeb_Applications_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryWeb_Applications_List_V2Args = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryWeb_Application_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryWeb_Application_CredentialsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryWeb_Application_UrlsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_Application_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryInventory_Stats_Reference_Date_GetArgs = {
  reference_date?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySpecial_Scan_Requests_ListArgs = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAsset_Group_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAsset_Group_DetailsArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryAsset_Group_Assets_Summary_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryShadow_Assets_All_Members_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryPolicy_Rule_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  most_relevant_rules_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryApi_Operation_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_is_authenticated?: ?$ElementType<Scalars, 'Boolean'>,
};


export type QueryCloud_Resource_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  filter_by_discovered_via?: ?$ElementType<Scalars, 'String'>,
  filter_by_domain_name_id?: ?$ElementType<Scalars, 'ID'>,
  filter_by_cloud_provider?: ?HostedOn,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_category?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type QueryCloud_Resource_Details_V2Args = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryCloud_Resource_Details_Policy_Violations_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryCloud_Resource_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryGraphql_Api_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  hosted_on_cloud_providers?: ?HostedOn,
  text?: ?$ElementType<Scalars, 'String'>,
};


export type QueryGraphql_Api_DetailsArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type QueryIp_Ranges_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryOpenapi_Definitions_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryToolkit_ScansArgs = {
  filter_by_type?: ?ToolkitScanTypeEnum,
  filter_by_status?: ?SpecialScanStatus,
};


export type QueryDomain_Name_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type QueryAsset_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?AssetOrderBy,
};


export type QueryUsers_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryUser_By_IdArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryPortal_Notifications_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  network_service_id?: ?$ElementType<Scalars, 'ID'>,
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  cloud_resource_id?: ?$ElementType<Scalars, 'ID'>,
  policy_violation_id?: ?$ElementType<Scalars, 'ID'>,
  openapi_definition_id?: ?$ElementType<Scalars, 'ID'>,
  importance?: ?$ElementType<Scalars, 'String'>,
  start_date?: ?$ElementType<Scalars, 'Date'>,
  end_date?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryMobile_Apps_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter?: ?MobileAppListFilter,
  platform?: ?MobileAppPlatform,
};


export type QueryMobile_App_Analysis_Schedules_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryMobile_App_Analysis_Requests_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryMobile_App_GetArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QuerySecurity_Findings_SearchArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?Array<$ElementType<Scalars, 'String'>>,
  current_status?: ?Array<TargetStatus>,
  priority?: ?Array<SecurityFindingSearchPriority>,
  importance_tags?: ?Array<SecurityFindingSearchImportanceTag>,
  severity?: ?Array<FindingSeverity>,
  compliance_policy?: ?Array<CompliancePolicy>,
  release_type?: ?Array<SecurityFindingSearchReleaseType>,
  age?: ?$ElementType<Scalars, 'Int'>,
  targets_created_after?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryAuto_Remediation_Attempt_Batch_DetailsArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type QueryActivity_Log_Events_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryOpenscan_Mobile_Apps_SearchArgs = {
  query: $ElementType<Scalars, 'String'>,
};


export type QueryOpenscan_Favorite_AppsArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QuerySecret_Strings_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryVendor_Management_Vendors_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type QueryVendor_Management_Vendor_By_IdArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type QueryScan_Runs_ListArgs = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_scan_type?: ?Array<?ScanRunsTypeEnum>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_date_updated?: ?$ElementType<Scalars, 'Date'>,
};


export type QueryScan_RunArgs = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type QueryScan_LogArgs = {
  url: $ElementType<Scalars, 'String'>,
  headers: Headers,
};

export type CredentialTriageParams = {
  triaging_decision?: ?CredentialTriagingDecisionEnum,
};

export type AutoRemediationAttemptBatches = {
  policy_violations_ids: Array<$ElementType<Scalars, 'ID'>>,
};

export type SpecialScanRequestParams = {
  scan_type: SpecialScanType,
};

export type PolicyRuleConfigurationParams = {
  http_methods_to_scan?: ?Array<HttpMethod>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
};

export type PolicyRulesUpdateParams = {
  has_all_alerts_muted?: ?$ElementType<Scalars, 'Boolean'>,
  relevance?: ?PolicyRuleTypeRelevance,
  configuration?: ?PolicyRuleConfigurationParams,
};

export type PolicyRulesCreateParams = {
  policy_rule_type_id: $ElementType<Scalars, 'String'>,
  relevance?: ?PolicyRuleTypeRelevance,
  configuration?: ?PolicyRuleConfigurationParams,
};

export type DetectAndInjectScanRunConfigurationParams = {
  http_methods_to_scan?: ?Array<HttpMethod>,
};

export type DetectAndInjectConfigurationParams = {
  should_scan_all_api_operations?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?DetectAndInjectScanRunConfigurationParams,
};

export type DetectAndInjectScansScheduleSetRequestParams = {
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationParams,
};

export type ToolkitScanStartRequestEmptyParams = {
  _?: ?$ElementType<Scalars, 'String'>,
};

export type WebApplicationXssScanRunConfigurationParams = {
  should_inject_in_get_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_post_parameters?: ?$ElementType<Scalars, 'Boolean'>,
  should_inject_in_url_path?: ?$ElementType<Scalars, 'Boolean'>,
  intensity?: ?WebApplicationXssScanIntensityEnum,
};

export type WebApplicationXssConfigurationParams = {
  web_applications_ids?: ?Array<$ElementType<Scalars, 'String'>>,
  should_scan_all_web_applications?: ?$ElementType<Scalars, 'Boolean'>,
  scan_run_configuration?: ?WebApplicationXssScanRunConfigurationParams,
};

export type WebApplicationXssScansScheduleSetRequestParams = {
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationParams,
};

export type WeekdaysParams = {
  data: Array<Weekday>,
};

export type MobileAppAnalysisScheduleParams = {
  time_when_to_scan: $ElementType<Scalars, 'String'>,
  weekdays_when_to_scan?: ?WeekdaysParams,
};

export type MobileAppAnalysisRequestParams = {
  feature: MobileAppAnalysisFeatureEnum,
};

export type SecretStringParams = {
  value: $ElementType<Scalars, 'String'>,
};

export type SevenhellSupportRequestsCreateParams = {
  body: $ElementType<Scalars, 'String'>,
  subject: $ElementType<Scalars, 'String'>,
};

export type VendorManagementCreateParams = {
  name: $ElementType<Scalars, 'String'>,
};

export type VendorManagementUpdateParams = {
  name?: ?$ElementType<Scalars, 'String'>,
  deletion_requested?: ?$ElementType<Scalars, 'Boolean'>,
};

export type IpRangeCreateParams = {
  ip_range: $ElementType<Scalars, 'String'>,
  has_continuous_discovery_enabled: $ElementType<Scalars, 'Boolean'>,
  ip_addresses_to_import?: ?Array<$ElementType<Scalars, 'String'>>,
};

export type IpRangeScanCreateParams = {
  ip_range: $ElementType<Scalars, 'String'>,
};

export type IpRangePatchParams = {
  has_continuous_discovery_enabled?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ComplianceReportParams = {
  mobile_app_id?: ?$ElementType<Scalars, 'ID'>,
};

export type CloudAuthenticatorUpdateParams = {
  customer_supplied_name: $ElementType<Scalars, 'String'>,
};

export type ToolkitScanStartRequestParams = {
  toolkit_scan_type: ToolkitScanTypeEnum,
  web_application_xss_configuration?: ?WebApplicationXssConfigurationParams,
  detect_and_inject_configuration?: ?DetectAndInjectConfigurationParams,
  cloud_leaks_configuration?: ?ToolkitScanStartRequestEmptyParams,
  code_red_configuration?: ?ToolkitScanStartRequestEmptyParams,
};

export type PolicyViolationsPatchParams = {
  exception_type?: ?PolicyViolationException,
  exception_explanation?: ?$ElementType<Scalars, 'String'>,
};

export type SharedLinksCreateForPolicyViolationParams = {
  validity_duration: $ElementType<Scalars, 'Int'>,
};

export type TargetStatusCreateParams = {
  status: TargetStatus,
};

export type ApigeeAuthenticatorsCreateParams = {
  organization: $ElementType<Scalars, 'String'>,
};

export type AwsAuthenticatorsCreateParams = {
  role_arn: $ElementType<Scalars, 'String'>,
  external_id: $ElementType<Scalars, 'String'>,
};

export type AxwayAuthenticatorsCreateParams = {
  client_id: $ElementType<Scalars, 'String'>,
  organization_id: $ElementType<Scalars, 'String'>,
  private_key: $ElementType<Scalars, 'String'>,
  added_by_external_user_email?: ?$ElementType<Scalars, 'String'>,
};

export type AzureAuthenticatorsCreateParams = {
  tenant_id: $ElementType<Scalars, 'String'>,
  client_id: $ElementType<Scalars, 'String'>,
  client_secret: $ElementType<Scalars, 'String'>,
};

export type GcpAuthenticatorsCreateParams = {
  organization_id: $ElementType<Scalars, 'String'>,
  service_account: $ElementType<Scalars, 'JSON'>,
};

export type KongAuthenticatorsCreateParams = {
  kong_admin_subdomain: $ElementType<Scalars, 'String'>,
  kong_admin_token: $ElementType<Scalars, 'String'>,
};

export type MulesoftAuthenticatorsCreateParams = {
  organization_id: $ElementType<Scalars, 'String'>,
  account_username: $ElementType<Scalars, 'String'>,
  account_password: $ElementType<Scalars, 'String'>,
};

export type NetworkServicesCreateParams = {
  domain_name: $ElementType<Scalars, 'String'>,
  port: $ElementType<Scalars, 'Int'>,
  application_layer_protocol: ApplicationLayerProtocol,
  is_tls_encrypted: $ElementType<Scalars, 'Boolean'>,
  requires_static_ip_addresses?: ?$ElementType<Scalars, 'Boolean'>,
};

export type HorizonSupportRequestsCreateParams = {
  subject: $ElementType<Scalars, 'String'>,
  body?: ?$ElementType<Scalars, 'String'>,
};

export type ComplianceReportScheduleConfigParams = {
  recipient_emails: Array<$ElementType<Scalars, 'String'>>,
  compliance_report_type: ToolkitScanTypeEnum,
  should_report_all_mobile_apps: $ElementType<Scalars, 'Boolean'>,
  customer_mobile_app_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type ComplianceReportScheduleParams = {
  should_only_scan_monthly?: ?$ElementType<Scalars, 'Boolean'>,
  weekdays_when_to_scan?: ?Array<Weekday>,
  time_when_to_scan?: ?$ElementType<Scalars, 'String'>,
  toolkit_scan_type?: ?ToolkitScanTypeEnum,
  compliance_reports_configuration?: ?ComplianceReportScheduleConfigParams,
};

export type BulkActionMoveToAssetGroupParams = {
  move_to_asset_group_id?: ?$ElementType<Scalars, 'ID'>,
  perform_on_assets_with_ids?: ?Array<$ElementType<Scalars, 'ID'>>,
};

export type AssetGroupCreateParams = {
  attached_policy_id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type AssetGroupUpdateParams = {
  attached_policy_id: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  description: $ElementType<Scalars, 'String'>,
};

export type Mutation = {
  __typename: 'Mutation',
  triage: CredentialTriageResponse,
  auto_remediation_attempt_batches: AutoRemediationAttemptBatch,
  cloud_authenticator_update: CloudAuthenticator,
  special_scan_request: SpecialScanRequestResponse,
  policy_rules_update: PolicyRulesUpdateResponse,
  policy_rules_create: PolicyRule,
  policy_rules_remove?: ?EmptyResponse,
  detect_and_inject_scans_schedules_set: DetectAndInjectScansScheduleSetResponse,
  web_application_xss_scans_schedules_set: WebApplicationXssScansSchedulesResponse,
  toolkit_scan_create: ToolkitScanStartRequestResponse,
  ip_ranges_create: IpRangeCreateResponse,
  ip_range_scans_create: IpRangeScanCreateResponse,
  ip_ranges_patch: IpRangePatchResponse,
  policy_violations_patch: PolicyViolationsPatchResponse,
  openapi_definitions_create: OpenApiDefinitionsCreateResponse,
  shared_links_for_policy_violation_create: SharedLinksCreateForPolicyViolationResponse,
  apigee_authenticators_create: ApigeeAuthenticatorsCreateResponse,
  aws_authenticators_create: AwsAuthenticatorsCreateResponse,
  axway_authenticators_create: AxwayAuthenticatorsCreateResponse,
  azure_authenticators_create: AzureAuthenticatorsCreateResponse,
  gcp_authenticators_create: GcpAuthenticatorsCreateResponse,
  kong_authenticators_create: KongAuthenticatorsCreateResponse,
  mulesoft_authenticators_create: MulesoftAuthenticatorsCreateResponse,
  network_services_create: NetworkService,
  policies_remove: EmptyResponse,
  horizon_support_requests_create?: ?EmptyResponse,
  compliance_reports_schedule_create: ComplianceReportScheduleResponse,
  bulk_action_move_to_asset_group_create: BulkActionResponse,
  asset_group_create: SingleAssetGroupResponse,
  asset_group_patch: SingleAssetGroupResponse,
  openscan_favorite_apps_add: OpenScanApp,
  mobile_app_analysis_request_create: AnalysisRequest,
  mobile_app_analysis_schedule_patch: MobileAppAnalysisScheduleResponse,
  secret_strings_create: SecretString,
  secret_strings_remove?: ?EmptyResponse,
  sevenhell_support_requests_create: EmptyResponse,
  security_findings_targets_statuses_create: TargetStatusWithFinding,
  vendor_management_vendor_create: Vendor,
  vendor_management_vendor_login: Session,
  vendor_management_vendor_update: Vendor,
  onboarding_completion_put: OnboardingCompletionResponse,
  onboarding_completion_delete: OnboardingCompletionResponse,
  data_trust_report: ComplianceReportResponse,
};


export type MutationTriageArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  credential_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?CredentialTriageParams,
};


export type MutationAuto_Remediation_Attempt_BatchesArgs = {
  body?: ?AutoRemediationAttemptBatches,
};


export type MutationCloud_Authenticator_UpdateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: CloudAuthenticatorUpdateParams,
};


export type MutationSpecial_Scan_RequestArgs = {
  body?: ?SpecialScanRequestParams,
};


export type MutationPolicy_Rules_UpdateArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesUpdateParams,
};


export type MutationPolicy_Rules_CreateArgs = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesCreateParams,
};


export type MutationPolicy_Rules_RemoveArgs = {
  policyId: $ElementType<Scalars, 'ID'>,
  ruleId: $ElementType<Scalars, 'ID'>,
};


export type MutationDetect_And_Inject_Scans_Schedules_SetArgs = {
  body?: ?DetectAndInjectScansScheduleSetRequestParams,
};


export type MutationWeb_Application_Xss_Scans_Schedules_SetArgs = {
  body?: ?WebApplicationXssScansScheduleSetRequestParams,
};


export type MutationToolkit_Scan_CreateArgs = {
  body: ToolkitScanStartRequestParams,
};


export type MutationIp_Ranges_CreateArgs = {
  body: IpRangeCreateParams,
};


export type MutationIp_Range_Scans_CreateArgs = {
  body: IpRangeScanCreateParams,
};


export type MutationIp_Ranges_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: IpRangePatchParams,
};


export type MutationPolicy_Violations_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationsPatchParams,
};


export type MutationOpenapi_Definitions_CreateArgs = {
  body: $ElementType<Scalars, 'String'>,
};


export type MutationShared_Links_For_Policy_Violation_CreateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body?: ?SharedLinksCreateForPolicyViolationParams,
};


export type MutationApigee_Authenticators_CreateArgs = {
  body: ApigeeAuthenticatorsCreateParams,
};


export type MutationAws_Authenticators_CreateArgs = {
  body: AwsAuthenticatorsCreateParams,
};


export type MutationAxway_Authenticators_CreateArgs = {
  body: AxwayAuthenticatorsCreateParams,
};


export type MutationAzure_Authenticators_CreateArgs = {
  body: AzureAuthenticatorsCreateParams,
};


export type MutationGcp_Authenticators_CreateArgs = {
  body: GcpAuthenticatorsCreateParams,
};


export type MutationKong_Authenticators_CreateArgs = {
  body: KongAuthenticatorsCreateParams,
};


export type MutationMulesoft_Authenticators_CreateArgs = {
  body: MulesoftAuthenticatorsCreateParams,
};


export type MutationNetwork_Services_CreateArgs = {
  body: NetworkServicesCreateParams,
};


export type MutationPolicies_RemoveArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationHorizon_Support_Requests_CreateArgs = {
  body: HorizonSupportRequestsCreateParams,
};


export type MutationCompliance_Reports_Schedule_CreateArgs = {
  body: ComplianceReportScheduleParams,
};


export type MutationBulk_Action_Move_To_Asset_Group_CreateArgs = {
  body: BulkActionMoveToAssetGroupParams,
};


export type MutationAsset_Group_CreateArgs = {
  body: AssetGroupCreateParams,
};


export type MutationAsset_Group_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: AssetGroupUpdateParams,
};


export type MutationOpenscan_Favorite_Apps_AddArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationMobile_App_Analysis_Request_CreateArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisRequestParams,
};


export type MutationMobile_App_Analysis_Schedule_PatchArgs = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisScheduleParams,
};


export type MutationSecret_Strings_CreateArgs = {
  body: SecretStringParams,
};


export type MutationSecret_Strings_RemoveArgs = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MutationSevenhell_Support_Requests_CreateArgs = {
  body: SevenhellSupportRequestsCreateParams,
};


export type MutationSecurity_Findings_Targets_Statuses_CreateArgs = {
  security_finding_id: $ElementType<Scalars, 'ID'>,
  target_id: $ElementType<Scalars, 'ID'>,
  body?: ?TargetStatusCreateParams,
};


export type MutationVendor_Management_Vendor_CreateArgs = {
  body: VendorManagementCreateParams,
};


export type MutationVendor_Management_Vendor_LoginArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type MutationVendor_Management_Vendor_UpdateArgs = {
  vendorId: $ElementType<Scalars, 'ID'>,
  body: VendorManagementUpdateParams,
};


export type MutationData_Trust_ReportArgs = {
  report_type: ComplianceReportType,
  body: ComplianceReportParams,
};

type $Pick<Origin: Object, Keys: Object> = $ObjMapi<Keys, <Key>(k: Key) => $ElementType<Origin, Key>>;

export type GetTheMostOfDtAuthenticatorCountQueryVariables = {};


export type GetTheMostOfDtAuthenticatorCountQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list: ({
      ...{ __typename: 'CloudAuthenticatorsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type GetTheMostOfDtIntegrationCountQueryVariables = {};


export type GetTheMostOfDtIntegrationCountQuery = ({
    ...{ __typename: 'Query' },
  ...{ alerts_integrations_list: ({
      ...{ __typename: 'AlertsIntegrationsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type AssetFragment = ({
    ...{ __typename: 'AssetSummaryField' },
  ...$Pick<AssetSummaryField, { id: *, name: *, url?: *, status: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: * }>,
  ...{ belongs_to_asset_group?: ?({
      ...{ __typename: 'AssetGroupField' },
    ...$Pick<AssetGroupField, { id: *, name: * }>
  }) }
});

export type PolicyRuleTypeV2PolicyRuleUpdateFragment = ({
    ...{ __typename: 'PolicyRuleTypeV2PolicyRule' },
  ...$Pick<PolicyRuleTypeV2PolicyRule, { relevance: * }>
});

export type SecurityToolKitMagecartQueryQueryVariables = {
  policyRuleTypeIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type SecurityToolKitMagecartQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type SecurityToolKitXssProtectionQueryQueryVariables = {
  policyRuleTypeIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
};


export type SecurityToolKitXssProtectionQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, additional_info?: * }>,
      ...{ affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>,
        ...{ most_recent_analysis_result: ({
            ...{ __typename: 'WebAnalysisResult' },
          ...$Pick<WebAnalysisResult, { title: * }>
        }) }
      }) }
    })> }
  }) }
});

export type ShadowAssetsOverviewMembershipQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ShadowAssetsOverviewMembershipQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ shadow_assets_all_members_list: ({
      ...{ __typename: 'ShadowAssetsMembersResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), asset_group_memberships: Array<({
        ...{ __typename: 'AssetGroupMembership' },
      ...$Pick<AssetGroupMembership, { id: * }>,
      ...{ cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: * }>
      }), network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, url: * }>
      }), restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { id: *, base_url: * }>
      }), web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })> }
  }) }
});

export type ShadowAssetsOverviewInventoryStatsQueryQueryVariables = {
  reference_date?: ?$ElementType<Scalars, 'String'>,
};


export type ShadowAssetsOverviewInventoryStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_reference_date_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { shadow_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type GetWebApplicationsStatsQueryQueryVariables = {};


export type GetWebApplicationsStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_stats_get: ({
      ...{ __typename: 'WebApplicationsStatsResponse' },
    ...{ web_applications_stats: Array<({
        ...{ __typename: 'WebAppAssetsInventoryStats' },
      ...$Pick<WebAppAssetsInventoryStats, { total_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
    })> }
  }) }
});

export type GetScanLogQueryQueryVariables = {
  url: $ElementType<Scalars, 'String'>,
  headers: Headers,
};


export type GetScanLogQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ scan_log: ({
      ...{ __typename: 'ScanLogResponse' },
    ...$Pick<ScanLogResponse, { text: * }>
  }) }
});

export type SpaXssProtectionPolicyRulesTypeListResponseQueryQueryVariables = {};


export type SpaXssProtectionPolicyRulesTypeListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rule_types_list: ({
      ...{ __typename: 'PolicyRuleTypeListResponse' },
    ...{ policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: * }>
    })> }
  }) }
});

export type SpaXssProtectionSpecialScansQueryQueryVariables = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type SpaXssProtectionSpecialScansQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ special_scan_requests_list: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, created_by_user_id?: *, current_status: *, scan_type: * }>
    })> }
  }) }
});

export type SpaXssProtectionPolicyViolationsQueryQueryVariables = {
  ruleTypeIds?: ?Array<?$ElementType<Scalars, 'ID'>>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SpaXssProtectionPolicyViolationsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, violated_policy_rule_id: *, status: *, date_created: *, date_resolved?: *, affected_web_application_id?: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, policy_rule_type_id: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: * }>
    })> }
  }) }
});

export type SpaXssProtectionInventoryStatsQueryQueryVariables = {};


export type SpaXssProtectionInventoryStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...$Pick<InventoryStats, { reference_date: * }>,
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type SpaXssProtectionWebApplicationsQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SpaXssProtectionWebApplicationsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_list: ({
      ...{ __typename: 'WebApplicationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, network_service_id: *, base_url: *, base_path: *, date_created: *, policy_violation_ids: * }>,
      ...{ most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: * }>
      }) }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, hosted_on?: * }>
    })> }
  }) }
});

export type TriageCredentialAhuraMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  credential_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?CredentialTriageParams,
};


export type TriageCredentialAhuraMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ triage: ({
      ...{ __typename: 'CredentialTriageResponse' },
    ...{ detected_spa_credential: ({
        ...{ __typename: 'DetectedSpaCredential' },
      ...$Pick<DetectedSpaCredential, { id: *, web_application_id: *, found_at_url: *, credential_as_markdown: *, exported_to_policy_violation_id?: *, triaging_decision: * }>
    }) }
  }) }
});

export type WebApplicationDetailsQueryAhuraQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type WebApplicationDetailsQueryAhuraQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_details: ({
      ...{ __typename: 'WebApplicationDetailsResponse' },
    ...{ network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: * }>
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: * }>
    })>, graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { id: * }>
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, base_path: *, network_service_id: *, date_created: *, discovered_via: * }>,
      ...{ network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: * }>
      }), most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: *, screenshot_url: *, fullscreen_screenshot_url: *, web_application_type: *, date_created: *, date_last_received: *, detected_restful_api_ids?: *, exercised_api_operation_ids?: * }>,
        ...{ detected_libraries?: ?Array<({
            ...{ __typename: 'WebLibrary' },
          ...$Pick<WebLibrary, { name: *, project_url: *, icon_url: * }>
        })>, detected_network_activity?: ?Array<({
            ...{ __typename: 'WebActivity' },
          ...$Pick<WebActivity, { base_url: *, request_types: * }>
        })> }
      }) }
    })> }
  }) }
});

export type WebApplicationCredentialsQueryAhuraQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type WebApplicationCredentialsQueryAhuraQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_credentials: ({
      ...{ __typename: 'WebApplicationCredentialsResponse' },
    ...{ detected_spa_credentials: Array<({
        ...{ __typename: 'DetectedSpaCredential' },
      ...$Pick<DetectedSpaCredential, { id: *, value: *, web_application_id: *, found_at_url: *, credential_as_markdown: *, exported_to_policy_violation_id?: *, triaging_decision: * }>,
      ...{ used_in_request: ({
          ...{ __typename: 'DetectedSpaCredentialUsedIn' },
        ...$Pick<DetectedSpaCredentialUsedIn, { header_name: *, header_value: *, method: *, url: * }>
      }) }
    })> }
  }) }
});

export type ActivityLogEventsQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ActivityLogEventsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ activity_log_events_list: ({
      ...{ __typename: 'ActivityLogEventListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), activity_log_events: Array<({
        ...{ __typename: 'ActivityLogEvent' },
      ...$Pick<ActivityLogEvent, { id: *, description: *, date_created: * }>
    })> }
  }) }
});

export type PolicyViolationsWithRemeditationQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsWithRemeditationQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { violated_policy_rule_id: * }>,
      ...PolicyViolationItemFragmentFragment
    })> }
  }) }
});

export type CorrectProtectAuthenticatorCountQueryVariables = {};


export type CorrectProtectAuthenticatorCountQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list: ({
      ...{ __typename: 'CloudAuthenticatorsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type OpenViolationsQueryVariables = {};


export type OpenViolationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type ResolvedViolationsQueryVariables = {};


export type ResolvedViolationsQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }) }
  }) }
});

export type CloudAuthenticatorsQueryVariables = {};


export type CloudAuthenticatorsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list_DEPRECATED: ({
      ...{ __typename: 'CloudAuthenticatorsResponse_DEPRECATED' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), cloud_authenticators: Array<({
        ...{ __typename: 'CloudAuthenticator_DEPRECATED' },
      ...$Pick<CloudAuthenticator_Deprecated, { status: * }>,
      ...{ apigee_credential?: ?({
          ...{ __typename: 'ApigeeCredential' },
        ...$Pick<ApigeeCredential, { id: *, organization: *, added_by_user_id: *, date_created: *, added_by_external_user_email?: * }>
      }), apigee_on_prem_credential?: ?({
          ...{ __typename: 'ApigeeOnPremCredential' },
        ...$Pick<ApigeeOnPremCredential, { id: *, date_created: *, added_by_user_id?: *, organization: *, management_api_url?: *, management_api_username?: * }>
      }), aws_credential?: ?({
          ...{ __typename: 'AwsCredential' },
        ...$Pick<AwsCredential, { id: *, role_arn: *, external_id: *, added_by_user_id: *, date_created: *, added_by_external_user_email?: * }>
      }), azure_credential?: ?({
          ...{ __typename: 'AzureCredential' },
        ...$Pick<AzureCredential, { id: *, tenant_id: *, client_id: *, added_by_user_id?: *, date_created: * }>
      }), axway_credential?: ?({
          ...{ __typename: 'AxwayCredential' },
        ...$Pick<AxwayCredential, { id: *, added_by_user_id?: *, date_created: *, organization_id?: *, client_id?: * }>
      }), mulesoft_credential?: ?({
          ...{ __typename: 'MulesoftCredential' },
        ...$Pick<MulesoftCredential, { id: *, added_by_user_id?: *, date_created: *, organization_id?: *, account_username?: * }>
      }), kong_credential?: ?({
          ...{ __typename: 'KongCredential' },
        ...$Pick<KongCredential, { id: *, added_by_user_id?: *, date_created: *, kong_admin_subdomain?: * }>
      }), gcp_credential?: ?({
          ...{ __typename: 'GcpCredential' },
        ...$Pick<GcpCredential, { id: *, added_by_user_id?: *, date_created: *, client_email?: *, organization_id?: *, private_key_id?: * }>
      }) }
    })> }
  }) }
});

export type DetectInjectApiOperationDetailsQueryQueryVariables = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type DetectInjectApiOperationDetailsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details: ({
      ...{ __typename: 'APIOperationDetailsResponse' },
    ...{ api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, http_method: *, policy_violation_ids: * }>
    })>, api_operation_scan_traces: Array<({
        ...{ __typename: 'APIOperationScanTrace' },
      ...$Pick<ApiOperationScanTrace, { id: *, url: *, http_request: *, http_response: * }>
    })> }
  }) }
});

export type LeakyApisPolicyRuleUpdateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesUpdateParams,
};


export type LeakyApisPolicyRuleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_update: ({
      ...{ __typename: 'PolicyRulesUpdateResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { weekdays_when_to_scan?: *, time_when_to_scan?: * }>
      }) }
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, default_relevance?: * }>
    })> }
  }) }
});

export type LeakyApisPolicyRuleCreateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesCreateParams,
};


export type LeakyApisPolicyRuleCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_create: ({
      ...{ __typename: 'PolicyRule' },
    ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: * }>,
    ...{ configuration?: ?({
        ...{ __typename: 'PolicyRuleConfiguration' },
      ...$Pick<PolicyRuleConfiguration, { weekdays_when_to_scan?: *, time_when_to_scan?: * }>
    }) }
  }) }
});

export type LeakyApisSpecialScansQueryQueryVariables = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type LeakyApisSpecialScansQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ special_scan_requests_list: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, created_by_user_id?: *, current_status: *, scan_type: * }>
    })> }
  }) }
});

export type StartSpecialScanMutationVariables = {
  body: SpecialScanRequestParams,
};


export type StartSpecialScanMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ special_scan_request: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, created_by_user_id?: *, current_status: *, scan_type: * }>
    })> }
  }) }
});

export type LeakyApisPoliciesListResponseQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type LeakyApisPoliciesListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policies_list: ({
      ...{ __typename: 'PoliciesListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, is_special_dt_policy: * }>
    })> }
  }) }
});

export type LeakyApisPolicyRulesListResponseQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
};


export type LeakyApisPolicyRulesListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_list: ({
      ...{ __typename: 'PolicyRuleListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { weekdays_when_to_scan?: *, time_when_to_scan?: * }>
      }) }
    })> }
  }) }
});

export type LeakyApisPolicyRulesTypeListResponseQueryQueryVariables = {};


export type LeakyApisPolicyRulesTypeListResponseQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rule_types_list: ({
      ...{ __typename: 'PolicyRuleTypeListResponse' },
    ...{ policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, default_relevance?: * }>
    })> }
  }) }
});

export type NetworkServiceDetailsQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type NetworkServiceDetailsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_service_details: ({
      ...{ __typename: 'NetworkServiceDetailsResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, network_service_id: *, openapi_definition_id: *, title: *, base_url: *, base_path: *, discovered_via: *, discovered_via_user_id?: *, date_created: *, asset_type_name: *, asset_type_icon_url: * }>,
      ...{ asset_group_memberships: Array<({
          ...{ __typename: 'AssetGroupMembership' },
        ...$Pick<AssetGroupMembership, { id: *, date_created: *, membership_kind: *, asset_group_id: * }>
      })>, api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, path: *, http_method: *, is_authenticated?: * }>
      })> }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, discovered_via: *, network_service_id: *, base_url: *, base_path: *, date_created: *, asset_type_name: *, asset_type_icon_url: *, policy_violation_ids: * }>,
      ...{ most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: * }>
      }) }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, date_created: *, asset_type_name: *, asset_type_icon_url: *, discovered_via: *, discovered_via_authenticator_id?: * }>,
      ...{ domain_name: ({
          ...{ __typename: 'DomainName' },
        ...$Pick<DomainName, { id: *, name: *, date_created: *, discovered_via: * }>
      }), connectivity_scan_results: Array<({
          ...{ __typename: 'NetworkServiceConnectivityScanResult' },
        ...$Pick<NetworkServiceConnectivityScanResult, { status: *, date_created: * }>
      })> }
    })>, events: Array<({
        ...{ __typename: 'Event' },
      ...$Pick<Event, { id: *, date_created: *, event_importance: *, event_type: * }>,
      ...{ user?: ?({
          ...{ __typename: 'User' },
        ...$Pick<User, { id: *, login_email: * }>
      }), cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, discovered_via: *, asset_type_name: * }>
      }), policy_violation?: ?({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, exception_explanation?: *, date_resolved?: *, exception_type?: *, status: * }>,
        ...{ exception_added_by_user?: ?({
            ...{ __typename: 'User' },
          ...$Pick<User, { login_email: * }>
        }), violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { title: * }>
          }) }
        }) }
      }), network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, discovered_via: *, url: * }>
      }), restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { id: *, discovered_via: *, base_url: * }>
      }), api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>
      }), comment?: ?({
          ...{ __typename: 'Comment' },
        ...$Pick<Comment, { id: *, date_created: *, content?: *, related_policy_violation_id: *, added_by_user_id: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_created: *, date_resolved?: *, exception_date_created?: *, exception_type?: *, status: * }>,
      ...{ exception_added_by_user?: ?({
          ...{ __typename: 'User' },
        ...$Pick<User, { login_email: * }>
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { description: *, link: *, compliance_standard: *, compliance_standard_criteria: * }>
          })> }
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { name: * }>
        }) }
      }), affected_restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { base_url: * }>
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: *, restful_api_id: * }>
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { url?: *, asset_type_name: * }>
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { base_url: * }>
      }) }
    })>, certificate_chains: Array<({
        ...{ __typename: 'CertificateChain' },
      ...$Pick<CertificateChain, { id: * }>,
      ...{ security_properties: Array<({
          ...{ __typename: 'CertificateChainSecurityProperty' },
        ...$Pick<CertificateChainSecurityProperty, { enum: *, value: *, relevance?: * }>
      })>, certificates: Array<({
          ...{ __typename: 'Certificate' },
        ...$Pick<Certificate, { id: *, issuer_as_rfc4514_string: *, date_created: *, domain_names_in_cn: *, public_key_algorithm: *, signature_hash_algorithm: *, public_key_size: *, not_valid_after: * }>
      })> }
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, discovered_via: *, url?: *, cloud_console_url?: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, tls_scan_results: Array<({
        ...{ __typename: 'TlsScanResult' },
      ...$Pick<TlsScanResult, { network_service_id: * }>,
      ...{ tls_scan_attributes: Array<({
          ...{ __typename: 'TlsScanAttribute' },
        ...$Pick<TlsScanAttribute, { enum: *, value: * }>,
        ...{ opened_policy_violation?: ?({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { violated_policy_rule_id: *, date_resolved?: * }>
        }), resolved_policy_violation?: ?({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { violated_policy_rule_id: *, date_resolved?: * }>
        }) }
      })> }
    })> }
  }) }
});

export type FavoriteAppsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type FavoriteAppsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ openscan_favorite_apps: ({
      ...{ __typename: 'OpenScanMobileAppsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), openscan_mobile_apps?: ?Array<({
        ...{ __typename: 'OpenScanApp' },
      ...$Pick<OpenScanApp, { id: *, icon_url?: *, name: *, platform: *, store_url?: *, publisher_name?: *, publisher_website?: * }>
    })> }
  }) }
});

export type SearchOpenscanAppsQueryVariables = {
  query: $ElementType<Scalars, 'String'>,
};


export type SearchOpenscanAppsQuery = ({
    ...{ __typename: 'Query' },
  ...{ openscan_mobile_apps_search: ({
      ...{ __typename: 'OpenScanMobileAppSearchResponse' },
    ...{ openscan_mobile_app_search_results: Array<({
        ...{ __typename: 'OpenScanSearchResponse' },
      ...$Pick<OpenScanSearchResponse, { id: *, icon_url?: *, name: *, platform: *, store_url?: * }>
    })> }
  }) }
});

export type AddToFavoritesMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AddToFavoritesMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ openscan_favorite_apps_add: ({
      ...{ __typename: 'OpenScanApp' },
    ...$Pick<OpenScanApp, { id: *, favorite: * }>
  }) }
});

export type TopPolicyViolationForRemeditationForRuleIdQueryVariables = {
  policy_rule_id: $ElementType<Scalars, 'ID'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
};


export type TopPolicyViolationForRemeditationForRuleIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: * }>
      }) }
    })> }
  }) }
});

export type PolicyRulesForContainerQueryVariables = {};


export type PolicyRulesForContainerQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rule_list: ({
      ...{ __typename: 'PolicyRuleListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: *, belongs_to_policy_id: *, belongs_to_policy_name: *, open_policy_violations_count: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: * }>
      }) }
    })> }
  }) }
});

export type InventoryStatsForPolicyViolationsDashboardQueryVariables = {};


export type InventoryStatsForPolicyViolationsDashboardQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...$Pick<InventoryStats, { unresolved_policy_violations_total_count: *, resolved_policy_violations_total_count: * }>
    })> }
  }) }
});

export type PolicyViolationsApiOperationDetailsQueryQueryVariables = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationsApiOperationDetailsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details: ({
      ...{ __typename: 'APIOperationDetailsResponse' },
    ...{ api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, http_method: *, policy_violation_ids: * }>
    })>, api_operation_scan_traces: Array<({
        ...{ __typename: 'APIOperationScanTrace' },
      ...$Pick<ApiOperationScanTrace, { id: *, url: *, http_request: *, http_response: * }>
    })> }
  }) }
});

export type PolicyViolationsForRuleIdQueryVariables = {
  violated_policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationsForRuleIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { violated_policy_rule_id: * }>,
      ...PolicyViolationItemFragmentFragment
    })> }
  }) }
});

export type PolicyRulesWithRemediationQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
};


export type PolicyRulesWithRemediationQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rule_list: ({
      ...{ __typename: 'PolicyRuleListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: *, belongs_to_policy_id: *, belongs_to_policy_name: *, open_policy_violations_count: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: * }>
      }) }
    })> }
  }) }
});

export type PolicyViolationsWithRemeditationForRuleIdQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_id: $ElementType<Scalars, 'ID'>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
};


export type PolicyViolationsWithRemeditationForRuleIdQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { violated_policy_rule_id: * }>,
      ...PolicyViolationItemFragmentFragment
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: *, belongs_to_policy_id: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: * }>
      }) }
    })> }
  }) }
});

export type PolicyViolationItemFragmentFragment = ({
    ...{ __typename: 'PolicyViolation' },
  ...$Pick<PolicyViolation, { id: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, status: * }>,
  ...{ violated_policy_rule: ({
      ...{ __typename: 'PolicyRule' },
    ...$Pick<PolicyRule, { id: *, relevance: * }>,
    ...{ policy_rule_type: ({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: * }>,
      ...{ compliance_policy_references: Array<({
          ...{ __typename: 'PolicyComplianceReference' },
        ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
      })> }
    }) }
  }), affected_api_operation?: ?({
      ...{ __typename: 'APIOperation' },
    ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
    ...{ restful_api: ({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { base_url: * }>
    }) }
  }), affected_cloud_resource?: ?({
      ...{ __typename: 'CloudResource' },
    ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
  }), affected_network_service?: ?({
      ...{ __typename: 'NetworkService' },
    ...$Pick<NetworkService, { id: * }>,
    ...{ domain_name: ({
        ...{ __typename: 'DomainName' },
      ...$Pick<DomainName, { name: * }>
    }) }
  }), affected_web_application?: ?({
      ...{ __typename: 'WebApplication' },
    ...$Pick<WebApplication, { id: *, base_url: * }>
  }) }
});

export type RemediationBatchMutationVariables = {
  body: AutoRemediationAttemptBatches,
};


export type RemediationBatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ auto_remediation_attempt_batches: ({
      ...{ __typename: 'AutoRemediationAttemptBatch' },
    ...$Pick<AutoRemediationAttemptBatch, { id: *, url: * }>,
    ...{ auto_remediation_attempts: Array<({
        ...{ __typename: 'AutoRemediationAttempt' },
      ...$Pick<AutoRemediationAttempt, { status: * }>
    })> }
  }) }
});

export type RemediationBatchCheckStatusQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type RemediationBatchCheckStatusQuery = ({
    ...{ __typename: 'Query' },
  ...{ auto_remediation_attempt_batch_details: ({
      ...{ __typename: 'AutoRemediationAttemptBatch' },
    ...{ auto_remediation_attempts: Array<({
        ...{ __typename: 'AutoRemediationAttempt' },
      ...$Pick<AutoRemediationAttempt, { status: * }>
    })> }
  }) }
});

export type PolicyViolationsForPolicyRuleIdsQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsForPolicyRuleIdsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, violated_policy_rule_id: *, status: *, date_created: *, date_resolved?: *, affected_web_application_id?: * }>
    })> }
  }) }
});

export type GetRestfulApiIntegratedCloudResourcesQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type GetRestfulApiIntegratedCloudResourcesQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_details: ({
      ...{ __typename: 'RestfulAPIDetailsResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...{ integrated_cloud_resources?: ?Array<({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { name: *, id: *, asset_type_name: *, runtime?: *, region?: *, asset_type_icon_url: * }>
      })> }
    })> }
  }) }
});

export type ShadowAssetsQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ShadowAssetsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ shadow_assets_all_members_list: ({
      ...{ __typename: 'ShadowAssetsMembersResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: *, next_cursor?: * }>
    }), asset_group_memberships: Array<({
        ...{ __typename: 'AssetGroupMembership' },
      ...$Pick<AssetGroupMembership, { id: *, cloud_resource_id?: *, restful_api_id?: *, network_service_id?: *, web_application_id?: * }>
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, cloud_console_url?: *, asset_type_name: *, asset_type_icon_url: *, name: *, discovered_via_apigee_authenticator_id?: *, discovered_via_aws_authenticator_id?: *, discovered_via_azure_authenticator_id?: *, discovered_via_gcp_authenticator_id?: *, discovered_via_mulesoft_authenticator_id?: *, discovered_via: *, date_created: * }>,
      ...{ asset_group_memberships: Array<({
          ...{ __typename: 'AssetGroupMembership' },
        ...$Pick<AssetGroupMembership, { asset_group_id: * }>
      })> }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, discovered_via: *, date_created: *, asset_type_name: *, asset_type_icon_url: * }>,
      ...{ domain_name: ({
          ...{ __typename: 'DomainName' },
        ...$Pick<DomainName, { name: * }>
      }), asset_group_memberships: Array<({
          ...{ __typename: 'AssetGroupMembership' },
        ...$Pick<AssetGroupMembership, { asset_group_id: * }>
      })> }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, base_path: *, asset_type_name: *, asset_type_icon_url: *, discovered_via: *, date_created: * }>,
      ...{ network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { hosted_on?: * }>
      }), asset_group_memberships: Array<({
          ...{ __typename: 'AssetGroupMembership' },
        ...$Pick<AssetGroupMembership, { asset_group_id: * }>
      })> }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, base_path: *, asset_type_name: *, asset_type_icon_url: *, discovered_via: *, date_created: * }>,
      ...{ most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: * }>
      }), network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { hosted_on?: * }>
      }), asset_group_memberships: Array<({
          ...{ __typename: 'AssetGroupMembership' },
        ...$Pick<AssetGroupMembership, { asset_group_id: * }>
      })> }
    })> }
  }) }
});

export type ShadowAssetStatsQueryQueryVariables = {};


export type ShadowAssetStatsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { shadow_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type ShadowAssetsConfigurationQueryQueryVariables = {};


export type ShadowAssetsConfigurationQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ shadow_assets_configuration_get: ({
      ...{ __typename: 'ShadowAssetsConfigurationResponse' },
    ...{ configuration: ({
        ...{ __typename: 'ShadowAssetsConfiguration' },
      ...$Pick<ShadowAssetsConfiguration, { days_count_when_move_assets_out_of_group?: *, should_add_assets_hosted_in_cloud_providers?: *, should_add_assets_hosted_in_unknown_cloud_environment: *, should_add_assets_not_used_by_mobile_or_web_applications: *, should_add_assets_recently_discovered_via_source?: *, should_add_cloud_resource_of_types?: * }>
    }) }
  }) }
});

export type UpdateUserFragment = ({
    ...{ __typename: 'User' },
  ...$Pick<User, { allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, first_name: *, last_name: *, login_email: *, notification_email?: *, role: * }>
});

export type UsersListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type UsersListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ users_list: ({
      ...{ __typename: 'UsersListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), users: Array<({
        ...{ __typename: 'User' },
      ...$Pick<User, { id: *, first_name: *, last_name: *, login_email: *, last_login_date?: *, notification_email?: *, can_access_app_secure: *, can_access_app_search: *, can_access_brand_protect: *, can_access_api_inspect: *, role: *, auth_strategy: *, allowed_app_ids?: *, can_invite_users: * }>
    })> }
  }) }
});

export type ScanRunGetQueryQueryVariables = {
  uuid: $ElementType<Scalars, 'ID'>,
};


export type ScanRunGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ scan_run: ({
      ...{ __typename: 'ScanRun' },
    ...$Pick<ScanRun, { uuid: *, scan_type: *, status: *, date_created?: *, date_started?: *, date_finished?: *, logs_file_url?: *, result_issues_found_count?: *, result_markdown?: *, scanned_asset_display_name?: *, triggered_by_actor_display_name?: * }>
  }) }
});

export type ScanRunsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_scan_type?: ?Array<?ScanRunsTypeEnum>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_date_updated?: ?$ElementType<Scalars, 'Date'>,
};


export type ScanRunsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ scan_runs_list: ({
      ...{ __typename: 'ScanRunsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), scan_runs: Array<({
        ...{ __typename: 'ScanRun' },
      ...$Pick<ScanRun, { uuid: *, scan_type: *, status: *, date_created?: *, date_started?: *, date_finished?: *, logs_file_url?: *, result_issues_found_count?: *, result_markdown?: *, scanned_asset_display_name?: *, triggered_by_actor_display_name?: * }>
    })> }
  }) }
});

export type ApiOperationsGetQueryQueryVariables = {
  restful_api_id?: ?$ElementType<Scalars, 'ID'>,
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ApiOperationsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details: ({
      ...{ __typename: 'APIOperationDetailsResponse' },
    ...{ api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, http_method: *, policy_violation_ids: * }>
    })>, api_operation_scan_traces: Array<({
        ...{ __typename: 'APIOperationScanTrace' },
      ...$Pick<ApiOperationScanTrace, { id: *, url: *, http_request: *, http_response: * }>
    })> }
  }) }
});

export type ApiOperationsGetVersion2QueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ApiOperationsGetVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_details_v2: ({
      ...{ __typename: 'APIOperationDetailsResponseVersion2' },
    ...$Pick<ApiOperationDetailsResponseVersion2, { id: * }>,
    ...{ restful_api: ({
        ...{ __typename: 'APIOperationV2RestfulApi' },
      ...$Pick<ApiOperationV2RestfulApi, { id: * }>
    }) }
  }) }
});

export type ApiOperationsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type ApiOperationsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ api_operation_list: ({
      ...{ __typename: 'ApiOperationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), api_operations: Array<({
        ...{ __typename: 'APIOperation' },
      ...$Pick<ApiOperation, { id: *, path: *, http_method: *, is_authenticated?: *, date_created: *, restful_api_id: *, restful_api_title?: *, hosted_on?: * }>
    })> }
  }) }
});

export type ApigeeAuthenticatorsCreateMutationMutationVariables = {
  body: ApigeeAuthenticatorsCreateParams,
};


export type ApigeeAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ apigee_authenticators_create: ({
      ...{ __typename: 'ApigeeAuthenticatorsCreateResponse' },
    ...$Pick<ApigeeAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, organization: * }>
  }) }
});

export type AssetGroupsAssetsSummaryListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
};


export type AssetGroupsAssetsSummaryListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_assets_summary_list: ({
      ...{ __typename: 'AssetGroupAssetsSummaryListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), assets: Array<({
        ...{ __typename: 'AssetSummary' },
      ...$Pick<AssetSummary, { id: *, name: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, open_urgent_policy_violations_count: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, discovered_via: *, hosted_on: *, date_created: * }>
    })> }
  }) }
});

export type AssetGroupsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AssetGroupsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_list: ({
      ...{ __typename: 'AssetGroupListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type AssetGroupDetailsQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AssetGroupDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_group_details: ({
      ...{ __typename: 'SingleAssetGroupResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })>, policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, created_by_actor_id: *, created_by_actor_name: *, is_special_dt_policy: *, date_created: *, protected_asset_groups_count: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }) }
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: *, requires_configuration?: *, group?: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, belongs_to_policy_name: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, relevance: *, open_policy_violations_count: * }>
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type AssetGroupCreateMutationVariables = {
  body: AssetGroupCreateParams,
};


export type AssetGroupCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_create: ({
      ...{ __typename: 'SingleAssetGroupResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })> }
  }) }
});

export type AssetGroupPatchMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: AssetGroupUpdateParams,
};


export type AssetGroupPatchMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ asset_group_patch: ({
      ...{ __typename: 'SingleAssetGroupResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }), asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, api_operations_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: * }>
      }) }
    })> }
  }) }
});

export type AssetListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_cloud_provider?: ?Array<?HostedOn>,
  filter_by_discovered_via?: ?Array<?DiscoveredVia>,
  filter_by_asset_group_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?AssetOrderBy,
};


export type AssetListQuery = ({
    ...{ __typename: 'Query' },
  ...{ asset_list: ({
      ...{ __typename: 'AssetListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), assets: Array<({
        ...{ __typename: 'AssetSummaryField' },
      ...$Pick<AssetSummaryField, { id: *, name: *, url?: *, status: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, open_important_policy_violations_count: *, open_proactive_policy_violations_count: *, open_urgent_policy_violations_count: * }>,
      ...{ belongs_to_asset_group?: ?({
          ...{ __typename: 'AssetGroupField' },
        ...$Pick<AssetGroupField, { id: *, name: * }>
      }) }
    })> }
  }) }
});

export type AwsAuthenticatorsCreateMutationMutationVariables = {
  body: AwsAuthenticatorsCreateParams,
};


export type AwsAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ aws_authenticators_create: ({
      ...{ __typename: 'AwsAuthenticatorsCreateResponse' },
    ...$Pick<AwsAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, role_arn: *, external_id: * }>
  }) }
});

export type AxwayAuthenticatorsCreateMutationMutationVariables = {
  body: AxwayAuthenticatorsCreateParams,
};


export type AxwayAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ axway_authenticators_create: ({
      ...{ __typename: 'AxwayAuthenticatorsCreateResponse' },
    ...$Pick<AxwayAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, client_id: *, organization_id: *, added_by_external_user_email?: * }>
  }) }
});

export type AzureAuthenticatorsCreateMutationMutationVariables = {
  body: AzureAuthenticatorsCreateParams,
};


export type AzureAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ azure_authenticators_create: ({
      ...{ __typename: 'AzureAuthenticatorsCreateResponse' },
    ...$Pick<AzureAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, tenant_id: *, client_id: * }>
  }) }
});

export type BulkActionMoveToAssetGroupCreateMutationVariables = {
  body: BulkActionMoveToAssetGroupParams,
};


export type BulkActionMoveToAssetGroupCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ bulk_action_move_to_asset_group_create: ({
      ...{ __typename: 'BulkActionResponse' },
    ...$Pick<BulkActionResponse, { action_type?: *, status?: * }>
  }) }
});

export type CloudAuthenticatorsListQueryVariables = {};


export type CloudAuthenticatorsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_authenticators_list: ({
      ...{ __typename: 'CloudAuthenticatorsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), cloud_authenticators: Array<({
        ...{ __typename: 'CloudAuthenticator' },
      ...$Pick<CloudAuthenticator, { authenticator_category: *, authenticator_type: *, cloud_account_id: *, customer_supplied_name?: *, date_created: *, id: *, status: * }>
    })> }
  }) }
});

export type UpdateCloudAuthenticatorMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: CloudAuthenticatorUpdateParams,
};


export type UpdateCloudAuthenticatorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ cloud_authenticator_update: ({
      ...{ __typename: 'CloudAuthenticator' },
    ...$Pick<CloudAuthenticator, { authenticator_category: *, authenticator_type: *, cloud_account_id: *, customer_supplied_name?: *, date_created: *, id: *, status: * }>
  }) }
});

export type CloudResourceDetailsQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type CloudResourceDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_details: ({
      ...{ __typename: 'CloudResourceDetailsResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: * }>
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url?: *, url?: * }>,
      ...{ policy_violations: Array<({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, status: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: *, title: * }>
          }) }
        }) }
      })> }
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, status: *, additional_info?: *, date_created: *, date_resolved?: *, exception_type?: *, exception_date_created?: *, affected_cloud_resource_id?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, asset_type_name: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }) }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: * }>
    })>, events: Array<({
        ...{ __typename: 'Event' },
      ...$Pick<Event, { id: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: * }>
    })> }
  }) }
});

export type CloudResourcesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  filter_by_discovered_via?: ?$ElementType<Scalars, 'String'>,
  filter_by_domain_name_id?: ?$ElementType<Scalars, 'ID'>,
  hostedOn?: ?HostedOn,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
  filter_by_category?: ?Array<?$ElementType<Scalars, 'String'>>,
};


export type CloudResourcesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_list: ({
      ...{ __typename: 'CloudResourceListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, url?: *, name: *, status: *, date_no_longer_accessible?: *, hosted_on?: *, runtime?: *, region?: *, resource_type: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, cloud_console_url?: *, discovered_via: *, discovered_via_authenticator_id?: *, discovered_via_apigee_authenticator_id?: *, discovered_via_aws_authenticator_id?: *, discovered_via_azure_authenticator_id?: *, discovered_via_axway_authenticator_id?: *, discovered_via_gcp_authenticator_id?: *, discovered_via_mulesoft_authenticator_id?: *, discovered_via_user_id?: *, aws_arn?: *, gcp_project_id?: *, mulesoft_domain?: *, azure_tenant_id?: *, axway_org_id?: *, kong_workspace_name?: *, open_urgent_policy_violations_count?: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: *, resource_type_icon_url?: * }>,
      ...{ included_code_red_stats?: ?({
          ...{ __typename: 'CloudResourceIncludedCodeRedStats' },
        ...$Pick<CloudResourceIncludedCodeRedStats, { unauthenticated_policy_violation_id?: * }>
      }) }
    })> }
  }) }
});

export type ComplianceStandardCriteriaListQueryVariables = {};


export type ComplianceStandardCriteriaListQuery = ({
    ...{ __typename: 'Query' },
  ...{ compliance_standard_criteria_list: ({
      ...{ __typename: 'ComplianceStandardCriteriaListResponse' },
    ...{ compliance_standard_criteria: Array<({
        ...{ __typename: 'ComplianceStandardCriteria' },
      ...$Pick<ComplianceStandardCriteria, { compliance_standard: *, criterion: *, description: *, title: *, link?: *, referenced_by_policy_rule_type_ids: * }>
    })> }
  }) }
});

export type DetectedSpaCredentialsListQueryQueryVariables = {};


export type DetectedSpaCredentialsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ detected_spa_credentials_list: ({
      ...{ __typename: 'DetectedSpaCredentialsResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), detected_spa_credentials: Array<({
        ...{ __typename: 'DetectedSpaCredential' },
      ...$Pick<DetectedSpaCredential, { id: *, web_application_id: *, found_at_url: *, credential_as_markdown: *, exported_to_policy_violation_id?: *, triaging_decision: *, value: * }>,
      ...{ used_in_request: ({
          ...{ __typename: 'DetectedSpaCredentialUsedIn' },
        ...$Pick<DetectedSpaCredentialUsedIn, { header_name: *, header_value: *, method: *, url: * }>
      }) }
    })> }
  }) }
});

export type DomainNameListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type DomainNameListQuery = ({
    ...{ __typename: 'Query' },
  ...{ domain_name_list: ({
      ...{ __typename: 'DomainNameListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), domain_names: Array<({
        ...{ __typename: 'DomainName' },
      ...$Pick<DomainName, { id: *, name: *, discovered_via: *, date_created: *, asset_type_icon_url?: *, asset_type_name?: * }>,
      ...{ included_code_red_stats?: ?({
          ...{ __typename: 'IncludedCodeRedStats' },
        ...$Pick<IncludedCodeRedStats, { fingerprinted_resources_count?: *, unauthenticated_fingerprinted_resources_count?: * }>
      }) }
    })> }
  }) }
});

export type DomainNamesStatsQueryVariables = {};


export type DomainNamesStatsQuery = ({
    ...{ __typename: 'Query' },
  ...{ domain_names_stats: ({
      ...{ __typename: 'DomainNamesStatsResponse' },
    ...{ fingerprinted_resource_stats_field: ({
        ...{ __typename: 'FingerprintedResourceStatsField' },
      ...{ fingerprinted_resources_categories_stats: Array<({
          ...{ __typename: 'DomanNamesFingerprintedResourceCategoriesStats' },
        ...$Pick<DomanNamesFingerprintedResourceCategoriesStats, { fingerprinted_resource_category?: *, fingerprinted_resources_count?: *, unauthenticated_fingerprinted_resources_count?: *, asset_type_icon_url: *, asset_type_name: * }>
      })>, last_scan_stats: ({
          ...{ __typename: 'DomainNamesLastScanStats' },
        ...$Pick<DomainNamesLastScanStats, { scanned_domain_names_count?: *, fingerprinted_resources_count?: *, unauthenticated_fingerprinted_resources_count?: *, date_since_last_breached?: * }>
      }) }
    }) }
  }) }
});

export type GcpAuthenticatorsCreateMutationMutationVariables = {
  body: GcpAuthenticatorsCreateParams,
};


export type GcpAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ gcp_authenticators_create: ({
      ...{ __typename: 'GcpAuthenticatorsCreateResponse' },
    ...$Pick<GcpAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, organization_id: *, client_email: * }>
  }) }
});

export type GraphqlApiDetailsQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type GraphqlApiDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ graphql_api_details: ({
      ...{ __typename: 'GraphqlApiDetailsResponse' },
    ...{ asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: * }>
    })>, domain_names: Array<({
        ...{ __typename: 'DomainName' },
      ...$Pick<DomainName, { id: * }>
    })>, events: Array<({
        ...{ __typename: 'Event' },
      ...$Pick<Event, { id: * }>
    })>, graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { asset_type_icon_url: *, asset_type_name: *, aws_arn?: *, azure_tenant_id?: *, date_created: *, discovered_via: *, discovered_via_actor_id?: *, discovered_via_apigee_authenticator_id?: *, discovered_via_apigee_on_prem_authenticator_id?: *, discovered_via_aws_authenticator_id?: *, discovered_via_axway_authenticator_id?: *, discovered_via_azure_authenticator_id?: *, discovered_via_cloud_authenticator_id?: *, discovered_via_gcp_authenticator_id?: *, discovered_via_kong_authenticator_id?: *, discovered_via_mulesoft_authenticator_id?: *, discovered_via_user_id?: *, gcp_project_id?: *, hosted_on: *, id: *, mulesoft_asset_id?: *, network_service_id?: *, policy_violation_ids: *, status: *, url: * }>
    })>, mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: * }>
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, status: *, additional_info?: *, date_created: *, date_resolved?: *, exception_type?: *, exception_date_created?: *, affected_cloud_resource_id?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, asset_type_name: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }) }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })> }
  }) }
});

export type GraphqlApisListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_hosted_on?: ?HostedOn,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type GraphqlApisListQuery = ({
    ...{ __typename: 'Query' },
  ...{ graphql_api_list: ({
      ...{ __typename: 'GraphqlApiListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { asset_type_icon_url: *, asset_type_name: *, aws_arn?: *, azure_tenant_id?: *, date_created: *, discovered_via: *, discovered_via_actor_id?: *, discovered_via_apigee_authenticator_id?: *, discovered_via_apigee_on_prem_authenticator_id?: *, discovered_via_aws_authenticator_id?: *, discovered_via_axway_authenticator_id?: *, discovered_via_azure_authenticator_id?: *, discovered_via_cloud_authenticator_id?: *, discovered_via_gcp_authenticator_id?: *, discovered_via_kong_authenticator_id?: *, discovered_via_mulesoft_authenticator_id?: *, discovered_via_user_id?: *, gcp_project_id?: *, hosted_on: *, id: *, mulesoft_asset_id?: *, network_service_id?: *, policy_violation_ids: *, status: *, url: * }>
    })> }
  }) }
});

export type InventoryStatsGetQueryQueryVariables = {};


export type InventoryStatsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: *, serverless_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type InventoryStatsByDateQueryVariables = {
  reference_date?: ?$ElementType<Scalars, 'String'>,
};


export type InventoryStatsByDateQuery = ({
    ...{ __typename: 'Query' },
  ...{ inventory_stats_reference_date_get: ({
      ...{ __typename: 'InventoryStatsResponse' },
    ...{ inventory_stats: Array<({
        ...{ __typename: 'InventoryStats' },
      ...{ all_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { shadow_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { shadow_assets_count: *, shadow_serverless_assets_count: * }>
        }) }
      }), aws_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }) }
      }), gcp_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }) }
      }), azure_assets_stats: ({
          ...{ __typename: 'AllAssetsStats' },
        ...{ network_services_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), restful_apis_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), web_applications_stats: ({
            ...{ __typename: 'AssetsInventoryStats' },
          ...$Pick<AssetsInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }), cloud_resources_stats: ({
            ...{ __typename: 'CloudResourcesInventoryStats' },
          ...$Pick<CloudResourcesInventoryStats, { assets_total_count: *, shadow_assets_count: *, urgent_assets_count: *, important_assets_count: *, proactive_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type KongAuthenticatorsCreateMutationMutationVariables = {
  body: KongAuthenticatorsCreateParams,
};


export type KongAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ kong_authenticators_create: ({
      ...{ __typename: 'KongAuthenticatorsCreateResponse' },
    ...$Pick<KongAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, kong_admin_subdomain: * }>
  }) }
});

export type FullStackSecurityMetricsQueryVariables = {};


export type FullStackSecurityMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ full_stack_security_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ full_stack_metrics?: ?({
          ...{ __typename: 'FullStackMetrics' },
        ...$Pick<FullStackMetrics, { api_operations_total_count: *, api_operations_exercised_by_mobile_applications_count: *, api_operations_exercised_by_web_applications_count: *, api_operations_hosted_on_cloud_platforms_count: * }>
      }) }
    })> }
  }) }
});

export type WebApplicationXssToolkitMetricsQueryVariables = {};


export type WebApplicationXssToolkitMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_xss_toolkit_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ xss_toolkit_metrics?: ?({
          ...{ __typename: 'WebApplicationXssToolkitMetrics' },
        ...$Pick<WebApplicationXssToolkitMetrics, { urls_count: *, web_applications_count: *, vulnerable_web_applications_count: *, watchlisted_web_applications_count: *, xss_scan_runs_count: *, ongoing_xss_scan_runs_count: * }>
      }) }
    })> }
  }) }
});

export type CloudResourceCategoriesMetricsQueryVariables = {};


export type CloudResourceCategoriesMetricsQuery = ({
    ...{ __typename: 'Query' },
  ...{ cloud_resource_categories_metrics: ({
      ...{ __typename: 'MetricsResponse' },
    ...{ metrics: Array<({
        ...{ __typename: 'Metrics' },
      ...{ cloud_resource_categories_metrics?: ?({
          ...{ __typename: 'CloudResourceCategoriesMetrics' },
        ...$Pick<CloudResourceCategoriesMetrics, { reference_date: * }>,
        ...{ all_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }), aws_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }), gcp_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }), azure_assets_stats: ({
            ...{ __typename: 'PerEnvironmentCloudResourceCategoriesMetrics' },
          ...$Pick<PerEnvironmentCloudResourceCategoriesMetrics, { assets_total_count: *, serverless_assets_count: *, database_assets_count: *, virtual_machine_assets_count: *, storage_assets_count: *, api_management_assets_count: *, secret_management_assets_count: *, other_assets_count: *, kubernetes_assets_count: * }>
        }) }
      }) }
    })> }
  }) }
});

export type MobileApplicationDetailsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type MobileApplicationDetailsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_application_details: ({
      ...{ __typename: 'MobileApplicationDetailsResponse' },
    ...{ mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: *, app_secure_id?: *, name: *, platform: *, icon_url?: * }>,
      ...{ most_recent_scan?: ?({
          ...{ __typename: 'ApplicationScan' },
        ...$Pick<ApplicationScan, { end_date?: *, date_updated?: *, mobile_app_id?: *, start_date?: *, date_created?: *, app_version?: *, id: * }>
      }) }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { http_method: *, path: *, is_authenticated?: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: *, date_resolved?: * }>,
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>
          }) }
        })>, restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>
      }) }
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url?: *, url?: * }>,
      ...{ policy_violations: Array<({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, status: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: *, title: * }>
          }) }
        }) }
      })> }
    })> }
  }) }
});

export type MulesoftAuthenticatorsCreateMutationMutationVariables = {
  body: MulesoftAuthenticatorsCreateParams,
};


export type MulesoftAuthenticatorsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mulesoft_authenticators_create: ({
      ...{ __typename: 'MulesoftAuthenticatorsCreateResponse' },
    ...$Pick<MulesoftAuthenticatorsCreateResponse, { id: *, date_created: *, added_by_user_id: *, organization_id: * }>
  }) }
});

export type NetworkServicesCreateMutationMutationVariables = {
  body: NetworkServicesCreateParams,
};


export type NetworkServicesCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ network_services_create: ({
      ...{ __typename: 'NetworkService' },
    ...$Pick<NetworkService, { id: *, port: *, url: *, status: *, application_layer_protocol: *, is_tls_encrypted: *, hosted_on?: *, date_no_longer_accessible?: *, asset_type_name: *, asset_type_icon_url: *, discovered_via: *, date_created: * }>
  }) }
});

export type NetworkServiceDetailsQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type NetworkServiceDetailsQuery = ({
    ...{ __typename: 'Query' },
  ...{ network_service_details: ({
      ...{ __typename: 'NetworkServiceDetailsResponse' },
    ...{ cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, name: *, resource_type: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, date_created: *, status: *, date_no_longer_accessible?: *, asset_type_icon_url: *, asset_type_name: *, discovered_via: * }>,
      ...{ domain_name: ({
          ...{ __typename: 'DomainName' },
        ...$Pick<DomainName, { id: *, name: * }>
      }) }
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, status: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })>, certificate_chains: Array<({
        ...{ __typename: 'CertificateChain' },
      ...$Pick<CertificateChain, { id: * }>,
      ...{ security_properties: Array<({
          ...{ __typename: 'CertificateChainSecurityProperty' },
        ...$Pick<CertificateChainSecurityProperty, { enum: *, value: *, relevance?: * }>
      })>, certificates: Array<({
          ...{ __typename: 'Certificate' },
        ...$Pick<Certificate, { id: *, issuer_as_rfc4514_string: *, date_created: *, domain_names_in_cn: *, public_key_algorithm: *, signature_hash_algorithm: *, public_key_size: *, not_valid_after: * }>
      })> }
    })>, tls_scan_results: Array<({
        ...{ __typename: 'TlsScanResult' },
      ...{ tls_scan_attributes: Array<({
          ...{ __typename: 'TlsScanAttribute' },
        ...$Pick<TlsScanAttribute, { enum: *, value: * }>,
        ...{ policy_violation?: ?({
            ...{ __typename: 'PolicyViolation' },
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>
          }) }
        }) }
      })> }
    })> }
  }) }
});

export type NotificationEventsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type NotificationEventsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ notification_events_list: ({
      ...{ __typename: 'EventsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), events?: ?Array<({
        ...{ __typename: 'HorizonNotificationEvent' },
      ...$Pick<HorizonNotificationEvent, { id: *, title?: *, event_type: *, icon_url: *, detail: *, detail_icon_url: *, date_created: *, portal_url: *, subtitle?: * }>
    })> }
  }) }
});

export type OpenApiDefinitionsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type OpenApiDefinitionsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ openapi_definitions_list: ({
      ...{ __typename: 'OpenAPIDefinitionsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), openapi_definitions: Array<({
        ...{ __typename: 'OpenApiDefinition' },
      ...$Pick<OpenApiDefinition, { id: *, title: *, discovered_via: *, discovered_via_aws_authenticator_id?: *, discovered_via_user_id?: *, original_document: *, date_created: * }>
    })> }
  }) }
});

export type OpenApiDefinitionsCreateMutationMutationVariables = {
  body: $ElementType<Scalars, 'String'>,
};


export type OpenApiDefinitionsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ openapi_definitions_create: ({
      ...{ __typename: 'OpenApiDefinitionsCreateResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { title: * }>
    })> }
  }) }
});

export type PoliciesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type PoliciesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policies_list: ({
      ...{ __typename: 'PoliciesListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, created_by_actor_id: *, created_by_actor_name: *, is_special_dt_policy: *, date_created: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: *, protected_asset_groups_count: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { id: *, first_name: *, last_name: *, login_email: *, date_created?: *, notification_email?: * }>
      }) }
    })> }
  }) }
});

export type PoliciesGetQueryVariables = {
  policyId?: ?$ElementType<Scalars, 'ID'>,
};


export type PoliciesGetQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_by_id: ({
      ...{ __typename: 'PolicyDetailResponse' },
    ...{ policies: Array<({
        ...{ __typename: 'Policy' },
      ...$Pick<Policy, { id: *, name: *, created_by_user_id: *, created_by_actor_id: *, created_by_actor_name: *, is_special_dt_policy: *, date_created: *, protected_asset_groups_count: *, enabled_policy_rules_count: *, policy_rule_ids?: *, description: * }>,
      ...{ created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { id: *, first_name: *, last_name: *, login_email: *, date_created?: *, notification_email?: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, relevance: *, open_policy_violations_count: *, resolved_policy_violations_count: *, belongs_to_policy_name: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, asset_groups: Array<({
        ...{ __typename: 'AssetGroups' },
      ...$Pick<AssetGroups, { id: *, date_created: *, name: *, is_read_only: *, description: *, attached_policy_id: *, created_by_user_id: * }>,
      ...{ asset_group_memberships_overview: ({
          ...{ __typename: 'AssetGroupMembershipsOverview' },
        ...$Pick<AssetGroupMembershipsOverview, { restful_apis_count: *, web_applications_count: *, network_services_count: *, cloud_resources_count: *, api_operations_count: * }>
      }), created_by_user: ({
          ...{ __typename: 'HorizonUserField' },
        ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
      }) }
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type PoliciesRemoveMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type PoliciesRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policies_remove: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type PoliciesPolicyRuleTypesListQueryVariables = {
  policy_id: $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_group?: ?Array<$ElementType<Scalars, 'String'>>,
  filter_by_compliance_standard_criteria?: ?Array<$ElementType<Scalars, 'String'>>,
  include_headliner_hacks_only?: ?$ElementType<Scalars, 'Boolean'>,
};


export type PoliciesPolicyRuleTypesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policies_policy_rule_types_list: ({
      ...{ __typename: 'PoliciesPolicyRuleTypeListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleTypeV2' },
      ...$Pick<PolicyRuleTypeV2, { id: *, default_relevance?: *, description: *, group?: *, recommendation?: *, requires_configuration?: *, title: *, headliner_hack_titles?: * }>,
      ...{ configuration_default_value?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { http_methods_to_scan?: *, nb_of_days_before_cert_expiration?: *, time_when_to_scan?: *, weekdays_when_to_scan?: *, enabled_google_pii_types?: * }>
      }), compliance_policy_references: Array<({
          ...{ __typename: 'PolicyComplianceReference' },
        ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
      })>, related_rule_types?: ?Array<({
          ...{ __typename: 'PolicyRuleTypeRelatedRuleType' },
        ...$Pick<PolicyRuleTypeRelatedRuleType, { id: *, default_relevance?: *, description: *, group?: *, recommendation?: *, requires_configuration?: *, title: * }>,
        ...{ configuration_default_value?: ?({
            ...{ __typename: 'PolicyRuleConfiguration' },
          ...$Pick<PolicyRuleConfiguration, { http_methods_to_scan?: *, nb_of_days_before_cert_expiration?: *, time_when_to_scan?: *, weekdays_when_to_scan?: *, enabled_google_pii_types?: * }>
        }), compliance_policy_references: Array<({
            ...{ __typename: 'PolicyComplianceReference' },
          ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
        })> }
      })>, policy_rule?: ?({
          ...{ __typename: 'PolicyRuleTypeV2PolicyRule' },
        ...$Pick<PolicyRuleTypeV2PolicyRule, { id: *, date_created: *, relevance: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
        ...{ configuration?: ?({
            ...{ __typename: 'PolicyRuleConfiguration' },
          ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
        }) }
      }) }
    })> }
  }) }
});

export type PolicyRuleQueryQueryVariables = {
  policyId?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyRuleQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_by_id: ({
      ...{ __typename: 'PolicyDetailResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, belongs_to_policy_name: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, relevance: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
      ...{ policy: ({
          ...{ __typename: 'Policy' },
        ...$Pick<Policy, { id: * }>
      }), configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })> }
  }) }
});

export type PolicyRulesListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policy_rule_type_id?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyRulesListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_list: ({
      ...{ __typename: 'PolicyRuleListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, belongs_to_policy_name: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })> }
  }) }
});

export type PolicyRulesByPolicyIdListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  policyId: $ElementType<Scalars, 'ID'>,
};


export type PolicyRulesByPolicyIdListQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_rules_by_policy_id_list: ({
      ...{ __typename: 'PolicyRulesByPolicyIdListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { default_relevance?: *, description: *, group?: *, id: *, recommendation?: *, requires_configuration?: *, title: * }>,
      ...{ compliance_policy_references: Array<({
          ...{ __typename: 'PolicyComplianceReference' },
        ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
      })>, configuration_default_value?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, open_policy_violations_count: *, belongs_to_policy_name: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, users: Array<({
        ...{ __typename: 'HorizonUserField' },
      ...$Pick<HorizonUserField, { date_created?: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: * }>
    })> }
  }) }
});

export type PolicyRulesCreateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesCreateParams,
};


export type PolicyRulesCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_create: ({
      ...{ __typename: 'PolicyRule' },
    ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, open_policy_violations_count: *, resolved_policy_violations_count: *, belongs_to_policy_name: * }>,
    ...{ configuration?: ?({
        ...{ __typename: 'PolicyRuleConfiguration' },
      ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
    }) }
  }) }
});

export type PolicyRulesRemoveMutationVariables = {
  policyId: $ElementType<Scalars, 'ID'>,
  ruleId: $ElementType<Scalars, 'ID'>,
};


export type PolicyRulesRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_remove?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type PolicyRulesUpdateMutationVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  policy_rule_id?: ?$ElementType<Scalars, 'ID'>,
  body?: ?PolicyRulesUpdateParams,
};


export type PolicyRulesUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_rules_update: ({
      ...{ __typename: 'PolicyRulesUpdateResponse' },
    ...{ policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, belongs_to_policy_id: *, relevance: *, created_by_user_id?: *, deleted_by_user_id?: *, date_created: *, policy_rule_type_id: *, deletion_explanation?: *, open_policy_violations_count: *, resolved_policy_violations_count: * }>,
      ...{ configuration?: ?({
          ...{ __typename: 'PolicyRuleConfiguration' },
        ...$Pick<PolicyRuleConfiguration, { nb_of_days_before_cert_expiration?: *, http_methods_to_scan?: *, weekdays_when_to_scan?: *, time_when_to_scan?: *, enabled_google_pii_types?: * }>
      }) }
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, default_relevance?: * }>
    })> }
  }) }
});

export type PolicyViolationsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violation_by_id: ({
      ...{ __typename: 'PolicyViolationResponse' },
    ...{ policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_created: *, date_resolved?: *, exception_date_created?: *, exception_type?: *, additional_info?: *, status: *, affected_api_operation_id?: *, violated_policy_rule_id: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
        ...{ most_recent_analysis_result: ({
            ...{ __typename: 'WebAnalysisResult' },
          ...$Pick<WebAnalysisResult, { title: * }>
        }), network_service: ({
            ...{ __typename: 'NetworkService' },
          ...$Pick<NetworkService, { id: *, hosted_on?: * }>
        }) }
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, asset_type_name: *, asset_type_icon_url: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_graphql_api?: ?({
          ...{ __typename: 'GraphqlApi' },
        ...$Pick<GraphqlApi, { id: *, url: *, asset_type_name: *, asset_type_icon_url: *, hosted_on: * }>
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, path: *, http_method: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
          ...{ network_service: ({
              ...{ __typename: 'NetworkService' },
            ...$Pick<NetworkService, { id: *, hosted_on?: * }>
          }) }
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, name: *, url?: *, asset_type_name: *, asset_type_icon_url: *, hosted_on?: *, cloud_console_url?: * }>
      }) }
    })>, policy_rules: Array<({
        ...{ __typename: 'PolicyRule' },
      ...$Pick<PolicyRule, { id: *, relevance: * }>
    })>, policy_rule_types: Array<({
        ...{ __typename: 'PolicyRuleType' },
      ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: * }>
    })> }
  }) }
});

export type PolicyViolationsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  is_eligible_for_auto_remediation?: ?$ElementType<Scalars, 'Boolean'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_relevance?: ?Array<?PolicyRuleTypeRelevance>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
};


export type PolicyViolationsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list: ({
      ...{ __typename: 'PolicyViolationListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, additional_info?: *, affected_api_operation_id?: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, violated_policy_rule_id: *, status: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_restful_api?: ?({
          ...{ __typename: 'RestfulAPI' },
        ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
        ...{ network_service: ({
            ...{ __typename: 'NetworkService' },
          ...$Pick<NetworkService, { id: *, hosted_on?: * }>
        }) }
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>,
          ...{ network_service: ({
              ...{ __typename: 'NetworkService' },
            ...$Pick<NetworkService, { id: *, hosted_on?: * }>
          }) }
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_graphql_api?: ?({
          ...{ __typename: 'GraphqlApi' },
        ...$Pick<GraphqlApi, { id: *, url: *, asset_type_name: *, asset_type_icon_url: *, hosted_on: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: * }>
      }) }
    })> }
  }) }
});

export type PolicyViolationGetVersion2QueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type PolicyViolationGetVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ policy_violation_details_v2: ({
      ...{ __typename: 'PolicyViolationV2' },
    ...$Pick<PolicyViolationV2, { id: *, date_created: *, date_resolved?: *, exception_type?: *, additional_info?: *, status: *, exception_date_created?: *, exception_explanation?: * }>,
    ...{ affected_asset: ({
        ...{ __typename: 'PolicyViolationAffectedAsset' },
      ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, hosted_on?: *, aws_arn?: * }>,
      ...{ belongs_to_cloud_account?: ?({
          ...{ __typename: 'PolicyViolationAffectedAssetCloudAccount' },
        ...$Pick<PolicyViolationAffectedAssetCloudAccount, { id: *, cloud_account_icon_url: *, cloud_account_type_name: *, discovered_via_cloud_authenticator_id?: *, user_supplied_name?: * }>
      }) }
    }), violated_policy_rule: ({
        ...{ __typename: 'PolicyRuleV2' },
      ...$Pick<PolicyRuleV2, { id: *, relevance: * }>,
      ...{ policy_rule_type: ({
          ...{ __typename: 'PolicyRuleType' },
        ...$Pick<PolicyRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
        ...{ compliance_policy_references: Array<({
            ...{ __typename: 'PolicyComplianceReference' },
          ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
        })>, related_rule_types?: ?Array<({
            ...{ __typename: 'PolicyRuleTypeRelatedRuleType' },
          ...$Pick<PolicyRuleTypeRelatedRuleType, { id: *, title: *, description: *, recommendation?: *, default_relevance?: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        })> }
      }) }
    }) }
  }) }
});

export type PolicyViolationsListQueryVersion2QueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  filter_by_violation_status?: ?Array<?PolicyViolationStatusEnum>,
  filter_by_policy_rule_type_id?: ?Array<?$ElementType<Scalars, 'ID'>>,
  was_discovered_after?: ?$ElementType<Scalars, 'Date'>,
  filter_by_importance_tag?: ?Array<?FilterByImportanceTagEnum>,
  filter_by_product?: ?Array<?FilterByProductEnum>,
  filter_by_relevance?: ?Array<?PolicyRuleTypeRelevance>,
  order_by?: ?$ElementType<Scalars, 'String'>,
};


export type PolicyViolationsListQueryVersion2Query = ({
    ...{ __typename: 'Query' },
  ...{ policy_violations_list_v2: ({
      ...{ __typename: 'PolicyViolationListResponseVersion2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), policy_violations: Array<({
        ...{ __typename: 'PolicyViolationV2' },
      ...$Pick<PolicyViolationV2, { id: *, affected_asset_id?: *, affected_asset_name: *, affected_asset_type: *, affected_asset_type_name: *, affected_asset_type_icon_url?: *, affected_asset_hosted_on?: *, date_created: *, date_resolved?: *, status: *, additional_info?: *, opened_by_certificate_chain_id?: *, exception_type?: *, exception_explanation?: *, exception_date_created?: * }>,
      ...{ affected_asset: ({
          ...{ __typename: 'PolicyViolationAffectedAsset' },
        ...$Pick<PolicyViolationAffectedAsset, { id: *, name?: *, url?: *, asset_type: *, asset_type_icon_url: *, asset_type_name: *, date_created: *, hosted_on?: * }>
      }), violated_policy_rule: ({
          ...{ __typename: 'PolicyRuleV2' },
        ...$Pick<PolicyRuleV2, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: *, description: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }) }
    })> }
  }) }
});

export type PolicyViolationsPatchMutationMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: PolicyViolationsPatchParams,
};


export type PolicyViolationsPatchMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ policy_violations_patch: ({
      ...{ __typename: 'PolicyViolationsPatchResponse' },
    ...{ policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, exception_type?: *, exception_explanation?: *, exception_date_created?: * }>
    })> }
  }) }
});

export type ApiDetailsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type ApiDetailsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_details: ({
      ...{ __typename: 'RestfulAPIDetailsResponse' },
    ...{ restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, status: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { http_method: *, path: *, is_authenticated?: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: *, date_resolved?: * }>,
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>
          }) }
        })>, restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>
      }) }
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, asset_type_name: *, asset_type_icon_url: * }>
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url?: *, url?: * }>,
      ...{ policy_violations: Array<({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, status: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: *, title: * }>
          }) }
        }) }
      })> }
    })>, mobile_applications: Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, date_resolved?: *, date_created: *, exception_type?: *, exception_date_created?: *, status: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })> }
  }) }
});

export type RestfulApIsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type RestfulApIsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_list: ({
      ...{ __typename: 'RestfulAPIsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { total_count?: * }>
    }), restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: *, open_urgent_policy_violations_count?: * }>
    })> }
  }) }
});

export type RestfulApIsListQuery_V1QueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type RestfulApIsListQuery_V1Query = ({
    ...{ __typename: 'Query' },
  ...{ restful_api_list_v1: ({
      ...{ __typename: 'RestfulAPIsListResponse_v1' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, asset_type_name: *, asset_type_icon_url: *, date_created: *, date_no_longer_accessible?: *, discovered_via: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: *, is_authenticated?: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: *, date_resolved?: * }>,
          ...{ violated_policy_rule: ({
              ...{ __typename: 'PolicyRule' },
            ...$Pick<PolicyRule, { id: *, relevance: * }>,
            ...{ policy_rule_type: ({
                ...{ __typename: 'PolicyRuleType' },
              ...$Pick<PolicyRuleType, { id: *, title: * }>,
              ...{ compliance_policy_references: Array<({
                  ...{ __typename: 'PolicyComplianceReference' },
                ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
              })> }
            }) }
          }) }
        })>, restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      })>, network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, hosted_on?: *, url: *, asset_type_icon_url: *, asset_type_name: * }>
      }) }
    })> }
  }) }
});

export type SharedLinksCreateForPolicyViolationMutationMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: SharedLinksCreateForPolicyViolationParams,
};


export type SharedLinksCreateForPolicyViolationMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ shared_links_for_policy_violation_create: ({
      ...{ __typename: 'SharedLinksCreateForPolicyViolationResponse' },
    ...$Pick<SharedLinksCreateForPolicyViolationResponse, { shared_resource_type: *, shared_resource_id?: *, access_token: * }>
  }) }
});

export type SpecialScanRequestsListQueryQueryVariables = {
  scan_type?: ?$ElementType<Scalars, 'String'>,
};


export type SpecialScanRequestsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ special_scan_requests_list: ({
      ...{ __typename: 'SpecialScanRequestResponse' },
    ...{ special_scan_requests: Array<({
        ...{ __typename: 'SpecialScanRequest' },
      ...$Pick<SpecialScanRequest, { id: *, date_created: *, date_completed?: *, current_status: * }>,
      ...{ scan_configuration?: ?({
          ...{ __typename: 'SpecialScanRequestConfiguration' },
        ...$Pick<SpecialScanRequestConfiguration, { selected_all_web_applications?: *, selected_web_application_ids?: * }>
      }) }
    })> }
  }) }
});

export type SupportRequestsCreateMutationMutationVariables = {
  body: HorizonSupportRequestsCreateParams,
};


export type SupportRequestsCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ horizon_support_requests_create?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type ToolkitScanCreateMutationVariables = {
  body: ToolkitScanStartRequestParams,
};


export type ToolkitScanCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ toolkit_scan_create: ({
      ...{ __typename: 'ToolkitScanStartRequestResponse' },
    ...$Pick<ToolkitScanStartRequestResponse, { toolkit_scan_type: * }>,
    ...{ detect_and_inject_configuration?: ?({
        ...{ __typename: 'DetectAndInjectConfigurationType' },
      ...$Pick<DetectAndInjectConfigurationType, { api_operation_ids?: *, should_scan_all_api_operations?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'DetectAndInjectScanRunConfigurationType' },
        ...$Pick<DetectAndInjectScanRunConfigurationType, { http_methods_to_scan?: * }>
      }) }
    }), web_application_xss_configuration?: ?({
        ...{ __typename: 'WebApplicationXssConfigurationType' },
      ...$Pick<WebApplicationXssConfigurationType, { web_applications_ids?: *, should_scan_all_web_applications?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'WebApplicationXssScanRunConfigurationType' },
        ...$Pick<WebApplicationXssScanRunConfigurationType, { should_inject_in_get_parameters?: *, should_inject_in_post_parameters?: *, should_inject_in_url_path?: *, intensity?: * }>
      }) }
    }), triggered_scan_runs?: ?Array<({
        ...{ __typename: 'TriggeredScanRuns' },
      ...$Pick<TriggeredScanRuns, { id: *, date_created: *, scanned_asset_id: *, scanned_asset_type: * }>
    })> }
  }) }
});

export type ToolkitScansListQueryVariables = {
  filter_by_type?: ?ToolkitScanTypeEnum,
  filter_by_status?: ?SpecialScanStatus,
};


export type ToolkitScansListQuery = ({
    ...{ __typename: 'Query' },
  ...{ toolkit_scans: ({
      ...{ __typename: 'ToolkitScansResponse' },
    ...{ toolkit_scans: Array<?({
        ...{ __typename: 'ToolkitScans' },
      ...$Pick<ToolkitScans, { date_created?: *, date_finished?: *, status: *, toolkit_scan_type: * }>
    })> }
  }) }
});

export type DetectAndInjectScansSchedulesGetQueryQueryVariables = {};


export type DetectAndInjectScansSchedulesGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ detect_and_inject_scans_schedules: ({
      ...{ __typename: 'DetectAndInjectScansSchedulesResponse' },
    ...$Pick<DetectAndInjectScansSchedulesResponse, { time_when_to_scan?: *, toolkit_scan_type?: *, weekdays_when_to_scan?: * }>,
    ...{ detect_and_inject_configuration?: ?({
        ...{ __typename: 'DetectAndInjectConfigurationType' },
      ...$Pick<DetectAndInjectConfigurationType, { api_operation_ids?: *, should_scan_all_api_operations?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'DetectAndInjectScanRunConfigurationType' },
        ...$Pick<DetectAndInjectScanRunConfigurationType, { http_methods_to_scan?: * }>
      }) }
    }) }
  }) }
});

export type DetectAndInjectScansSchedulesSetQueryMutationVariables = {
  body?: ?DetectAndInjectScansScheduleSetRequestParams,
};


export type DetectAndInjectScansSchedulesSetQueryMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ detect_and_inject_scans_schedules_set: ({
      ...{ __typename: 'DetectAndInjectScansScheduleSetResponse' },
    ...$Pick<DetectAndInjectScansScheduleSetResponse, { weekdays_when_to_scan?: *, time_when_to_scan?: *, toolkit_scan_type?: * }>,
    ...{ detect_and_inject_configuration?: ?({
        ...{ __typename: 'DetectAndInjectConfigurationType' },
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'DetectAndInjectScanRunConfigurationType' },
        ...$Pick<DetectAndInjectScanRunConfigurationType, { http_methods_to_scan?: * }>
      }) }
    }) }
  }) }
});

export type WebApplicationXssSchedulesGetQueryQueryVariables = {};


export type WebApplicationXssSchedulesGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_xss_scans_schedules: ({
      ...{ __typename: 'WebApplicationXssScansSchedulesResponse' },
    ...$Pick<WebApplicationXssScansSchedulesResponse, { time_when_to_scan?: *, toolkit_scan_type?: *, weekdays_when_to_scan?: * }>,
    ...{ web_application_xss_configuration?: ?({
        ...{ __typename: 'WebApplicationXssConfigurationType' },
      ...$Pick<WebApplicationXssConfigurationType, { web_applications_ids?: *, should_scan_all_web_applications?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'WebApplicationXssScanRunConfigurationType' },
        ...$Pick<WebApplicationXssScanRunConfigurationType, { should_inject_in_get_parameters?: *, should_inject_in_post_parameters?: *, should_inject_in_url_path?: *, intensity?: * }>
      }) }
    }) }
  }) }
});

export type WebApplicationXssScansSchedulesSetQueryMutationVariables = {
  body?: ?WebApplicationXssScansScheduleSetRequestParams,
};


export type WebApplicationXssScansSchedulesSetQueryMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ web_application_xss_scans_schedules_set: ({
      ...{ __typename: 'WebApplicationXssScansSchedulesResponse' },
    ...$Pick<WebApplicationXssScansSchedulesResponse, { time_when_to_scan?: *, toolkit_scan_type?: *, weekdays_when_to_scan?: * }>,
    ...{ web_application_xss_configuration?: ?({
        ...{ __typename: 'WebApplicationXssConfigurationType' },
      ...$Pick<WebApplicationXssConfigurationType, { web_applications_ids?: *, should_scan_all_web_applications?: * }>,
      ...{ scan_run_configuration?: ?({
          ...{ __typename: 'WebApplicationXssScanRunConfigurationType' },
        ...$Pick<WebApplicationXssScanRunConfigurationType, { should_inject_in_get_parameters?: *, should_inject_in_post_parameters?: *, should_inject_in_url_path?: *, intensity?: * }>
      }) }
    }) }
  }) }
});

export type ComplianceReportScheduleQueryQueryVariables = {};


export type ComplianceReportScheduleQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ compliance_report_schedules: ({
      ...{ __typename: 'ComplianceReportScheduleResponse' },
    ...$Pick<ComplianceReportScheduleResponse, { time_when_to_scan?: *, toolkit_scan_type: *, weekdays_when_to_scan?: *, should_only_scan_monthly: * }>,
    ...{ compliance_reports_configuration: ({
        ...{ __typename: 'ComplianceReportConfigurationType' },
      ...$Pick<ComplianceReportConfigurationType, { recipient_emails: *, compliance_report_type: *, should_report_all_mobile_apps: *, customer_mobile_app_ids?: * }>
    }) }
  }) }
});

export type ComplianceReportScheduleUpdateMutationVariables = {
  body: ComplianceReportScheduleParams,
};


export type ComplianceReportScheduleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ compliance_reports_schedule_create: ({
      ...{ __typename: 'ComplianceReportScheduleResponse' },
    ...$Pick<ComplianceReportScheduleResponse, { time_when_to_scan?: *, toolkit_scan_type: *, weekdays_when_to_scan?: *, should_only_scan_monthly: * }>,
    ...{ compliance_reports_configuration: ({
        ...{ __typename: 'ComplianceReportConfigurationType' },
      ...$Pick<ComplianceReportConfigurationType, { recipient_emails: *, compliance_report_type: *, should_report_all_mobile_apps: *, customer_mobile_app_ids?: * }>
    }) }
  }) }
});

export type WebApplicationDetailsGetQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
};


export type WebApplicationDetailsGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_details: ({
      ...{ __typename: 'WebApplicationDetailsResponse' },
    ...{ web_applications: Array<({
        ...{ __typename: 'WebApplication' },
      ...$Pick<WebApplication, { id: *, base_url: *, status: *, date_no_longer_accessible?: *, base_path: *, discovered_via: *, asset_type_name: *, asset_type_icon_url: *, network_service_id: *, date_created: * }>,
      ...{ network_service: ({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>,
        ...{ policy_violations: Array<({
            ...{ __typename: 'PolicyViolation' },
          ...$Pick<PolicyViolation, { id: *, status: * }>
        })> }
      }), most_recent_analysis_result: ({
          ...{ __typename: 'WebAnalysisResult' },
        ...$Pick<WebAnalysisResult, { title: *, screenshot_url: *, fullscreen_screenshot_url: *, web_application_type: *, detected_restful_api_ids?: *, exercised_api_operation_ids?: *, date_created: *, date_last_received: * }>,
        ...{ detected_libraries?: ?Array<({
            ...{ __typename: 'WebLibrary' },
          ...$Pick<WebLibrary, { name: *, project_url: *, icon_url: * }>
        })>, detected_network_activity?: ?Array<({
            ...{ __typename: 'WebActivity' },
          ...$Pick<WebActivity, { base_url: *, request_types: * }>
        })> }
      }), policy_violations: Array<({
          ...{ __typename: 'PolicyViolation' },
        ...$Pick<PolicyViolation, { id: *, additional_info?: *, status: *, date_resolved?: *, exception_type?: *, affected_restful_api_id?: *, affected_api_operation_id?: *, affected_network_service_id?: * }>,
        ...{ violated_policy_rule: ({
            ...{ __typename: 'PolicyRule' },
          ...$Pick<PolicyRule, { id: *, relevance: * }>,
          ...{ policy_rule_type: ({
              ...{ __typename: 'PolicyRuleType' },
            ...$Pick<PolicyRuleType, { id: * }>
          }) }
        }) }
      })> }
    })>, restful_apis: Array<({
        ...{ __typename: 'RestfulAPI' },
      ...$Pick<RestfulApi, { id: *, title: *, base_url: *, base_path: *, network_service_id: *, asset_type_name: *, asset_type_icon_url: * }>,
      ...{ api_operations?: ?Array<({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>
      })> }
    })>, cloud_resources: Array<({
        ...{ __typename: 'CloudResource' },
      ...$Pick<CloudResource, { id: *, date_created: *, name: *, region?: *, resource_type: *, asset_type_icon_url: *, asset_type_name: *, runtime?: *, status: *, date_no_longer_accessible?: *, discovered_via: *, hosted_on?: *, cloud_console_url?: *, url?: * }>
    })>, network_services: Array<({
        ...{ __typename: 'NetworkService' },
      ...$Pick<NetworkService, { id: *, url: *, hosted_on?: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, graphql_apis: Array<({
        ...{ __typename: 'GraphqlApi' },
      ...$Pick<GraphqlApi, { id: *, url: *, hosted_on: *, asset_type_icon_url: *, asset_type_name: * }>
    })>, policy_violations: Array<({
        ...{ __typename: 'PolicyViolation' },
      ...$Pick<PolicyViolation, { id: *, affected_restful_api_id?: *, affected_api_operation_id?: *, affected_network_service_id?: *, status: *, date_resolved?: *, exception_type?: *, date_created: *, exception_date_created?: * }>,
      ...{ violated_policy_rule: ({
          ...{ __typename: 'PolicyRule' },
        ...$Pick<PolicyRule, { id: *, relevance: * }>,
        ...{ policy_rule_type: ({
            ...{ __typename: 'PolicyRuleType' },
          ...$Pick<PolicyRuleType, { id: *, title: * }>,
          ...{ compliance_policy_references: Array<({
              ...{ __typename: 'PolicyComplianceReference' },
            ...$Pick<PolicyComplianceReference, { compliance_standard: *, compliance_standard_criteria: *, description: *, link: * }>
          })> }
        }) }
      }), affected_api_operation?: ?({
          ...{ __typename: 'APIOperation' },
        ...$Pick<ApiOperation, { id: *, http_method: *, path: * }>,
        ...{ restful_api: ({
            ...{ __typename: 'RestfulAPI' },
          ...$Pick<RestfulApi, { id: *, base_url: * }>
        }) }
      }), affected_cloud_resource?: ?({
          ...{ __typename: 'CloudResource' },
        ...$Pick<CloudResource, { id: *, asset_type_name: *, url?: * }>
      }), affected_network_service?: ?({
          ...{ __typename: 'NetworkService' },
        ...$Pick<NetworkService, { id: * }>,
        ...{ domain_name: ({
            ...{ __typename: 'DomainName' },
          ...$Pick<DomainName, { id: *, name: * }>
        }) }
      }), affected_web_application?: ?({
          ...{ __typename: 'WebApplication' },
        ...$Pick<WebApplication, { id: *, base_url: * }>
      }) }
    })> }
  }) }
});

export type WebApplicationsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  include?: ?$ElementType<Scalars, 'String'>,
  order_by?: ?$ElementType<Scalars, 'String'>,
  filter_by_text?: ?$ElementType<Scalars, 'String'>,
};


export type WebApplicationsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_applications_list_v2: ({
      ...{ __typename: 'WebApplicationListResponseVersion2' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), web_applications: Array<({
        ...{ __typename: 'WebApplicationVersion2' },
      ...$Pick<WebApplicationVersion2, { id: *, discovered_via: *, network_service_id: *, base_url: *, base_path: *, title: *, screenshot_url: *, date_created: *, policy_violation_ids?: *, open_important_policy_violations_count?: *, open_proactive_policy_violations_count?: *, open_urgent_policy_violations_count?: *, hosted_on?: * }>,
      ...{ included_detected_spa_credentials_stats?: ?({
          ...{ __typename: 'WebAppCredentialsStat' },
        ...$Pick<WebAppCredentialsStat, { detected_spa_credentials_count: * }>
      }), included_connected_assets_stats?: ?({
          ...{ __typename: 'WebAppConnectedAssetsStats' },
        ...{ api_management_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), api_operations_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), database_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), graphql_apis_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), network_services_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), other_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), secret_management_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), serverless_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), storage_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }), virtual_machine_cloud_resources_stats: ({
            ...{ __typename: 'WebAppAssetsInventoryStats' },
          ...$Pick<WebAppAssetsInventoryStats, { important_assets_count: *, proactive_assets_count: *, total_assets_count: *, urgent_assets_count: * }>
        }) }
      }), included_xss_toolkit_stats?: ?({
          ...{ __typename: 'WebAppXssToolkitStats' },
        ...$Pick<WebAppXssToolkitStats, { next_xss_scan_run_date?: *, urls_count: *, open_xss_policy_violations_count: *, xss_result: * }>,
        ...{ latest_xss_scan_run?: ?({
            ...{ __typename: 'LatestScanRun' },
          ...$Pick<LatestScanRun, { id: *, date_created: *, scan_type: *, status: * }>
        }) }
      }) }
    })> }
  }) }
});

export type WebApplicationDetailsGetUrlsQueryQueryVariables = {
  id?: ?$ElementType<Scalars, 'ID'>,
  include?: ?$ElementType<Scalars, 'String'>,
};


export type WebApplicationDetailsGetUrlsQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ web_application_urls: ({
      ...{ __typename: 'WebApplicationUrlsResponse' },
    ...{ urls: Array<({
        ...{ __typename: 'WebAppSummaryDiscoveredUrl' },
      ...$Pick<WebAppSummaryDiscoveredUrl, { url: * }>,
      ...{ xss_scan_result?: ?({
          ...{ __typename: 'XssScanRunResultUrl' },
        ...{ scanned_get_parameters: Array<({
            ...{ __typename: 'XssScanRunResultUrlGetParameter' },
          ...$Pick<XssScanRunResultUrlGetParameter, { name: *, sample_value?: * }>
        })>, reflected_get_parameters: Array<({
            ...{ __typename: 'XssScanRunResultUrlGetParameterReflected' },
          ...$Pick<XssScanRunResultUrlGetParameterReflected, { name: *, payload?: *, page_src_position_where_reflected?: *, page_src_position_snippet?: * }>
        })>, vulnerable_get_parameters: Array<({
            ...{ __typename: 'XssScanRunResultUrlGetParameterVulnerableToXss' },
          ...$Pick<XssScanRunResultUrlGetParameterVulnerableToXss, { name: *, payload?: *, page_src_position_where_reflected?: *, page_src_position_snippet?: * }>
        })> }
      }) }
    })> }
  }) }
});

export type CreateDataTrustReportMutationVariables = {
  report_type: ComplianceReportType,
  body: ComplianceReportParams,
};


export type CreateDataTrustReportMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ data_trust_report: ({
      ...{ __typename: 'ComplianceReportResponse' },
    ...$Pick<ComplianceReportResponse, { download_url: * }>
  }) }
});

export type IpRangeScansCreateMutationMutationVariables = {
  body: IpRangeScanCreateParams,
};


export type IpRangeScansCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ip_range_scans_create: ({
      ...{ __typename: 'IpRangeScanCreateResponse' },
    ...{ ip_range_scans: Array<({
        ...{ __typename: 'IpRangeScan' },
      ...$Pick<IpRangeScan, { ip_range: *, ip_range_id?: *, title?: * }>,
      ...{ ip_address_scan_results: Array<({
          ...{ __typename: 'IpRangeScanResult' },
        ...$Pick<IpRangeScanResult, { ip_address: *, online_network_service_urls: * }>
      })> }
    })>, ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type IpRangesListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type IpRangesListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ ip_ranges_list: ({
      ...{ __typename: 'IpRangeListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type IpRangesCreateMutationMutationVariables = {
  body: IpRangeCreateParams,
};


export type IpRangesCreateMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ip_ranges_create: ({
      ...{ __typename: 'IpRangeCreateResponse' },
    ...{ ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type IpRangesPatchMutationMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: IpRangePatchParams,
};


export type IpRangesPatchMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ ip_ranges_patch: ({
      ...{ __typename: 'IpRangePatchResponse' },
    ...{ ip_ranges: Array<({
        ...{ __typename: 'IpRange' },
      ...$Pick<IpRange, { id: *, has_continuous_discovery_enabled: *, ip_range: * }>
    })> }
  }) }
});

export type MobileAppGetQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type MobileAppGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_get: ({
      ...{ __typename: 'MobileApp' },
    ...$Pick<MobileApp, { id: *, name: *, platform: *, max_app_protection_score: *, subscription: *, release_type?: *, bundle_id?: *, is_enterprise_internal?: *, app_store_customer_mobile_app_id?: *, icon_url?: *, store_url?: *, date_created: *, scan_status?: *, all_communication_encrypted: *, does_not_offload_data_to_third_parties: *, enhances_password_security: *, full_protection_on_untrusted_networks: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_sdk: *, leverages_secure_system_settings: *, protects_data_on_screens: *, protects_the_keyboard: *, removes_data_from_shared_device_locations: *, requires_android_device_security: *, requires_android_malware_protection: *, server_enforces_https: *, supports_two_factor_authentication: *, trustkit_url?: * }>,
    ...{ most_recent_scan?: ?({
        ...{ __typename: 'ApplicationScan' },
      ...$Pick<ApplicationScan, { app_version?: *, start_date?: *, end_date?: * }>
    }), metadata: ({
        ...{ __typename: 'ApplicationMetadata' },
      ...$Pick<ApplicationMetadata, { open_security_finding_priority_alerts_count: *, open_security_finding_high_count: *, open_security_finding_medium_count: *, open_security_finding_low_count: * }>
    }), keys_to_the_kingdom: ({
        ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
      ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
    }), coffee_shop_compromise: ({
        ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
      ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
    }), app_store_privacy?: ?({
        ...{ __typename: 'AppStorePrivacy' },
      ...$Pick<AppStorePrivacy, { data_record_types_collected_count: *, data_records_collected_count: *, data_records_shared_with_sdks_count: *, embedded_sdk_count: *, downloaded_in_countries?: *, downloaded_in_countries_names?: * }>
    }) }
  }) }
});

export type MobileAppsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  platform?: ?MobileAppPlatform,
};


export type MobileAppsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_apps_list: ({
      ...{ __typename: 'MobileAppsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_apps?: ?Array<({
        ...{ __typename: 'MobileApp' },
      ...$Pick<MobileApp, { id: *, name: *, platform: *, max_app_protection_score: *, subscription: *, release_type?: *, bundle_id?: *, is_enterprise_internal?: *, app_store_customer_mobile_app_id?: *, icon_url?: *, store_url?: *, date_created: *, scan_status?: *, all_communication_encrypted: *, does_not_offload_data_to_third_parties: *, enhances_password_security: *, full_protection_on_untrusted_networks: *, leverages_secure_browser_settings: *, leverages_secure_system_libraries: *, leverages_secure_system_sdk: *, leverages_secure_system_settings: *, protects_data_on_screens: *, protects_the_keyboard: *, removes_data_from_shared_device_locations: *, requires_android_device_security: *, requires_android_malware_protection: *, server_enforces_https: *, supports_two_factor_authentication: *, trustkit_url?: * }>,
      ...{ most_recent_scan?: ?({
          ...{ __typename: 'ApplicationScan' },
        ...$Pick<ApplicationScan, { app_version?: *, start_date?: *, end_date?: * }>
      }), metadata: ({
          ...{ __typename: 'ApplicationMetadata' },
        ...$Pick<ApplicationMetadata, { open_security_finding_priority_alerts_count: *, open_security_finding_high_count: *, open_security_finding_medium_count: *, open_security_finding_low_count: * }>
      }), keys_to_the_kingdom: ({
          ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
        ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
      }), coffee_shop_compromise: ({
          ...{ __typename: 'ToolkitsFindingAndTargetsCount' },
        ...$Pick<ToolkitsFindingAndTargetsCount, { open_security_finding_count: *, open_security_finding_target_count: * }>
      }), app_store_privacy?: ?({
          ...{ __typename: 'AppStorePrivacy' },
        ...$Pick<AppStorePrivacy, { data_record_types_collected_count: *, data_records_collected_count: *, data_records_shared_with_sdks_count: *, embedded_sdk_count: *, downloaded_in_countries?: *, downloaded_in_countries_names?: * }>
      }) }
    })> }
  }) }
});

export type AnalysisRequestListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type AnalysisRequestListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_analysis_requests_list: ({
      ...{ __typename: 'MobileAppAnalysisRequestListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), mobile_app_analysis_requests?: ?Array<({
        ...{ __typename: 'AnalysisRequest' },
      ...$Pick<AnalysisRequest, { id: *, date_created: *, date_updated: *, feature?: *, progress: *, requested_by: *, status?: * }>,
      ...{ analyzed_apps?: ?Array<({
          ...{ __typename: 'AnalyzedApp' },
        ...$Pick<AnalyzedApp, { mobile_app_id: *, scan_status: * }>
      })> }
    })> }
  }) }
});

export type AnalysisRequestCreateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisRequestParams,
};


export type AnalysisRequestCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_app_analysis_request_create: ({
      ...{ __typename: 'AnalysisRequest' },
    ...$Pick<AnalysisRequest, { id: *, date_created: *, date_updated: *, feature?: *, progress: *, requested_by: *, status?: * }>,
    ...{ analyzed_apps?: ?Array<({
        ...{ __typename: 'AnalyzedApp' },
      ...$Pick<AnalyzedApp, { mobile_app_id: *, scan_status: * }>
    })> }
  }) }
});

export type AnalysisScheduleGetQueryQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type AnalysisScheduleGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ mobile_app_analysis_schedules_get: ({
      ...{ __typename: 'MobileAppAnalysisScheduleResponse' },
    ...$Pick<MobileAppAnalysisScheduleResponse, { id: *, date_created: *, date_updated: *, time_when_to_scan: *, weekdays_when_to_scan?: * }>
  }) }
});

export type AnalysisScheduleUpdateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  body: MobileAppAnalysisScheduleParams,
};


export type AnalysisScheduleUpdateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ mobile_app_analysis_schedule_patch: ({
      ...{ __typename: 'MobileAppAnalysisScheduleResponse' },
    ...$Pick<MobileAppAnalysisScheduleResponse, { id: *, date_created: *, date_updated: *, time_when_to_scan: *, weekdays_when_to_scan?: * }>
  }) }
});

export type OnboardingCompletePutMutationMutationVariables = {};


export type OnboardingCompletePutMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboarding_completion_put: ({
      ...{ __typename: 'OnboardingCompletionResponse' },
    ...$Pick<OnboardingCompletionResponse, { onboarding_completed: * }>
  }) }
});

export type OnboardingCompleteDeleteMutationMutationVariables = {};


export type OnboardingCompleteDeleteMutationMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ onboarding_completion_delete: ({
      ...{ __typename: 'OnboardingCompletionResponse' },
    ...$Pick<OnboardingCompletionResponse, { onboarding_completed: * }>
  }) }
});

export type PortalNotificationsListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type PortalNotificationsListQuery = ({
    ...{ __typename: 'Query' },
  ...{ portal_notifications_list: ({
      ...{ __typename: 'PortalNotificationsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), portal_notifications?: ?Array<({
        ...{ __typename: 'SevenhellNotificationEvent' },
      ...$Pick<SevenhellNotificationEvent, { id: *, title?: *, event_type: *, icon_url: *, detail: *, detail_icon_url: *, date_created: *, portal_url: *, subtitle?: * }>
    })> }
  }) }
});

export type SecretStringsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type SecretStringsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ secret_strings_list: ({
      ...{ __typename: 'SecretStringsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), secret_strings?: ?Array<({
        ...{ __typename: 'SecretString' },
      ...$Pick<SecretString, { id: *, added_by: *, date_created: *, value: * }>
    })> }
  }) }
});

export type SecretStringsCreateMutationVariables = {
  body: SecretStringParams,
};


export type SecretStringsCreateMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ secret_strings_create: ({
      ...{ __typename: 'SecretString' },
    ...$Pick<SecretString, { id: *, added_by: *, date_created: *, value: * }>
  }) }
});

export type SecretStringsRemoveMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};


export type SecretStringsRemoveMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ secret_strings_remove?: ?({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type SecurityFindingsSearchQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
  keywords?: ?$ElementType<Scalars, 'String'>,
  mobile_app_id?: ?Array<$ElementType<Scalars, 'String'>>,
  current_status?: ?Array<TargetStatus>,
  priority?: ?Array<SecurityFindingSearchPriority>,
  importance_tags?: ?Array<SecurityFindingSearchImportanceTag>,
  severity?: ?Array<FindingSeverity>,
  compliance_policy?: ?Array<CompliancePolicy>,
  release_type?: ?Array<SecurityFindingSearchReleaseType>,
  age?: ?$ElementType<Scalars, 'Int'>,
  targets_created_after?: ?$ElementType<Scalars, 'Date'>,
};


export type SecurityFindingsSearchQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ security_findings_search: ({
      ...{ __typename: 'SecurityFindingsSearchResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: *, total_count?: * }>
    }), security_findings?: ?Array<({
        ...{ __typename: 'SecurityFinding' },
      ...$Pick<SecurityFinding, { id: *, date_updated: *, date_created: *, description: *, description_intro?: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, issue_type_id: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: * }>,
      ...{ compliance_policy_references?: ?Array<({
          ...{ __typename: 'CompliancePolicyReference' },
        ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
      })>, targets: Array<({
          ...{ __typename: 'Target' },
        ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
        ...{ statuses: Array<({
            ...{ __typename: 'TargetStatusWithDate' },
          ...$Pick<TargetStatusWithDate, { status: *, date: * }>
        })>, additional_contexts?: ?Array<({
            ...{ __typename: 'TargetAdditionalContext' },
          ...$Pick<TargetAdditionalContext, { body: *, type: * }>
        })> }
      })>, notes?: ?Array<({
          ...{ __typename: 'Note' },
        ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
      })> }
    })> }
  }) }
});

export type CreateSupportRequestMutationVariables = {
  body: SevenhellSupportRequestsCreateParams,
};


export type CreateSupportRequestMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ sevenhell_support_requests_create: ({
      ...{ __typename: 'EmptyResponse' },
    ...$Pick<EmptyResponse, { nothing?: * }>
  }) }
});

export type CreateTargetStatusMutationVariables = {
  security_finding_id: $ElementType<Scalars, 'ID'>,
  target_id: $ElementType<Scalars, 'ID'>,
  body?: ?TargetStatusCreateParams,
};


export type CreateTargetStatusMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ security_findings_targets_statuses_create: ({
      ...{ __typename: 'TargetStatusWithFinding' },
    ...$Pick<TargetStatusWithFinding, { date: *, status: * }>,
    ...{ security_finding?: ?({
        ...{ __typename: 'SecurityFinding' },
      ...$Pick<SecurityFinding, { id: *, description: *, description_intro?: *, date_created: *, date_updated: *, title: *, category?: *, exploitability?: *, priority?: *, severity: *, finding_type?: *, recommendation?: *, reproduction_steps?: *, screenshot_url?: *, secure_code?: *, template_id?: *, is_permanently_closed?: *, affecting_mediawatch_article_ids?: *, aggregated_status: *, aggregated_status_date: *, app_protection_score?: *, mobile_app_id: *, importance_tags?: *, issue_type_id: * }>,
      ...{ compliance_policy_references?: ?Array<({
          ...{ __typename: 'CompliancePolicyReference' },
        ...$Pick<CompliancePolicyReference, { compliance_policy: *, link: *, markdown: * }>
      })>, targets: Array<({
          ...{ __typename: 'Target' },
        ...$Pick<Target, { date_created: *, date_updated: *, external_id?: *, external_bug_tracker_url?: *, raw_text?: *, security_finding_id: *, formatted_text: *, id: * }>,
        ...{ statuses: Array<({
            ...{ __typename: 'TargetStatusWithDate' },
          ...$Pick<TargetStatusWithDate, { status: *, date: * }>
        })>, additional_contexts?: ?Array<({
            ...{ __typename: 'TargetAdditionalContext' },
          ...$Pick<TargetAdditionalContext, { body: *, type: * }>
        })> }
      })>, notes?: ?Array<({
          ...{ __typename: 'Note' },
        ...$Pick<Note, { id: *, text: *, date: *, date_created: *, date_updated: *, author_email: *, security_finding_id: * }>
      })> }
    }) }
  }) }
});

export type UsersGetQueryQueryVariables = {
  userId: $ElementType<Scalars, 'ID'>,
};


export type UsersGetQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ user_by_id?: ?({
      ...{ __typename: 'User' },
    ...$Pick<User, { allowed_app_ids?: *, auth_strategy: *, can_access_app_search: *, can_access_app_secure: *, can_access_api_inspect: *, can_access_brand_protect: *, can_invite_users: *, first_name: *, id: *, last_name: *, login_email: *, notification_email?: *, role: * }>
  }) }
});

export type VendorManagementVendorsListQueryQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'String'>,
};


export type VendorManagementVendorsListQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_vendors_list: ({
      ...{ __typename: 'VendorManagementVendorsListResponse' },
    ...{ pagination_information: ({
        ...{ __typename: 'PaginationInformation' },
      ...$Pick<PaginationInformation, { next_cursor?: * }>
    }), administrative_account: ({
        ...{ __typename: 'AdministrativeAccount' },
      ...$Pick<AdministrativeAccount, { id: *, logged_in: *, name: * }>
    }), vendors?: ?Array<({
        ...{ __typename: 'Vendor' },
      ...$Pick<Vendor, { date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: * }>
    })> }
  }) }
});

export type VendorManagementVendorByIdQueryQueryVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type VendorManagementVendorByIdQueryQuery = ({
    ...{ __typename: 'Query' },
  ...{ vendor_management_vendor_by_id: ({
      ...{ __typename: 'VendorManagementVendorByIdResponse' },
    ...{ vendor: ({
        ...{ __typename: 'Vendor' },
      ...$Pick<Vendor, { date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: * }>
    }) }
  }) }
});

export type CreateVendorMutationVariables = {
  body: VendorManagementCreateParams,
};


export type CreateVendorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_vendor_create: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: * }>
  }) }
});

export type UpdateVendorMutationVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
  body: VendorManagementUpdateParams,
};


export type UpdateVendorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_vendor_update: ({
      ...{ __typename: 'Vendor' },
    ...$Pick<Vendor, { date_created: *, date_updated: *, deletion_requested?: *, id: *, last_login_date?: *, logged_in: *, name: *, setup_completion_date?: *, setup_needed: * }>
  }) }
});

export type LoginVendorMutationVariables = {
  vendorId: $ElementType<Scalars, 'ID'>,
};


export type LoginVendorMutation = ({
    ...{ __typename: 'Mutation' },
  ...{ vendor_management_vendor_login: ({
      ...{ __typename: 'Session' },
    ...$Pick<Session, { session_id: *, session_expiration: * }>
  }) }
});
