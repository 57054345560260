//@flow
import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import type { Node } from 'react';

type Props = {|
  +style?: mixed,
  +m?: number,
  +p?: number,
  +variant: 'info' | 'error' | 'success',
  +icon?: Node,
  +message: string,
|};

const variantStyle = {
  info: { bgcolor: palette.gray50, color: palette.gray10 },
  error: { bgcolor: palette.red50, color: palette.red10 },
  success: { bgcolor: palette.green50, color: palette.green10 },
};

let useStyles = makeStyles({
  // use entire given `style` but override
  // display: flex & justifyItems: center
  // to inline icon & text.
  box: props => ({
    ...(props.style && props.style),
    display: 'flex',
    alignItems: 'center',
  }),
});

const MessageComponent = function Message({
  style,
  m,
  p,
  variant,
  icon,
  message,
}: Props) {
  let classes = useStyles({ style });
  const padding = typeof p !== 'undefined' ? p : 1;
  const margin = typeof m !== 'undefined' ? m : 2;

  return (
    <Box
      bgcolor={variantStyle[variant].bgcolor}
      color={variantStyle[variant].color}
      p={padding}
      m={margin}
      borderRadius={2}
      whiteSpace="pre-line" // Used to allow messages to be multiline strings.
      className={classes.box}
    >
      {icon && (
        <Box component="div" width="auto" m={0} p={1}>
          {icon}
        </Box>
      )}
      <Box component="div" width="auto" m={0} p={1}>
        <Typography variant="body1">{message}</Typography>
      </Box>
    </Box>
  );
};

export const Message = memo<Props>(MessageComponent);
