// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Menu, MenuItem, Button, Typography } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import { ConfigurationsCloudAddAccessPicker } from './ConfigurationsCloudAddAccessPicker';
import { ConfigurationsCloudAvatar } from './ConfigurationsCloudAvatar';
import { ConfigurationsCloudNameIdentifier } from './ConfigurationsCloudNameIdentifier';
import type { ConfigurationsCloudVariant } from '../types';
import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';
import values from 'lodash/fp/values';

const useStyles = makeStyles({
  menu_list: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type Props = {|
  +variant?: 'text' | 'outlined' | 'contained',
  +size?: 'small' | 'medium' | 'large',
  title: string,
|};

const ConfigurationsCloudPageAddSourceButtonComponent = function ConfigurationsCloudPageAddSourceButton({
  variant,
  size,
  title,
}: Props) {
  const classes = useStyles();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOnClickMenuItem = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Button
        aria-label="add-source-button"
        variant={variant ? variant : 'contained'}
        size={size ? size : 'small'}
        color="primary"
        startIcon={<ContentAdd />}
        onClick={event => {
          setMenuOpen(!isMenuOpen);
          setAnchorEl(event.currentTarget);
        }}
      >
        {title}
      </Button>

      <Menu
        aria-label="add-source-menu"
        classes={{ list: classes.menu_list }}
        disableScrollLock
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        keepMounted
      >
        {[
          ...values(CloudAuthenticatorTypeValues)
            // Keep duplicate entry from displaying in add access menu
            .filter(
              variant => variant !== CloudAuthenticatorTypeValues.ApigeeOnPrem,
            ),
          'ip_ranges',
          'openapi_definitions',
          'network_services',
        ].map((variant: ConfigurationsCloudVariant) => (
          <ConfigurationsCloudAddAccessPicker key={variant} variant={variant}>
            <MenuItem
              aria-label={`${variant}-menu-item`}
              onClick={handleOnClickMenuItem}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  lineHeight: '32px',
                }}
              >
                <ConfigurationsCloudAvatar
                  variant={variant}
                  style={{ marginRight: 12 }}
                />
                <Typography variant="body2">
                  <ConfigurationsCloudNameIdentifier
                    variant={variant}
                    fullName
                  />{' '}
                </Typography>
              </div>
            </MenuItem>
          </ConfigurationsCloudAddAccessPicker>
        ))}
      </Menu>
    </>
  );
};

export const ConfigurationsCloudPageAddSourceButton = ConfigurationsCloudPageAddSourceButtonComponent;
