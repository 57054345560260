// @flow
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  type Store as ReduxStore,
  type Middleware,
} from 'redux';
import { type State } from './../reduxStateType';
import { sagasCreated } from './containers/actions';

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
    })
  : compose;

export function buildReducer() {
  return combineReducers<_, *>({
    user_sessions: require('./user_sessions/reducer').default,
    ready: (state = false, action): boolean => {
      if (action.type === sagasCreated.toString()) return true;
      return state;
    },
  });
}

export function getStore(
  middleware: $ReadOnlyArray<Middleware<*, *>>,
  initialState?: State,
) {
  const middlewareFinal = composeEnhancers(applyMiddleware(...middleware));

  if (initialState) {
    return createStore<State, *, *>(
      buildReducer(),
      initialState,
      middlewareFinal,
    );
  } else {
    return createStore<State, *, *>(buildReducer(), middlewareFinal);
  }
}

export type Action<T: string, D, M> = {
  type: T,
  payload?: D,
  meta?: M,
  ...
};

export type Store = ReduxStore<State, *>;
