// @flow
import React, { useEffect, memo, lazy, Suspense } from 'react';
import { entries } from 'lodash/fp';
import { makeStyles } from '@material-ui/styles';
import { Router, Redirect } from '@reach/router';
import type { AccountInfo } from '@dt/user-api/account_info';
import { NotFoundPage, OnboardingBanner } from '@dt/components';
import { palette } from '@dt/theme';
import ConfigurableLHSMenu from '@dt/material-components/navigation/ConfigurableLHSMenuPage';
import {
  MenuWeb,
  MenuMobile,
  MenuSupplyChainSecurity,
  MenuApi,
  MenuCloud,
  MenuDashboard,
  MenuManagement,
} from '@dt/material-components/navigation/ConfigurableLHSMenuConfiguration';
import type { LeftMenuConfiguration } from '@dt/material-components/navigation/ConfigurableLHSMenuPage';

// prettier-ignore
const {
  ApiInventoryPage,
  ApiAssetGroupsPage,
  ApiAssetGroupsUuidPage,
  AsmSetupPage,
  CloudActivityPage,
  CloudPage,
  CloudAssetGroupsPage,
  CloudInventoryPage,
  CloudPoliciesPage,
  CloudPoliciesUuidPage,
  CloudPolicyViolationsPage,
  CloudPolicyViolationsUuidPage,
  CloudCloudResourcesUuidPage,
  CloudRestfulApisUuidPage,
  CloudNetworkServicesUuidPage,
  CloudToolkitsPage,
  CloudToolkitsCodeRedPage,
  CloudToolkitsLeaksPage,
  CloudToolkitsGotchasPage,
  CloudWebApplicationsUuid,
  CloudAssetGroupsUuidPage,
  WebPolicyViolationsPage,
  WebPolicyViolationsUuidPage,
  WebPage,
  WebAssetGroupsPage,
  WebInventoryPage,
  WebSecurityToolkitsPage,
  WebToxicTokensPage,
  WebSecurityToolkitsXssProtectionPage,
  WebApplicationsUuidPage,
  DashboardPage,
  WebNetworkServicesUuidPage,
  WebCloudResourcesUuidPage,
  WebGraphqlApisUuidPage,
  WebSecurityToolkitsDetectInjectPage,
  WebRestfulApisUuidPage,
  WebPoliciesPage,
  WebPoliciesUuidPage,
  WebAssetGroupsUuidPage,
  WebSecurityToolkitsXssAttackPage,
  ManagementConfigurationsPage,
  ApiPoliciesPage,
  ApiPoliciesUuidPage
} = {
  ApiInventoryPage:                           lazy(() => import('./pages/api/inventory/ApiInventoryPage'),),
  ApiAssetGroupsPage:                         lazy(() => import('./pages/api/asset_groups/ApiAssetGroupsPage'),),
  ApiAssetGroupsUuidPage:                     lazy(() => import('./pages/api/asset_groups/uuid/ApiAssetGroupsUuidPage'),),
  AsmSetupPage:                               lazy(() => import('./pages/cloud/asm_setup/AsmSetupPage')),
  CloudActivityPage:                          lazy(() => import('./pages/cloud/activity/CloudActivityPage')),
  CloudPage:                                  lazy(() => import('./pages/cloud/CloudPage')),
  CloudAssetGroupsPage:                       lazy(() => import('./pages/cloud/asset_groups/CloudAssetGroupsPage'),),
  CloudInventoryPage:                         lazy(() => import('./pages/cloud/inventory/CloudInventoryPage')),
  CloudPoliciesPage:                          lazy(() => import('./pages/cloud/policies/CloudPoliciesPage')),
  CloudPoliciesUuidPage:                      lazy(() => import('./pages/cloud/policies/uuid/CloudPoliciesUuidPage')),
  CloudPolicyViolationsPage:                  lazy(() => import('./pages/cloud/policy_violations/CloudPolicyViolationsPage')),
  CloudPolicyViolationsUuidPage:              lazy(() => import('./pages/cloud/policy_violations/uuid/CloudPolicyViolationsUuidPage')),
  CloudCloudResourcesUuidPage:                lazy(() => import('./pages/cloud/cloud_resources/uuid/CloudCloudResourcesUuidPage')),
  CloudNetworkServicesUuidPage:               lazy(() => import('./pages/cloud/network_services/uuid/CloudNetworkServicesUuidPage')),
  CloudRestfulApisUuidPage:                   lazy(() => import('./pages/cloud/restful_apis/uuid/CloudRestfulApisUuidPage')),
  CloudToolkitsPage:                          lazy(() => import('./pages/cloud/toolkits/CloudToolkitsPage')),
  CloudToolkitsCodeRedPage:                   lazy(() => import('./pages/cloud/toolkits/code_red/CodeRedPage')),
  CloudToolkitsLeaksPage:                     lazy(() => import('./pages/cloud/toolkits/leaks/CloudToolkitsLeaksPage')),
  CloudToolkitsGotchasPage:                   lazy(() => import('./pages/cloud/toolkits/gotchas/CloudToolkitsGotchasPage')),
  CloudWebApplicationsUuid:                   lazy(() => import('./pages/cloud/web_applications/uuid/CloudWebApplicationsUuidPage')),
  CloudAssetGroupsUuidPage:                   lazy(() => import('./pages/cloud/asset_groups/uuid/CloudAssetGroupsUuidPage'),),
  WebPolicyViolationsPage:                    lazy(() => import('./pages/web/policy_violations/WebPolicyViolationsPage'),),
  WebPolicyViolationsUuidPage:                lazy(() => import('./pages/web/policy_violations/uuid/WebPolicyViolationsUuidPage'),),
  WebPage:                                    lazy(() => import('./pages/web/WebPage')),
  WebAssetGroupsPage:                         lazy(() => import('./pages/web/asset_groups/WebAssetGroupsPage'),),
  WebInventoryPage:                           lazy(() => import('./pages/web/inventory/WebInventoryPage')),
  WebSecurityToolkitsPage:                    lazy(() => import('./pages/web/security_toolkits/WebSecurityToolkitsPage'),),
  WebToxicTokensPage:                         lazy(() => import('./pages/web/toxic_tokens/WebToxicTokensPage')),
  WebSecurityToolkitsXssProtectionPage:       lazy(() => import('./pages/web/security_toolkits/xss_protection/WebSecurityToolkitsXssProtectionPage'),),
  WebApplicationsUuidPage:                    lazy(() => import('./pages/web/applications/uuid/WebApplicationsUuidPage')),
  DashboardPage:                              lazy(() => import('./pages/dashboard/DashboardPage')),
  WebNetworkServicesUuidPage:                 lazy(() => import('./pages/web/network_services/uuid/WebNetworkServicesUuidPage'),),
  WebCloudResourcesUuidPage:                  lazy(() => import('./pages/web/cloud_resources/uuid/WebCloudResourcesUuidPage'),),
  WebGraphqlApisUuidPage:                     lazy(() => import('./pages/web/graphql_apis/uuid/WebGraphqlApisUuidPage'),),
  WebSecurityToolkitsDetectInjectPage:        lazy(() => import('./pages/web/security_toolkits/sql_injection/WebSecurityToolkitsSqlInjectionPage'),),
  WebRestfulApisUuidPage:                     lazy(() => import('./pages/web/restful_apis/uuid/WebRestfulApisUuidPage')),
  WebPoliciesPage:                            lazy(() => import('./pages/web/policies/WebPoliciesPage'),),
  WebPoliciesUuidPage:                        lazy(() => import('./pages/web/policies/uuid/WebPoliciesUuidPage'),),
  WebAssetGroupsUuidPage:                     lazy(() => import('./pages/web/asset_groups/uuid/WebAssetGroupsUuidPage'),),
  WebSecurityToolkitsXssAttackPage:           lazy(() => import('./pages/web/security_toolkits/xss_attack/WebSecurityToolkitsXssAttackPage')),
  ManagementConfigurationsPage:               lazy(() => import('./pages/management/configurations/ManagementConfigurationsPage'),),
  ApiPoliciesPage:                            lazy(() => import('./pages/api/policies/ApiPoliciesPage'),),
  ApiPoliciesUuidPage:                        lazy(() => import('./pages/api/policies/uuid/ApiPoliciesUuidPage'),),
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
  },
  content: {
    overflow: 'auto',
    padding: 8,
    flexGrow: '1',
    minHeight: '100%',
    backgroundColor: palette.gray50,
  },
});

type Props = {
  accountInfo: AccountInfo,
};

function AuthenticatedApp({ accountInfo }: Props) {
  const styles = useStyles();

  // Page Title.
  useEffect(() => {
    let title = null;
    if (window.location.pathname.startsWith('/api')) {
      title = 'API Secure';
    } else if (window.location.pathname.startsWith('/cloud')) {
      title = 'Cloud Secure';
    } else if (window.location.pathname.startsWith('/dashboard')) {
      title = 'Dashboard';
    } else if (window.location.pathname.startsWith('/management')) {
      title = 'Management';
    } else if (window.location.pathname.startsWith('/mobile')) {
      title = 'Mobile Secure';
    } else if (window.location.pathname.startsWith('/openscan')) {
      title = 'Supply Chain Security';
    } else if (window.location.pathname.startsWith('/web')) {
      title = 'Web Secure';
    }

    document.title = `${title ? `${title} | ` : ''}Data Theorem`;
  }, []);

  // Page Menu.
  // prettier-ignore
  const routesSidebarContent = {
    '/api/*':        MenuApi,
    '/cloud/*':      MenuCloud,
    '/dashboard/*':  MenuDashboard,
    '/management/*': MenuManagement,
    '/mobile/*':     MenuMobile,
    '/openscan/*':   MenuSupplyChainSecurity,
    '/web/*':        MenuWeb,
  };

  // Page Content.
  // prettier-ignore
  const routesPageContent = {
    // TODO@nw: Support for stripping trailing slashes.
    //'.*/+$':                                             window.location.pathname.slice(0, -1),
    '/api/inventory':                                     ApiInventoryPage,
    '/api/inventory/:currentTab':                         ApiInventoryPage,
    '/api/discover/inventory':                            '/api/inventory',
    '/api/discover/inventory/:currentTab':                '/api/inventory/:currentTab',
    '/api/asset-groups':                                  ApiAssetGroupsPage,
    '/api/asset-groups/:id':                              ApiAssetGroupsUuidPage,
    '/api/discover/configuration':                        '/management/configurations',
    '/api/policy':                                        '/api/policies',
    '/api/policy/:id':                                    '/api/policies/:id',
    '/api/policies':                                      ApiPoliciesPage,
    '/api/policies/:id':                                  '/api/policies/:id/api',
    '/api/policies/:id/:currentTab':                      ApiPoliciesUuidPage,
    '/cloud':                                             CloudPage,
    '/cloud/asm-setup':                                   AsmSetupPage,
    '/cloud/asset-groups':                                CloudAssetGroupsPage,
    '/cloud/activity':                                    CloudActivityPage,
    '/cloud/inventory':                                   CloudInventoryPage,
    '/cloud/inventory/:currentTab':                       CloudInventoryPage,
    '/cloud/cloud-resources/:id':                         CloudCloudResourcesUuidPage,
    '/cloud/cloud-resources/:id/:currentTab':             CloudCloudResourcesUuidPage,
    '/cloud/network-services/:id':                        CloudNetworkServicesUuidPage,
    '/cloud/network-services/:id/:currentTab':            CloudNetworkServicesUuidPage,
    '/cloud/policies':                                    CloudPoliciesPage,
    '/cloud/policies/:id':                                '/cloud/policies/:id/cloud',
    '/cloud/policies/:id/:currentTab':                    CloudPoliciesUuidPage,
    '/cloud/policy-violations':                           CloudPolicyViolationsPage,
    '/cloud/policy-violations/:policyViolationId':        CloudPolicyViolationsUuidPage,
    '/cloud/restful-apis/:id':                            CloudRestfulApisUuidPage,
    '/cloud/restful-apis/:id/:currentTab':                CloudRestfulApisUuidPage,
    "/cloud/security-toolkits":                           CloudToolkitsPage,
    "/cloud/security-toolkits/cloud-gotchas":             CloudToolkitsGotchasPage,
    "/cloud/security-toolkits/cloud-gotchas/:currentTab": CloudToolkitsGotchasPage,
    "/cloud/security-toolkits/cloud-leaks":               CloudToolkitsLeaksPage,
    '/cloud/security-toolkits/code-red':                  CloudToolkitsCodeRedPage,
    '/cloud/web-applications/:id':                        CloudWebApplicationsUuid,
    '/cloud/web-applications/:id/:currentTab':            CloudWebApplicationsUuid,
    '/cloud/asset-groups/:id':                            CloudAssetGroupsUuidPage,
    '/dashboard':                                         DashboardPage,
    '/management/configurations':                         ManagementConfigurationsPage,
    '/web/':                                              WebPage,
    '/web/asset-groups':                                  WebAssetGroupsPage,
    '/web/cloud-resources/:id':                           WebCloudResourcesUuidPage,
    '/web/cloud-resources/:id/:currentTab':               WebCloudResourcesUuidPage,
    '/web/graphql-apis/:id':                              WebGraphqlApisUuidPage,
    '/web/graphql-apis/:id/:currentTab':                  WebGraphqlApisUuidPage,
    '/web/inventory':                                     WebInventoryPage,
    '/web/inventory/:currentTab':                         WebInventoryPage,
    '/web/network-services/:id':                          WebNetworkServicesUuidPage,
    '/web/network-services/:id/:currentTab':              WebNetworkServicesUuidPage,
    '/web/asset-groups/:id':                              WebAssetGroupsUuidPage,
    '/web/policies':                                      WebPoliciesPage,
    '/web/policies/:id':                                  '/web/policies/:id/web',
    '/web/policies/:id/:currentTab':                      WebPoliciesUuidPage,
    '/web/policy-violations':                             WebPolicyViolationsPage,
    '/web/policy-violations/:violationId':                WebPolicyViolationsUuidPage,
    '/web/restful-apis/:id':                              WebRestfulApisUuidPage,
    '/web/restful-apis/:id/:currentTab':                  WebRestfulApisUuidPage,
    '/web/security-toolkits':                             WebSecurityToolkitsPage,
    '/web/security-toolkits/sql-injection/*':             WebSecurityToolkitsDetectInjectPage,
    '/web/security-toolkits/xss-attack':                  WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-attack/:currentTab':      WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-protection':              WebSecurityToolkitsXssProtectionPage,
    '/web/toxic-tokens/*':                                WebToxicTokensPage,
    '/web/web-applications/:id':                          WebApplicationsUuidPage,
    '/web/web-applications/:id/:currentTab':              WebApplicationsUuidPage,
  };

  return (
    <div className={styles.root} data-testid="pageMenu">
      {/* Sidebar */}
      <Router style={{ height: '100%' }}>
        {entries(routesSidebarContent).map(
          ([route, configuration]: [string, LeftMenuConfiguration]) => (
            // $FlowFixMe - This is fixed with react-router -> reach forces components to have path props.
            <ConfigurableLHSMenu
              key={route}
              path={route}
              configuration={configuration}
            />
          ),
        )}
      </Router>

      {/* Page */}
      <div className={styles.content} data-testid="pageContent">
        <OnboardingBanner accountInfo={accountInfo} fullScreen />

        <Suspense fallback={null}>
          <Router style={{ height: '100%' }}>
            {entries(
              routesPageContent,
            ).map(
              (
                [route, Destination]: [
                  string,
                  string | React$AbstractComponent<{| +path: string |}, mixed>,
                ],
                i,
              ) =>
                typeof Destination === 'string' ? (
                  <Redirect key={i} from={route} to={Destination} noThrow />
                ) : (
                  <Destination key={i} path={route} />
                ),
            )}

            {/* TODO@nw: Move to pages after apps are merged. */}
            <NotFoundPage default />
          </Router>
        </Suspense>
      </div>
    </div>
  );
}

export default memo<Props>(AuthenticatedApp);
